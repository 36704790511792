const companyProfile = (state, data) => {
  state.companyProfile = data;
};

const status = (state, data) => {
  state.status = data;
};

const logoUrl = (state, data) => {
  state.logoUrl = data;
};

const jobPostingSettings = (state, data) => {
  state.jobPostingSettings = data;
};

export default {
  jobPostingSettings: jobPostingSettings,
  status: status,
  companyProfile: companyProfile,
  logoUrl: logoUrl
};
