<template>
  <div>
    <SectionHeader
      :title="'Hantera konto'"
      :show-divider="false"
    ></SectionHeader>
    <PageContent :loading="false" :loading-message="''">
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12">
              <h4>Återställ lösenord</h4>
            </v-col>
            <v-col cols="12">
              <v-btn dark class="text-none white--text" rounded color="primary"
                ><router-link :to="{ name: 'PasswordReset' }">
                  <span class="white--text">Återställ lösenord</span>
                </router-link></v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4>Logga ut</h4>
              <p>
                Även när du är utloggad får du emails om vad som händer på Brite
              </p>
            </v-col>
            <v-col>
              <v-btn class="text-none" rounded color="primary" @click="logout"
                >Logga ut</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </PageContent>
  </div>
</template>

<script>
import basicMixin from "../../../mixins/basic";

export default {
  mixins: [basicMixin],
  data: function () {
    return {};
  },
  methods: {
    logout: function () {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>