

const handleBreadcrumbsVisibility = (breadcrumbs, tab, status, jobStatus, allQuestionsValid, showAll) => {
  var setFollowingToDisabled = false;
  for (var i = 0; i < breadcrumbs.length; i++) {
    if (showAll == true) {
      breadcrumbs[i].disabled = false;
      continue;
    }
    if (allQuestionsValid && breadcrumbs[i].showWhenAllQuestionsValid) {
      breadcrumbs[i].disabled = false;
      continue
    }
    if (jobStatus == "DRAFT" && breadcrumbs[i].disabledInDraft) {
      breadcrumbs[i].disabled = true;
      continue;
    }
    if (setFollowingToDisabled) {
      breadcrumbs[i].disabled = true;
    }
    if (breadcrumbs[i].tab == tab) {
      breadcrumbs[i].valid = status;
      if (status == false) {
        setFollowingToDisabled = true;
      }
      if (i < breadcrumbs.length - 1) {
        breadcrumbs[i + 1].disabled = !status;
      }
    }
    if (!setFollowingToDisabled) {
      if (breadcrumbs[i].valid && i < breadcrumbs.length - 1) {
        breadcrumbs[i + 1].disabled = false;
      }
    }
  }
  return breadcrumbs;
};

module.exports = {
  handleBreadcrumbsVisibility: handleBreadcrumbsVisibility,
};
