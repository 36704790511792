
const axios = require("axios");
import store from "../store";

var baseUrl = process.env.VUE_APP_JOBS_BASE_URL;
console.log("Using endpoint: " + process.env.VUE_APP_JOBS_BASE_URL);

const EPcreateUser = "/auth/ag/user";
const EPuploadUrl = "/ag/upload/url";
const EPuploadFile = "/ag/upload/file";
const EPquestionsJob = "/ag/questions";
const EPjobs = "/ag/jobs";
const EPjobQuestion_1 = "/ag/jobs/";
const EPjobQuestion_2 = "/questions";
const EPinterviews_1 = "/ag/jobs/";
const EPinterviews_2 = "/interviews";
const EPapplications_1 = "/ag/jobs/";
const EPapplications_2 = "/applications/";
const EPcompany = "/ag/company";
const EPjobStatistics = "/ag/job/statistics";
const EPjobPosting = "/ag/jobs/{jobGuid}/postings";
const EPuser = "/ag/user";
const EPinviteCandidateToTest = (job_guid, application_guid) =>
  `/ag/jobs/${job_guid}/applications/${application_guid}/testinvitation`;
const EPcompanyJobPostingSettings = "/ag/company/jobposting"

const EPnativeValidate = "/auth/ag/validate";
const EPnativeLogin = "/auth/ag/login";
const EPnativeCreate = "/auth/ag/user";
const EPpassword = "/auth/ag/password";

const EPcitySuggestions = "/external/cities";


async function briteRequest({
  type,
  ep,
  body,
  params,
  url,
  propagate_401_error,
  skipAuthorization = false
}) {
  try {
    if (url == undefined) {
      url = baseUrl + ep;
    }
    let headers = {}
    if (!skipAuthorization) {
      headers = {
        headers: {
          authorization: "Bearer " + store.state.user.token
        }
      }
    }
    if (type == "GET") {
      const response = await axios.get(url, { ...headers, params: params });
      return response.data;
    } else if (type == "POST") {
      const response = await axios.post(url, body, headers);
      return response.data;
    } else if (type == "PATCH") {
      const response = await axios.patch(url, body, headers);
      return response.data;
    } else if (type == "PUT") {
      const response = await axios.put(url, body, headers);
      return response.data;
    } else if (type == "DELETE") {
      const response = await axios.post(url, body, headers);
      return response.data;
    }
  } catch (e) {
    if (e.response) {
      console.log(e.response.data);
      console.log(e.response.status);
      console.log(e.response.headers);
      if (e.response.status == 401 && propagate_401_error) {
        throw e;
      } else if (e.response.status == 401) {
        // console.log("Logging out")
        // store.default.dispatch("user/logout");
        throw "notAuthorized";
      } else {
        throw e;
      }
    } else {
      console.log(e);
      throw e;
    }
  }
}

// const EPinterviews_2 = '/interviews'

// ?? const uploadUrl = async () => { return await briteRequest({ type: "GET", ep: EPuploadUrl }) }
// ?? const uploadFile = async () => { return await briteRequest({ type: "GET", ep: EPuploadUrl }) }

// const getInterviews = async ({ params }) => { return await briteRequest({ type: "GET", ep: EPinterviews, params: params }) }
// const putInterview = async ({ body }) => { return await briteRequest({ type: "PUT", ep: EPinterviews, body: body }) }

const getUserInfo = async ({ }) => {
  return await briteRequest({ type: "GET", ep: EPuser });
};
const getJobCreateQuestions = async ({ }) => {
  return await briteRequest({ type: "GET", ep: EPquestionsJob });
};
const sendInvites = async ({ job_guid, body }) => {
  return await briteRequest({
    type: "POST",
    ep: EPinterviews_1 + job_guid + EPinterviews_2,
    body: body,
  });
};
const setInterviewTimes = async ({ job_guid, body }) => {
  return await briteRequest({
    type: "POST",
    ep: EPinterviews_1 + job_guid + EPinterviews_2,
    body: body,
  });
};
const createJob = async ({ body }) => {
  return await briteRequest({ type: "POST", ep: EPjobs, body: body });
};
const getJobs = async ({ params, ep }) => {
  return await briteRequest({ type: "GET", ep: ep || EPjobs, params: params });
};
const updateJob = async ({ job_guid, body }) => {
  return await briteRequest({
    type: "PATCH",
    ep: EPjobs + "/" + job_guid,
    body: body,
  });
};
const getJobQuestions = async ({ job_guid }) => {
  return await briteRequest({
    type: "GET",
    ep: EPjobQuestion_1 + job_guid + EPjobQuestion_2,
  });
};
const updateJobQuestions = async ({ job_guid, body }) => {
  return await briteRequest({
    type: "POST",
    ep: EPjobQuestion_1 + job_guid + EPjobQuestion_2,
    body: body,
  });
};
const updateApplication = async ({ job_guid, application_guid, body }) => {
  return await briteRequest({
    type: "PUT",
    ep: EPapplications_1 + job_guid + EPapplications_2 + application_guid,
    body: body,
  });
};
const setApplicationStatus = async ({ job_guid, application_guid, body }) => {
  return await briteRequest({
    type: "PATCH",
    ep: EPapplications_1 + job_guid + EPapplications_2 + application_guid,
    body: body,
  });
};
const getCandidateQuestions = async ({ job_guid, application_guid }) => {
  return await briteRequest({
    type: "GET",
    ep: EPapplications_1 + job_guid + EPapplications_2 + application_guid,
  });
};
const getCitySuggestions = async ({ params }) => {
  return await briteRequest({
    type: "GET",
    ep: EPcitySuggestions,
    params: params,
  });
};
const getCompany = async ({ }) => {
  return await briteRequest({ type: "GET", ep: EPcompany });
};
const saveCompany = async ({ questions }) => {
  return await briteRequest({
    type: "POST",
    ep: EPcompany,
    body: { questions: questions },
  });
};
const getJobStatistics = async ({ }) => {
  return await briteRequest({ type: "GET", ep: EPjobStatistics });
};
const postInviteToTest = async ({ job_guid, application_guid }) => {
  return await briteRequest({
    type: "POST",
    ep: EPinviteCandidateToTest(job_guid, application_guid),
  });
};
const createUserPost = async ({ body }) => {
  return await briteRequest({ type: "POST", body: body, ep: EPcreateUser });
};

const nativeLogin = async ({ body }) => {
  return await briteRequest({
    type: "POST",
    ep: EPnativeLogin,
    body: body,
    propagate_401_error: true,
    skipAuthorization: true
  });
};
const nativeValidate = async ({ body }) => {
  return await briteRequest({
    type: "POST", ep: EPnativeValidate, body: body,
    skipAuthorization: true
  });
};
const nativeCreate = async ({ body }) => {
  return await briteRequest({
    type: "POST", ep: EPnativeCreate, body: body,
    skipAuthorization: true
  });
};
const resetPassword = async ({ body }) => {
  return await briteRequest({
    type: "POST", ep: EPpassword, body: body,
    skipAuthorization: true
  });
};
const changePassword = async ({ body }) => {
  return await briteRequest({
    type: "POST", ep: EPpassword, body: body,
    skipAuthorization: true
  });
};
const getJobPostingSettingsRequest = async ({ body, jobGuid }) => {
  return await briteRequest({ type: "GET", body: body, ep: EPjobPosting.replace("{jobGuid}",jobGuid) });
};
const putJobPostingSettingsRequest = async ({ body, jobGuid }) => {
  return await briteRequest({ type: "PUT", body: body, ep: EPjobPosting.replace("{jobGuid}",jobGuid) });
};

const getCompanyJobPostingSettingsRequest = async () => {
  return await briteRequest({ type: "GET", body: null, ep: EPcompanyJobPostingSettings });
};
const putCompanyJobPostingSettingsRequest = async ({ body }) => {
  return await briteRequest({ type: "PUT", body: body, ep: EPcompanyJobPostingSettings });
};

const uploadFile = async ({ formData }) => {
  return await briteRequest({ type: "POST", ep: EPuploadFile, body: formData });
};

export {
  putCompanyJobPostingSettingsRequest,
  getCompanyJobPostingSettingsRequest,
  putJobPostingSettingsRequest,
  getJobPostingSettingsRequest,
  postInviteToTest,
  getUserInfo,
  getJobStatistics,
  getCandidateQuestions,
  sendInvites,
  getJobCreateQuestions,
  createJob,
  getJobs,
  getCompany,
  saveCompany,
  setApplicationStatus,
  getJobQuestions,
  updateJobQuestions,
  updateJob,
  updateApplication,
  getCitySuggestions,
  nativeLogin,
  nativeValidate,
  nativeCreate,
  resetPassword,
  changePassword,
  setInterviewTimes,
  uploadFile,
  createUserPost,
};
