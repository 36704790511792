import { groupQuestions, unGroupQuestions } from "../../utils/questions";
import { handleBreadcrumbsVisibility } from "../../utils/tabs";

import {
  getJobCreateQuestions,
  getJobQuestions,
  updateJobQuestions,
  createJob,
} from "../../requests/global";

const groupedQuestions = (context, data) => {
  var q = context.state.groupedQuestions;
  if (q != null) {
    data.forEach(function (item, index) {
      q[
        q.findIndex((obj) => {
          return obj.id === item.id;
        })
      ] = item;
    });
  }
  context.commit("questions", JSON.parse(JSON.stringify(q)));
};

const get = async (context, data) => {
  //console.log("data")
  //console.log(data.job_guid)
  //console.log("time to get questions")
  context.commit("status", "pending");
  let response;
  if (data.job_guid != null) {
    response = await getJobQuestions({ job_guid: data.job_guid });
  } else {
    response = await getJobCreateQuestions({});
  }
  let questions = response.questions;
  //console.log("got data")
  //console.log(questions[0])
  var groupedQuestions = groupQuestions(questions, context.state.tabSortOrder);

  //console.log("grpuing done")
  context.commit("status", "success");
  context.commit("groupedQuestions", groupedQuestions);
  //console.log("2nd grpuing done")

  return;
};

const save = async (context, data) => {
  await createJob({ questions: data });
  context.dispatch("questions", data);
};

const updateJob = async (context, data) => {
  var questions = unGroupQuestions(data.questions);
  await updateJobQuestions({
    job_guid: data.job_guid,
    body: { questions: questions },
  });
  var groupedQuestions = groupQuestions(questions, context.state.tabSortOrder);
  context.commit("groupedQuestions", groupedQuestions);
  context.dispatch("questions", data);
};

const breadcrumbs = (context, data) => {
  var tempBreadcrumbs = JSON.parse(JSON.stringify(context.state.breadcrumbs));
  tempBreadcrumbs = handleBreadcrumbsVisibility(
    tempBreadcrumbs,
    data.tab,
    data.status,
    data.jobStatus,
    data.allQuestionsValid,
    data.showAll
  );
  context.commit("breadcrumbs", tempBreadcrumbs);
};

const createNewJob = async (context, data) => {
  var questions = unGroupQuestions(data);
  var jobGuid = await createJob({ body: { questions: questions } });
  //await createJob({ body: fakebody })

  context.dispatch("jobs/jobs", null, { root: true });

  // Keep this was triggering a redownload of job question to create a new job shortly after a job was created
  //context.commit("status", 'initialize');

  context.commit("groupedQuestions", null);
  return jobGuid.job_guid;
};

export default {
  groupedQuestions: groupedQuestions,
  get: get,
  save: save,
  breadcrumbs: breadcrumbs,
  createNewJob: createNewJob,
  updateJob: updateJob,
};

const fakebody = {
  questions: [
    {
      answerAlternatives: [
        {
          val: null,
          text: null,
        },
      ],
      range: [],
      labels: [],
      id: 19,
      rank: 1,
      tab: "questions",
      kind: "QTextShort",
      questionText: "Föredragen bransch",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: null,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title: "Föredragen bransch",
      answer: null,
      answer_text: "asda",
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: null,
          text: null,
        },
      ],
      range: [],
      labels: [],
      id: 29,
      rank: 10,
      tab: "create",
      kind: "QTextShort",
      questionText: "Jobbtitel",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: null,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title: "Jobbtitel",
      answer: null,
      answer_text: "asda",
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: null,
          text: null,
        },
      ],
      range: [],
      labels: [],
      id: 34,
      rank: 15,
      tab: "profile",
      kind: "QJobs",
      questionText: "Vad är det för typ av tjänst?",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: null,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: null,
          text: null,
        },
      ],
      range: [],
      labels: [],
      id: 30,
      rank: 20,
      tab: "create",
      kind: "QTextShort",
      questionText: "Avdelning",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: null,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title: "Avdelning",
      answer: null,
      answer_text: "asdasd",
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Lön i SEK",
        },
        {
          val: 2,
          text: "Lön i SEK",
        },
      ],
      range: [20000, 60000],
      labels: ["20000", "60000+"],
      id: 5,
      rank: 30,
      tab: "profile",
      kind: "QSlide",
      questionText:
        "Vad är den högsta lönen ni kan betala för denna roll? <br/> Brite kommer aldrig uppge denna siffra för arbetstagaren men Brite kommer ej matcha rollen mot arbetstagare som endast accepterar jobb med en högre lön än denna. Alla arbetstagare uppger sin minimilön. Löneförhandling sker sedan som vanligt sist i rekryteringsprocessen men förfarandet säkerställer att ni kommer att komma överens i slutändan.",
      introText: null,
      instructionText:
        "Jag kommer aldrig ge arbetsgivaren denna information men jag kommer bara leta efter jobb där lönen motsvarar denna summa eller högre.",
      atbotOKText: "Nästa fråga, tacku0021",
      answer_list_id: 4,
      showThumbLabel: true,
      thumbLabelSize: 50,
      sliderStepSize: 500,
      groupAnswers: false,
      autoContinue: true,
      default: null,
      title:
        "Vad är den högsta lönen ni kan betala för denna roll? <br/> Brite kommer aldrig uppge denna siffra för arbetstagaren men Brite kommer ej matcha rollen mot arbetstagare som endast accepterar jobb med en högre lön än denna. Alla arbetstagare uppger sin minimilön. Löneförhandling sker sedan som vanligt sist i rekryteringsprocessen men förfarandet säkerställer att ni kommer att komma överens i slutändan.",
      answer: 40000,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: null,
          text: null,
        },
      ],
      range: [],
      labels: [],
      id: 31,
      rank: 40,
      tab: "create",
      kind: "QText",
      questionText: "Beskriv tjänsten kortfattat",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: null,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title: "Beskriv tjänsten kortfattat",
      answer: null,
      answer_text: "asdas",
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "IT",
        },
        {
          val: 2,
          text: "Verksamhetsutveckling",
        },
        {
          val: 3,
          text: "Ekonomi",
        },
        {
          val: 4,
          text: "HR",
        },
        {
          val: 5,
          text: "Marknad/Sälj",
        },
        {
          val: 6,
          text: "Administration",
        },
        {
          val: 7,
          text: "Service/Kundtjänst",
        },
        {
          val: 8,
          text: "Produktion",
        },
        {
          val: 9,
          text: "Lager",
        },
        {
          val: 10,
          text: "Koordinering",
        },
        {
          val: 11,
          text: "Ledarskap",
        },
      ],
      range: [],
      labels: [],
      id: 3,
      rank: 50,
      tab: "profile",
      kind: "QMulti",
      questionText: "Jobbkategori",
      introText: null,
      instructionText: null,
      atbotOKText: "Låt oss fortsättau0021",
      answer_list_id: 3,
      groupAnswers: true,
      autoContinue: false,
      default: null,
      title: "Jobbkategori",
      answer: [2],
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Heltid",
        },
        {
          val: 2,
          text: "Deltid",
        },
      ],
      range: [],
      labels: [],
      id: 2,
      rank: 60,
      tab: "profile",
      kind: "QMulti",
      questionText:
        "Är denna tjänst en heltidstjänst eller deltidstjänst (om detta ej är relevant markera båda)?",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 2,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title:
        "Är denna tjänst en heltidstjänst eller deltidstjänst (om detta ej är relevant markera båda)?",
      answer: [1],
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: null,
          text: null,
        },
      ],
      range: [],
      labels: [],
      id: 32,
      rank: 60,
      tab: "create",
      kind: "QDatepicker",
      questionText:
        "Ange när tjänsten senast behöver vara tillsatt (troligen kommer den tillsättas tidigare)",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: null,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title:
        "Ange när tjänsten senast behöver vara tillsatt (troligen kommer den tillsättas tidigare)",
      answer: {
        start: "2019-12-31T23:00:00.000Z",
        end: "2020-01-07T23:00:00.000Z",
      },
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Heltid",
        },
        {
          val: 2,
          text: "Deltid",
        },
      ],
      range: [],
      labels: [],
      id: 6,
      rank: 70,
      tab: "profile",
      kind: "QCities",
      questionText: "I vilka städer finns tjänsten?",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 2,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title: "I vilka städer finns tjänsten?",
      answer: [
        {
          id: 1,
          name: "Hela Sverige",
        },
      ],
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "9-15 samt flexibla arbetstider övriga timmar",
        },
        {
          val: 2,
          text: "Fast schema med fasta arbetstider dagtid",
        },
        {
          val: 3,
          text: "Fast schema med fasta arbetstider kvällstid",
        },
        {
          val: 4,
          text: "Fast schema med fasta arbetstider nattetid",
        },
        {
          val: 5,
          text: "Förtroendearbetstid",
        },
      ],
      range: [],
      labels: [],
      id: 7,
      rank: 80,
      tab: "profile",
      kind: "QMulti",
      questionText: "Vilken typ av arbetstider har denna roll?",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 5,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title: "Vilken typ av arbetstider har denna roll?",
      answer: [2],
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Ja",
        },
        {
          val: 2,
          text: "Nej",
        },
      ],
      range: [],
      labels: [],
      id: 8,
      rank: 90,
      tab: "questions",
      kind: "QSingle",
      questionText:
        "Kräver denna tjänst att arbetstagaren kan arbeta kvällar och helger?",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 7,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title:
        "Kräver denna tjänst att arbetstagaren kan arbeta kvällar och helger?",
      answer: 1,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Ja",
        },
        {
          val: 2,
          text: "Nej",
        },
      ],
      range: [],
      labels: [],
      id: 9,
      rank: 100,
      tab: "questions",
      kind: "QSingle",
      questionText:
        "Kräver tjänsten att arbetstagaren kan tänka sig att arbeta övertid (mer än 40 timmar i veckan)?",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 7,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title:
        "Kräver tjänsten att arbetstagaren kan tänka sig att arbeta övertid (mer än 40 timmar i veckan)?",
      answer: 1,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 10,
      rank: 110,
      tab: "questions",
      kind: "QSlide",
      questionText:
        "Arbetstagaren kan i hög utsträckning styra sin egen tid i denna roll.",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title:
        "Arbetstagaren kan i hög utsträckning styra sin egen tid i denna roll.",
      answer: 73,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 33,
      rank: 115,
      tab: "questions",
      kind: "QSlide",
      questionText:
        "Den här rollen ställer höga krav på analytiskt och abstrakt tänkande.",
      introText:
        "Nedan vill vi att du berättar hur komplex denna roll är. Ditt svar påverkar algoritmen som väljer ut kandidater.",
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title:
        "Den här rollen ställer höga krav på analytiskt och abstrakt tänkande.",
      answer: 50,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 11,
      rank: 120,
      tab: "questions",
      kind: "QSlide",
      questionText:
        "I denna roll arbetar man i hög utsträckning i team (låg grad av ensamarbete).",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title:
        "I denna roll arbetar man i hög utsträckning i team (låg grad av ensamarbete).",
      answer: 50,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 12,
      rank: 130,
      tab: "questions",
      kind: "QSlide",
      questionText: "Denna roll har en tydlig koppling till samhällsnytta.",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title: "Denna roll har en tydlig koppling till samhällsnytta.",
      answer: 50,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 13,
      rank: 140,
      tab: "questions",
      kind: "QSlide",
      questionText:
        "Det finns tydliga karriärvägar i relation till denna roll. Arbetstagaren kan förvänta sig hög grad av intern rörlighet.",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title:
        "Det finns tydliga karriärvägar i relation till denna roll. Arbetstagaren kan förvänta sig hög grad av intern rörlighet.",
      answer: 50,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 14,
      rank: 150,
      tab: "questions",
      kind: "QSlide",
      questionText:
        "I denna roll är resultatet direkt kopplat till ersättning.",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title: "I denna roll är resultatet direkt kopplat till ersättning.",
      answer: 50,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 16,
      rank: 160,
      tab: "questions",
      kind: "QSlide",
      questionText: "Rollen innehåller tydliga inslag av ledarskap.",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title: "Rollen innehåller tydliga inslag av ledarskap.",
      answer: 50,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Stämmer inte alls - Stämmer precis",
        },
        {
          val: 2,
          text: "Stämmer inte alls - Stämmer precis",
        },
      ],
      range: [0, 100],
      labels: ["Stämmer inte alls", "Stämmer precis"],
      id: 18,
      rank: 170,
      tab: "questions",
      kind: "QSlide",
      questionText:
        "Denna roll förutsätter en hög grad ansvarstagande och eget driv.",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 6,
      showThumbLabel: false,
      thumbLabelSize: null,
      sliderStepSize: 1,
      groupAnswers: false,
      autoContinue: true,
      default: 50,
      title: "Denna roll förutsätter en hög grad ansvarstagande och eget driv.",
      answer: 50,
      answer_text: null,
      validAnswer: true,
    },
    {
      answerAlternatives: [
        {
          val: 1,
          text: "Ja",
        },
        {
          val: 2,
          text: "Nej",
        },
      ],
      range: [],
      labels: [],
      id: 28,
      rank: 180,
      tab: "questions",
      kind: "QSingle",
      questionText: "Kräver rollen att arbetstagaren har körkort?",
      introText: null,
      instructionText: null,
      atbotOKText: null,
      answer_list_id: 7,
      groupAnswers: false,
      autoContinue: false,
      default: null,
      title: "Kräver rollen att arbetstagaren har körkort?",
      answer: 1,
      answer_text: null,
      validAnswer: true,
    },
  ],
};
