<template>
  <v-col class="questions" cols="12" sm="6" lg="6">
    <v-card height="100%" class="ma-0 pa-4 rounded-card">
      <v-container>
        <v-row justify="space-between">
          <div>
            <v-icon
              v-if="!validAnswer && showMissing"
              class="mr-2"
              color="accent"
              >warning</v-icon
            >
            <div class="question-text" v-html="questionText"></div>
          </div>
          <div>
            <v-tooltip
              v-if="
                questionTextExtraInfo != null &&
                questionTextExtraInfo.length > 0
              "
              color="primary"
              top
            >
              <template v-slot:activator="{ on }">
                <v-icon class="align-self-flexend" :color="'gray'" v-on="on"
                  >help</v-icon
                >
              </template>
              <span>{{ questionTextExtraInfo }}</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-container>

      <!-- 
      <v-container>
        <v-row justify="space-between">
          
          <v-col>
            <div>
              <v-icon v-if="!validAnswer && showMissing" class="mr-2" color="error">warning</v-icon>
              <div class="question-text" v-html="questionText"></div>
            </div>
            <v-spacer></v-spacer>
            <div>
                <v-icon class="align-self-flexend" color="primary" v-on="on">help</v-icon>
   <!--          <v-tooltip
              color="primary"
              v-if="questionTextExtraInfo != null && questionTextExtraInfo.length>0"
              bottom
            >
              <template v-slot:activator="{ on }">
              </template>
              <span>{{questionTextExtraInfo}}</span>
            </v-tooltip> 
            </div>
          </v-col>
        </v-row>
      </v-container>-->
      <slot name="question"></slot>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    questionTextExtraInfo: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    disabled: Boolean,
    validAnswer: Boolean,
    showMissing: Boolean,
  },
};
</script>
