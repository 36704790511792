module.exports = {
  status: "initialize",
  companyProfile: {
    companyName: null,
    logoUrl: null
  },
  jobPostingSettings: {
    ams: {
      status: "NOT_SETUP",
      settings: {}
    },
    blocketjobs: {
      status: "NOT_SETUP",
      settings: {}
    }
  },
  logoUrl: null,
  companyName: null
};
/*
let companyInterface = {
  companyName: string;
  website: string;
  description: string;
  responsibleName: string;
  numberOfEmployees: number;
  foundingYear: number;
  logoUrl: string;
  email: string;
  phone: string;
  standardCandidateMessage: string;
  organisationNumber: string;
  visitingAddress: string;
  billingAddress: string;
  billingEmailAddress: string;
  officeAdressPostalCode: number;
  officeAdressMunicipalityCode: number;
  officeAdressCountryCode: number;
  officeAdressStreetName: string;
  officeAdressMunicipality: string;
  officeAdressCountry: string;
} */