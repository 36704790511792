<template>
  <v-alert
    v-if="visible"
    class="mx-auto"
    max-width="450"
    border="left"
    text
    color="error"
    transition="scale-transition"
  >
    <v-row align="center">
      <v-col class="grow">{{ message }}</v-col>
      <v-col class="shrink py-0">
        <Button :type="'error'" @clicked="$router.go()">{{
          btnMessage
        }}</Button>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import Button from "./Button";
export default {
  components: {
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: String,
    btnMessage: String,
  },
  data: () => {
    return {};
  },
};
</script>
