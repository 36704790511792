function createDatacollection(data, labelKey, dataKey) {
  let collection = { labels: [], data: [] };
  data.forEach((element) => {
    collection.labels.push(element[labelKey]);
    collection.data.push(element[dataKey]);
  });
  return collection;
}

module.exports = {
  createDatacollection: createDatacollection,
};
