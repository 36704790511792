import Vue from "vue";
// import Vuetify from 'vuetify/lib';
import VCalendar from "v-calendar";
import sv from "vuetify/es5/locale/sv";
import Vuetify from "vuetify/lib/framework";
import { theme } from "../json/colors.js";

Vue.use(Vuetify);

Vue.use(VCalendar, {
  componentPrefix: "vc", // Now use vc-calendar and vc-date-picker
});

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: theme,
  lang: {
    locales: { sv },
    current: "sv",
  },
});
