<template>
  <Sticky><slot></slot></Sticky>
</template>
<script>
import Sticky from "../basic/Sticky";
export default {
  components: { Sticky },
  props: {},
  data: () => {
    return {};
  },
};
</script>
<style>
.make-it-stick {
  position: sticky;
}
</style>
