import { getJobs } from "../../requests/global";
import { dateHasNotPassed } from "../../utils/misc"

const jobs = async (context, data) => {
  context.commit("status", "pending");
  if (context.rootState.user.companyPermissions == null) {
    await context.dispatch("user/getUserInformation", null, { root: true });
  }
  let jobs;
  if (context.rootState.user.companyPermissions.identifyCandidates) {
    jobs = await getJobs({ params: { extraFields: "email" } });
  }
  else {
    jobs = await getJobs({});
  }

  jobs = setStatusLabelsAndColor(jobs, context.state.jobStatusDefinition);

  var upcomingInterviews = [];

  for (let i = 0; i < jobs.length; i++) {
    if (jobs[i].interviews != null) {
      for (let k = 0; k < jobs[i].interviews.length; k++) {
        if (jobs[i].interviews[k].interview_status == "BOOKED" && dateHasNotPassed(new Date(jobs[i].interviews[k].start))) {
          upcomingInterviews.push({
            ...jobs[i].interviews[k],
            title: jobs[i].title,
          });
        }
      }
    }
  }
  upcomingInterviews.sort((a, b) => {
    if (a.start > b.start) {
      return 1;
    } else {
      return -1;
    }
  });
  context.commit("upcomingInterviews", upcomingInterviews);

  for (let i = 0; i < jobs.length; i++) {
    if (jobs[i].applicants != null && jobs[i].applicants.length > 0) {
      for (let k = 0; k < jobs[i].applicants.length; k++) {
        jobs[i].applicants[k].jobTitle = jobs[i].title;
        jobs[i].applicants[k].job_guid = jobs[i].job_guid;
      }
    }
  }
  var applicants = {};
  jobs.forEach((element) => {
    if (element.applicants != null && element.applicants.length > 0) {
      applicants[element.job_guid] = element.applicants;
    }
  });
  let jobDrafts = []
  let jobsWithoutDrafts = []
  jobs.forEach((job) => {
    if (job.status == "DRAFT") {
      jobDrafts.push(job);
    }
    else {
      jobsWithoutDrafts.push(job);
    }
  });
  context.commit("jobDrafts", jobDrafts);
  context.commit("jobs", jobsWithoutDrafts);
  context.commit("allJobs", jobs);
  context.dispatch("applications/applications", applicants, { root: true });
  context.commit("status", "success");
};

const jobById = (context, job_guid) => {
  var job = {};
  for (var i = 0; i < context.state.jobs.length; i++) {
    if (job_guid == context.state.jobs[i].job_guid) {
      job = JSON.parse(JSON.stringify(context.state.jobs[i]));
    }
  }

  return job;
};

function setStatusLabelsAndColor(jobs, jobStatusDefinition) {
  jobs.forEach((element) => {
    element.statusText = jobStatusDefinition[element.status].statusText;
    element.statusColor = jobStatusDefinition[element.status].statusColor;
    var cityText = "";
    try {
      if (element.city != null) {
        element.city.forEach((city) => {
          cityText += city.name + ", ";
        });
        element.cityText = cityText.slice(0, -2)
      }
    } catch (e) {
      console.log(e);
    }
  });

  return jobs;
}

export default {
  jobs: jobs,
  jobById: jobById,
};
