<template>
  <v-card :elevation="0" class="rounded-lg">
    <h4 class="pa-4 mt-0">{{ title }}</h4>
    <v-card-text v-if="items.length == 0">
    {{textIfEmpty}}
    </v-card-text>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="$emit('clicked', item)"
        >
          <v-list-item-icon class="mr-1">
            <v-icon
              v-if="item.icon != null || icon != null"
              color="primary"
              v-text="item.icon"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item[textField] }}</v-list-item-title>
            <v-list-item-subtitle v-if="subTitleTextField != null">{{
              item[subTitleTextField]
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  props: {
    title: String,
    textField: String,
    subTitleTextField: String,
    items: Array,
    icon: String,
    textIfEmpty: String
  },
  created: function () {
    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i].icon != null) {
        continue;
      }
      this.items[i].icon = this.icon;
    }
  },
};
</script>
