module.exports = {
  questionsKey: "companyQuestions",
  status: "initialize",
  groupedQuestions: null,
  breadcrumbs: null,
  progressBar: null,
  tabSortOrder: [
    { tab: "company", sortKey: 1 },
    { tab: "account", sortKey: 2 },
    { tab: "questions", sortKey: 3 },
  ],
};
