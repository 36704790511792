import { getMatchColor } from "../../utils/misc";

const applications = async (context, applications) => {
  context.commit("status", "pending");
  applications = addMatchColorToApplicantsInJobs(applications);

  applications = setStatusLabelsAndColor(
    applications,
    context.state.applicantsStatusDefinition
  );
  var latestApplications = sortLatestApplications(applications);
  context.commit("latestApplications", latestApplications.slice(0, 3));
  context.commit("applications", applications);
  context.commit("status", "success");
};

const updateApplications = (context, data) => {
  var tempApp = context.state.applications;
  tempApp[data.job_guid] = data.applicants;
  context.commit("applications", tempApp);
};

function addMatchColorToApplicantsInJobs(applications) {
  for (var item in applications) {
    applications[item].forEach((element) => {
      element.matchColor = getMatchColor(element.score);
    });
  }
  return applications;
}

function setStatusLabelsAndColor(applications, applicantsStatusDefinition) {
  for (var item in applications) {
    applications[item].forEach((element) => {
      element.statusText =
        applicantsStatusDefinition[element.status].statusText;
      element.statusColor =
        applicantsStatusDefinition[element.status].statusColor;
      element.applicationDate = element.applicationTimestamp.substring(0, 10);
    });
  }
  return applications;
}

function sortLatestApplications(applications) {
  var latestApplications = [];
  for (var item in applications) {
    for (var i = 0; i < applications[item].length; i++) {
      //console.log(applications[item][i]);
      latestApplications.push(applications[item][i]);
    }
  }
  latestApplications.sort((a, b) => {
    if (a.applicationTimestamp < b.applicationTimestamp) {
      return 1;
    } else {
      return -1;
    }
  });

  return latestApplications;
}

export default {
  applications: applications,
  updateApplications: updateApplications,
};
