<template>
  <!-- <v-bottom-navigation
    :value="activeBtn"
    color="deep-purple accent-4"
  >
    <v-btn>
      <span>Recents</span>
      <v-icon>mdi-history</v-icon>
    </v-btn>

    <v-btn>
      <span>Favorites</span>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn>
      <span>Nearby</span>
      <v-icon>mdi-map-marker</v-icon>
    </v-btn>
  </v-bottom-navigation> -->

  <v-bottom-navigation
    v-if="$vuetify.breakpoint.mdAndDown"
    v-model="navigation"
    app
    active-class="font-weight-white"
    color="white"
    background-color="primary"
    dark
    grow
  >
    <v-btn
      v-for="item in items"
      :key="item.id"
      :value="item.name"
      :to="item.path"
    >
      <span>{{ item.name }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    navigation: "Företaget",
    activeBtn: 1,
    items: [
      { id: 1, name: "Översikt", icon: "speed", path: "/" },
      { id: 4, name: "Processer", icon: "mdi-sitemap", path: "/p" },
      { id: 7, name: "Mer", icon: "menu", path: "/menu" },

      /*
      { id: 2, name: "Profil", icon: "mdi-home", path: "/c" },
      { id: 3, name: "Skapa", icon: "add", path: "/cr" },
      { id: 5, name: "Inställningar", icon: "mdi-tune", path: "/s" },
      { id: 6, name: "Support", icon: "mdi-chat-processing", path: "/su" }
      */
    ],
  }),
};
</script>
<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: inherit;
}
</style>
