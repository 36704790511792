<template>
  <v-card
    v-if="items.length > 0 || alwaysDisplay"
    class="mx-auto"
    max-width="600"
    tile
  >
    <v-card-title @click="showItems = !showItems">
      <h5>{{ title }} ({{ items.length }})</h5>
      <div class="flex-grow-1"></div>
      <v-btn icon>
        <v-icon>{{ showItems ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition v-if="items.length > 0">
      <div v-show="showItems">
        <v-list>
          <v-list-item-group color="success">
            <v-col v-for="(item, i) in items" :key="item.interview_guid">
              <v-card width="100%">
                <v-card-title class="clickable" @click="open(item)">
                  {{ item.name }}
                  <v-spacer></v-spacer>

                  <AddToCalendar :items="item.addToCalendar" />
                </v-card-title>

                <v-card-subtitle>
                  {{ item.start + "-" + item.end.substring(11, 16) }}
                </v-card-subtitle>
                <v-card-actions
                  @click="
                    item.showDetailedInterviewInfo =
                      !item.showDetailedInterviewInfo
                  "
                >
                  <v-btn color="secondary" text class="text-none">
                    Se mer information
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn icon>
                    <v-icon>{{
                      item.showDetailedInterviewInfo
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="item.showDetailedInterviewInfo">
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-text-field
                        readonly
                        label="Kontaktinfo"
                        :value="item.employee_ice_contact"
                      >
                      </v-text-field>
                      <v-textarea
                        label="Mötesinformation"
                        readonly
                        rows="3"
                        :value="item.meeting_info"
                        >{{ item.meeting_info }}
                      </v-textarea>
                      <v-textarea
                        label="Arbetsgivarens kommentar"
                        readonly
                        rows="3"
                        :value="item.employer_comment"
                        >{{ item.employer_comment }}
                      </v-textarea>

                      <v-textarea
                        label="Arbetstagarens kommentar"
                        readonly
                        rows="3"
                        :value="item.employee_comment"
                        >{{ item.employee_comment }}
                      </v-textarea>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-list-item-group>
        </v-list>
      </div>
    </v-expand-transition>
    <v-card-text v-else>
      <p>{{ textWhenEmpty }}</p>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import store from "../../store";

import snackbarMixin from "../../mixins/snackbar";
import basic from "../../mixins/basic";

export default {
  components: {},
  mixins: [basic, snackbarMixin],
  props: {
    items: Array,
    title: String,
    alwaysDisplay: Boolean,
    textWhenEmpty: String,
  },
  data: function () {
    return {
      showItems: false,
    };
  },

  methods: {
    open: function (item) {
      this.$emit("open", item);
    },
  },
};
</script>
