<template>
  <div class="mx-auto page-normal">
    <Spinner v-if="loading" message="Brite Bot bygger grafer..."></Spinner>
    <v-container v-else>
      <!-- <v-row>
        <v-col>
          <Searchbox />
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="flex-grow-0 align-self-center">
          <v-row>
            <v-col> <Settings class="d-inline-flex" /></v-col>
          </v-row>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col>
          <h1 class="my-10">
            Välkommen tillbaka, {{ userInformation.userAccount.name }} 👋
          </h1>
        </v-col>
      </v-row>
      <AlertBar
        :visible="alertBar.visible"
        :message="alertBar.message"
        :btn-message="alertBar.btnMessage"
      ></AlertBar>
      <v-row v-if="jobStatisticsForDashboard != null">
        <v-col cols="12"> <h2>Statistik</h2> </v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="rounded-lg" elevation="0">
                <ApplicationsLineChart
                  v-if="
                    jobStatisticsForDashboard.applicationDatacollection.data.length >
                    0
                  "
                  :height="graphHeight"
                  :dataset="jobStatisticsForDashboard.applicationDatacollection"
                />
                <v-col v-else class="text-center">
                  <p class="text-center"><b>Ansökningar</b></p>

                  <span
                    >Du har inte fått några ansökningar än. När du fått det kan
                    du se dem här 🔍</span
                  ></v-col
                >
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="rounded-lg" elevation="0">
                <SalaryBarChart
                  v-if="
                    jobStatisticsForDashboard.salaryDatacollection.data.length > 0
                  "
                  :height="graphHeight"
                  :dataset="jobStatisticsForDashboard.salaryDatacollection"
                />
                <v-col v-else class="text-center">
                  <p class="text-center"><b>Löneanspråk</b></p>

                  <span
                    >När det börjar komma in kandidater kommer du kunna se deras
                    löneanspråk per tjänst här 💰</span
                  ></v-col
                >
              </v-card>
            </v-col>
            <v-col cols="12">
              <InfoSheet>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                    class="ma-0 pa-0 d-flex justify-start justify-md-center"
                  >
                    <v-img max-height="150" contain src="https://storage.googleapis.com/brite-resources/img/ag/img/Introduction.svg" ></v-img>
                  </v-col>
                  <v-col class="my-2" cols="12" md="7">
                    <h3 class="mt-0 white--text">En rekryterares checklista</h3>
                    <span class="white--text"
                      >Att rekrytera diskrimineringsfritt, snabbt och med hög
                      träffsäkerhet är egentligen ganska enkelt. Om man gör som
                      forskningen säger. Vi listar 9 tips som du som rekryterare
                      behöver veta.
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="d-flex justify-start justify-md-end align-end"
                  >
                    <a
                      href="https://www.brite.jobs/blogg/en-rekryterares-checklista-9-tips"
                      target="_blank"
                    >
                      <BigButton
                        :text-color="'secondary'"
                        :color="'white'"
                        :text="false"
                        >Läs mer</BigButton
                      ></a
                    >
                  </v-col>
                </v-row>
              </InfoSheet>
            </v-col>

            <v-col cols="12">
              <h2>Pågående rekryteringar</h2>
              <v-simple-table
                v-if="jobs != null && jobs.length > 0"
                class="clickable-rows-table"
                fixed-header
                height="300px"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"><b>Roll</b></th>
                      <th class="text-left"><b>Enhet</b></th>
                      <th class="text-left"><b>Ansökningar</b></th>
                      <th class="text-left"><b>Status</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in jobs"
                      :key="item.name"
                      @click="openProcess(item)"
                    >
                      <td>{{ item.title }}</td>
                      <td>{{ item.department }}</td>
                      <td>
                        {{
                          item.applicants != null ? item.applicants.length : 0
                        }}
                      </td>
                      <td>
                        <v-chip color="ongoing">{{ item.statusText }}</v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row v-else
                ><v-col
                  ><span>Du har inga pågående rekryteringar just nu 💼</span>
                </v-col></v-row
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <UpcomingInterviewNotificationList
                :title="'Senaste ansökningarna'"
                :text-field="'title'"
                icon="today"
                :interviews="upcomingInterviews"
              >
              </UpcomingInterviewNotificationList>
              <!--  <SimpleList
                :items="upcomingInterviews"
                prependType="calendar"
                :keyName="'job_guid'"
                :itemTitle="'name'"
                :itemSubtitle="'title'"
                :itemSubtitle2="'start'"
                :itemActionAvatarColor="'ongoing'"
                :title="'Kommande intervjuer'"
                v-on:listItemClick="openProcess"
              /> -->
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <ApplicationsNotificationList
                :title="'Senaste ansökningarna'"
                :text-field="'title'"
                icon="notification_important"
                :applications="latestApplications"
              >
              </ApplicationsNotificationList>
              <!-- <SimpleList
                :items="latestApplications"
                prependType="briteScore"
                :keyName="'application_guid'"
                :itemTitle="'name'"
                :itemSubtitle="'jobTitle'"
                :itemSubtitle2="''"
                :itemActionText="'applicationDate'"
                :itemActionAvatarColor="'statusColor'"
                :title="'Senaste ansökningarna'"
                v-on:listItemClick="openCandidate"
              >
              </SimpleList>-->
            </v-col>
          </v-row>
          <!--   <v-row>
            <v-col cols="12" v-if="upcomingInterviews != null && upcomingInterviews.length > 0">
              <NotificationList
                :title="'Kommande intervjuer'"
                :textField="'title'"
                icon="notification_important"
                :items="upcomingInterviews"
              >
              </NotificationList>
            </v-col>
            <v-col v-if="latestApplications != null && latestApplications.length > 0">
             <SimpleList
            :items="latestApplications"
            prependType="calendar"
            :keyName="'job_guid'"
            :itemTitle="'name'"
            :itemSubtitle="'title'"
            :itemSubtitle2="'description'"
            :itemActionText="'start'"
            :itemActionAvatarColor="'ongoing'"
            :title="'Kommande intervjuer'"
            v-on:listItemClick="openProcess"
          />
            </v-col>
          </v-row> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import alertBarMixin from "../mixins/alertBar";
import Searchbox from "../components/views/dashboard/Searchbox";
import Settings from "../components/views/dashboard/Settings";
import NotificationList from "../components/views/dashboard/NotificationList";
import ApplicationsNotificationList from "../components/views/dashboard/ApplicationsNotificationList";
import UpcomingInterviewNotificationList from "../components/views/dashboard/UpcomingInterviewNotificationList";

import SalaryBarChart from "../components/views/dashboard/SalaryBarChart";
import ApplicationsLineChart from "../components/views/dashboard/ApplicationsLineChart";
import store from "../store";
import { mapState, mapActions } from "vuex";

import basicMixin from "../mixins/basic";

export default {
  components: {
    Searchbox,
    Settings,
    NotificationList,
    ApplicationsNotificationList,
    UpcomingInterviewNotificationList,
    SalaryBarChart,
    ApplicationsLineChart,
  },
  mixins: [basicMixin, alertBarMixin],
  computed: {
    ...mapState("jobStatistics", {
      jobStatisticsStatus: "status",
      jobStatisticsForDashboard: "jobStatisticsForDashboard",
    }),
    ...mapState("applications", ["latestApplications"]),
    ...mapState("jobs", {
      jobStatus: "status",
      upcomingInterviews: "upcomingInterviews",
      jobs: "jobs",
    }),
    ...mapState("user", {
      userStatus: "status",
      userInformation: "userInformation",
    }),
  },
  watch: {
    jobStatisticsStatus: function () {
      this.loadingDatalist[2].status = this.jobStatisticsStatus;
      this.handleLoad();
    },
    jobStatus: function () {
      this.loadingDatalist[0].status = this.jobStatus;
      this.handleLoad();
    },
    userStatus: function () {
      this.loadingDatalist[1].status = this.userStatus;
      this.handleLoad();
    },
  },
  mounted: async function () {
    this.loadingDatalist = [
      { status: this.jobStatus, load: this.getJobs },
      { status: this.userStatus, load: this.getUserInformation },
      { status: this.jobStatisticsStatus, load: this.loadJobStatistics },
    ];
    this.loadingDatalist.forEach((element) => {
      if (element.status == "initialize") {
        element.load();
      }
    });
    this.handleLoad();

    // this.applicationsHandleLoad();
  },
  methods: {
    ...mapActions("user", ["getUserInformation"]),
    ...mapActions("jobStatistics", ["loadJobStatistics"]),
    ...mapActions("jobs", { getJobs: "jobs" }),
    openProcess(item) {
      this.$router.push({ name: "Job", params: { id: item.job_guid } });
    },
    handleLoad: function () {
      let loadingStatus = "success";
      for (var i = 0; i < this.loadingDatalist.length; i++) {
        if (this.loadingDatalist[i].status == "success") {
          continue;
        } else if (this.loadingDatalist[i].status == "failed") {
          loadingStatus = "failed";
          break;
        } else {
          loadingStatus = "pending";
          break;
        }
      }
      if (loadingStatus == "failed") {
        this.handleAlertBar({
          visible: true,
          message: "Fel vid laddning",
          btnMessage: "Ladda om sidan",
        });
        this.loading = false;
      } else if (loadingStatus == "success") {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      loading: true,
      loadingDatalist: [],
      graphHeight: 250,
    };
  },
};
</script>
