<template>
  <div>
    <v-card
      flat
      :class="{ 'file-hover': fileHover }"
      @dragenter.stop="dragCounter++"
      @drop="handleDrop($event)"
      @dragleave.stop="dragCounter--"
    >
      <v-card-text class="pa-4">
        <div id="file-upload-container" class="file-upload-card-content">
          <form enctype="multipart/form-data" @submit.prevent="saveFile">
            <v-row
              :class="{ 'visibility-hidden': fileHover }"
              class="fields justify-center"
            >
              <!-- <v-col cols="12">
                <v-text-field v-model="urlSrc" placeholder="Ange länk till fil" />
              </v-col> -->
              <div class="upload-btn-wrapper">
                <v-btn class="text-none" rounded color="primary"
                  >Välj fil på datorn</v-btn
                >
                <input
                  ref="file"
                  type="file"
                  name="myfile"
                  @change="handleSelect"
                />
              </div>
              <!-- <v-btn class="text-none" rounded color="primary">
            Upload File
            <input type="file" ref="file" @change="onSelect" />
              </v-btn>-->
              <div class="fields mt-4">
                <span>Eller släpp din bild här</span>
              </div>
              <div class="message">
                <v-alert
                  v-if="errorMessage != null"
                  dense
                  outlined
                  type="error"
                  >{{ errorMessage }}</v-alert
                >
                <v-alert v-if="message != null" dense outlined type="success">{{
                  message
                }}</v-alert>
              </div>
            </v-row>
            <v-row :class="{ 'visibility-hidden': !fileHover }">
              <h3>Nu kan du släppa</h3>
            </v-row>
            <v-row align="center" justify="center">
              <div
                style="display: inline-block; padding: 25px"
                class="grey lighten-2"
              >
                <v-img
                  style="display: block; height: 100%; width: 200px"
                  contain
                  :src="fileSrc"
                  aspect-ratio="1"
                  widht="200"
                  max-width="200"
                ></v-img>
              </div>
            </v-row>
          </form>
        </div>
      </v-card-text>
      <v-card-actions v-if="fileSrc != originalUrl" justify="center">
        <Spinner v-if="uploadingImage == true" :message="'Laddar upp bild'" />
        <slot v-else name="actions">
          <v-row class="mx-0" justify="space-around">
            <v-btn
              class="dialog-action-btn text-none"
              rounded
              text
              color="error"
              @click="fileSrc = originalUrl"
              >Avbryt</v-btn
            >
            <v-btn
              class="dialog-action-btn text-none"
              rounded
              dark
              color="primary"
              @click="saveFile"
              >Spara</v-btn
            >
          </v-row>
        </slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";
import mixin from "@/mixins/general";
import Spinner from "@/components/misc/Spinner.vue";

export default {
  name: "FileUpload",
  components: {
    vueDropzone: vue2Dropzone,
    Spinner,
  },
  mixins: [mixin],
  props: {
    originalUrl: {
      default:
        "https://storage.googleapis.com/brite-files/0a74f4cc-b859-4eef-94c0-cac0f3e61b47.png",
      type: String,
    },
  },
  data() {
    return {
      uploadingImage: false,
      wrongFileTypeMessage: "Fel fil-typ. Jpg och Png godkända.",
      couldNotFetchMessage: "Inte en bild eller kunde inte hämta",
      fileSizeLimit: 5000000,
      fileSizeTooBigMessage: "För stor fil, max 5mb",
      allowedTypes: ["image/jpeg", "image/jpg", "image/png"],
      dragCounter: 0,
      file: null,
      urlSrc: "",
      fileSrc: ``,
      errorMessage: null,
      message: null,
      uploadedMessage: "Fil sparad!",
    };
  },
  computed: {
    fileHover: function () {
      return this.dragCounter > 0;
    },
  },
  watch: {
    originalUrl: function () {
      this.fileSrc = this.originalUrl;
    },
    urlSrc: async function () {
      this.corsIssue = false;
      this.errorMessage = null;
      this.message = null;

      if (this.validBase64(this.urlSrc)) {
        console.log("base64 string");
      } else if (!this.validUrl(this.urlSrc)) {
        console.log("Not a valid url");
        return;
      }
      try {
        let b = await fetch(this.urlSrc).then((r) => r);
        let blob = await b.blob();
        var file = new File([blob], Date.now(), {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.checkFile(file);
      } catch (e) {
        this.fileSrc = this.urlSrc;

        //this.errorMessage = this.couldNotFetchMessage;
        this.corsIssue = true;
      }
    },
  },
  created: function () {
    this.fileSrc = this.originalUrl;
    window.addEventListener(
      "dragover",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
  },
  methods: {
    updateOriginalUrl: function (link) {
      this.$emit("update", link);
      //this.fileSrc = this.originalUrl;
    },
    previewFile(file) {
      var self = this;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        self.file = file;
        self.fileSrc = reader.result;
      };
    },
    validateFile: function (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.errorMessage = this.wrongFileTypeMessage;
        return false;
      }
      if (file.size > this.fileSizeLimit) {
        this.errorMessage = this.fileSizeTooBigMessage;
        return false;
      }
      return true;
    },
    handleDrop: function (e) {
      e.preventDefault;
      this.corsIssue = false;
      this.dragCounter = 0;
      this.checkFile(e.dataTransfer.files[0]);
    },
    handleSelect: function () {
      this.corsIssue = false;
      this.checkFile(this.$refs.file.files[0]);
    },
    checkFile(file) {
      this.errorMessage = null;
      this.message = null;
      if (this.validateFile(file)) {
        this.previewFile(file);
      }
    },
    async saveFile() {
      var self = this;
      this.uploadingImage = true;
      if (this.corsIssue) {
        this.$store
          .dispatch("uploadUrl", this.fileSrc)
          .then((response) => {
            self.message = self.uploadedMessage;
            self.updateOriginalUrl(response);

            this.uploadingImage = false;
          })
          .catch((err) => {
            console.log(err);
            self.errorMessage = err;
            this.uploadingImage = false;
          });
        /* 
        try {
          //  await axios.post("http://localhost:3000/external/upload/url", {
          var response = await axios.post(
            "https://us-central1-higher.cloudfunctions.net/service/external/upload/url",
            {
              url: this.fileSrc
            }
          );
          self.message = self.uploadedMessage;
          self.updateOriginalUrl(response.data.data.url);
        } catch (e) {
          self.message = e;
        } */
      } else {
        //return;
        const formData = new FormData();
        formData.append("file", self.file);
        this.$store
          .dispatch("uploadFile", formData)
          .then((response) => {
            self.message = self.uploadedMessage;
            self.updateOriginalUrl(response);
            this.uploadingImage = false;
          })
          .catch((err) => {
            console.log(err);
            self.errorMessage = err;
            this.uploadingImage = false;
          });
      }
    },
  },
};

/* 
function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}
 */
</script>
<style>
.dialog-action-btn {
  min-width: 200px !important;
}
.file-upload-card-content {
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.file-upload-card-content .row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-hover {
  background-color: rgb(241, 241, 241) !important;
  opacity: 0.8;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

/* .vue-dropzone {
  display: inline-block;
} */
.vue-dropzone .dz-error-message {
  opacity: 1 !important;
  top: 0 !important;
}
.vue-dropzone > .dz-preview .dz-details {
  bottom: 0;
  top: 0;
  color: #fff;
  background-color: #182540;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  text-align: left;
}
</style>
