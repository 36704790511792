<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="autocomplete popup">
        <v-col>
          <v-text-field
            ref="cityDialogInput"
            v-model="cityDialogQuery"
            prepend-inner-icon="keyboard_backspace"
            append-icon="search"
            placeholder="Sök..."
            clearable
            @click:prepend-inner="dialog = false"
          ></v-text-field>
          <h5 v-if="this.city.length > 0">
            Valda:
            <span v-for="cty in city" :key="'city-span' + cty.description">
              <v-chip color="primary" @click="remove(city, cty)">
                {{ cty.name }}
                <v-icon right>close</v-icon>
              </v-chip>
            </span>
          </h5>
          <h5 v-if="this.city_list_fix.length > 0">Sökresultat:</h5>
          <span v-for="cty in city_list_fix" :key="cty.id">
            <v-chip outlined @click="addCity(cty)">
              {{ cty.name }}
              <v-icon right>add</v-icon>
            </v-chip>
          </span>
          <!--            <v-checkbox
              color="primary"
              v-for="cty in city_list_fix"
              v-bind:key="'check'+cty.description"
              :label="cty.name"
              :value="JSON.stringify(cty)"
              v-model="cityDialog"
              dense="true"
              return-object
          ></v-checkbox>-->
        </v-col>
      </v-card>
    </v-dialog>
    <v-autocomplete
      v-if="!disabled"
      v-model="city_temp"
      :items="city_list_fix"
      :search-input.sync="cityQuery"
      item-text="name"
      item-value="id"
      placeholder="Sök..."
      return-object
      no-data-text="Inga träffar"
      no-filter
      tags
      clearable
      @mouseup="cityFocus"
    >
      <template v-slot:selection="data"></template>
    </v-autocomplete>
    <v-expansion-panels v-if="city.length > 0" v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header
          >Valda städer ({{ city.length }})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody class="simple-table-full-width removeable-rows">
                <tr
                  v-for="cty in city"
                  :key="cty.id"
                  @click="
                    if (!disabled) {
                      remove(city, cty);
                    }
                  "
                >
                  <td>{{ cty.name }}</td>
                  <td>
                    <v-icon small>close</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!--  <v-list>
            <v-list-item
              class="compact-list"
              v-for="cty in city"
              :key="cty.id"
              dense
              @click="remove(city,cty)"
            >
              <v-list-item-content>
                <v-list-item-title>{{cty.name}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon @click="remove(city,cty)">
                  <v-icon small>close</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>-->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--  <v-card v-if="city.length>0" class="mx-auto compact-list" max-height="1000" tile>
      <v-subheader>Valda städer</v-subheader>
      <v-list class="expandable-list" :class="{'expanded' : !limit}">
        <v-list-item
          class="compact-list"
          v-for="cty in filteredCity"
          :key="cty.id"
          dense
          @click="remove(city,cty)"
        >
          <v-list-item-content>
            <v-list-item-title>{{cty.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-icon @click="remove(city,cty)">
              <v-icon small>close</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="city.length>3" class="compact-list" dense @click="limit = limit ? false : true">
          <v-list-item-content >
            <v-list-item-title>
              <span v-if="limit">Visa alla ({{city.length}})</span>
              <span v-else>Dölj</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon v-if="limit" small>arrow_downward</v-icon>
              <v-icon v-else small>arrow_upward</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>-->
  </div>
</template>

<script>
import { getCitySuggestions } from "../../requests/global";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    answer: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      panel: null,
      timeoutID: false,
      debounceDelay: 320,
      dialog: false,
      cityQuery: null,
      cityQueryfix: null,
      cityDialogQuery: null,
      cityDialog: [],
      choosenCities: [],
      city_list: [],
      //city: [{id:1, name:"asd"},{id:2, name:"asd"},{id:3, name:"asd"},{id:4, name:"asd"},{id:5, name:"asd"}],
      city: [], //this.answer,
      city_temp: [],
      city_list_fix: [
        { id: 1, name: "Hela Sverige", description: "Hela Sverige" },
      ],
      citySelection: [],
      limit: true,
      dValidAnswer: false,
    };
  },
  computed: {
    filteredCity() {
      return this.city;
    },
  },
  mounted: function () {
    this.city = this.answer;
    /* if (this.answer != null && this.answer.length > 0) {
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer: this.city,
        validAnswer: this.dValidAnswer,
      });
    } */
  },
  watch: {
    cityDialog: {
      handler: function (val, oldVal) {
        /* var index = this.findId(this.city, this.cityDialog.id);
        console.log(index);
        if (index < 0) {
          this.city[this.city.length] = this.city_temp;
          this.city.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.$emit("update", this.city);

        } */
        /*         this.city = [];
        for (var i = 0; i < this.cityDialog.length; i++) {
          this.city[i] = JSON.parse(this.cityDialog[i]);
        }
        this.city.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.$refs.cityDialogInput.focus();
        this.cityDialogQuery = null;
        this.$emit("update", this.city); */
      },
    },
    city_temp: {
      handler: function (val, oldVal) {
        //console.log("updating city with city_temp");
        var index = this.findId(this.city, this.city_temp.id);
        //console.log(index);
        if (index < 0) {
          this.city[this.city.length] = this.city_temp;
          this.city.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.$emit("update", this.city);
        }
        //this.city_temp = null;
        //console.log("updating city with city_temp");
      },
    },
    city: {
      handler: function () {
        //console.log("changed city");
        //console.log(this.city);
        this.panel = 0;
        if (this.city.length > 0) {
          this.dValidAnswer = true;
        } else {
          this.dValidAnswer = false;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.city,
          validAnswer: this.dValidAnswer,
        });
        setTimeout(() => {
          //this.$refs.cityAutoComplete.isMenuActive = false;
        }, 50);
      },
    },
    cityDialogQuery: {
      handler: async function (val, oldVal) {
        var self = this;
        this.city_list_fix = [];
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(function () {
          self.getSuggestions(val);
        }, this.debounceDelay);
      },
    },
    cityQuery: {
      handler: async function (val, oldVal) {
        var self = this;
        this.city_list_fix = [];
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(function () {
          self.getSuggestions(val);
        }, this.debounceDelay);
      },
      deep: false,
    },
  },

  methods: {
    addCity: function (city) {
      var index = this.findId(this.city, city.id);
      if (index < 0) {
        this.city.push(city);
        this.city.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.$emit("update", this.city);
      }
    },
    getSuggestions: async function (query) {
      this.cityFocus();
      this.city_list_fix = [
        { id: 1, name: "Hela Sverige", description: "Hela Sverige" },
      ];

      if (query != "" && query != " " && query != "  " && query != null) {
        var res = await getCitySuggestions({ params: { input: query } });
        this.city_list_fix = [];
        this.city_list_fix = res;
      } else {
        this.city_list_fix = [
          { id: 1, name: "Hela Sverige", description: "Hela Sverige" },
        ];
      }
    },
    cityFocus: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        setTimeout(() => {
          this.dialog = true;
          this.$refs.cityDialogInput.focus();
        }, 1);
      }
    },
    findId: function (obj, id) {
      for (var i = 0; i < obj.length; i++) {
        if (obj[i].id == id) {
          return i;
        }
      }
      return -1;
    },
    removeDialogCity(item) {
      for (var i = 0; i < this.cityDialog.length; i++) {
        if (JSON.parse(this.cityDialog[i]).id == item.id) {
          this.cityDialog.splice(i, 1);
        }
      }
    },
    remove(list, item) {
      //const index = list.indexOf(item.id);
      const index = this.findId(list, item.id);
      if (index >= 0) list.splice(index, 1);
    },
    filteredList: function (list, answer) {
      return list.filter(function (itm) {
        return answer.includes(itm.id) == true;
      });
    },
  },
};
</script>

<style>
/* .expandable-list {
    max-height: 200px;
    transition: height 2s;
}
.expandable-list.expanded {
    max-height: 1200px;
    transition: height 2s;
} */
</style>
