const ouical = require("./ouical");

module.exports = {
  addToCal(item) {
    // console.log("item calendar");
    // console.log(item);
    var ouicalData = ouical.addToCalendarData({
      data: {
        // Event title
        title: "Intervju: " + item.title,

        // Event start date
        start: new Date(item.start.replace(/-/g, "/")),
        end: new Date(item.end.replace(/-/g, "/")),
        // start: new Date("2020/03/04 13:00"),
        // end: new Date("2020/03/04 14:00"),
      },
    });
    // console.log(ouicalData);
    return ouicalData;
  },
};
