import { jobQuestions } from "./state";

var {
  getJobQuestions,
  getCandidateQuestions,
} = require("../../requests/global");

import { prepareQuestions } from "./helpers";

const reset = (context, data) => {
  context.commit("job_guid", null);
  context.commit("application_guid", null);
  context.commit("applicationInfo", null);
  context.commit("candidateQuestions", []);
  context.commit("jobQuestions", []);
  context.commit("motivationQuestions", []);
  context.commit("candidateBasicQuestions", []);
  context.commit("status", "initialize");
};

const load = async (context, data) => {
  context.dispatch("reset", {
    job_guid: data.job_guid,
    application_guid: data.application_guid,
  });

  if (context.rootState.jobs.jobs == null) {
    await context.dispatch("jobs/jobs", null, { root: true });
  }

  context.dispatch(
    "process/loadProcessData",
    { job_guid: data.job_guid },
    { root: true }
  );

  var candidate = context.rootGetters[
    "applications/applicationByApplicationGuid"
  ]({ application_guid: data.application_guid, job_guid: data.job_guid });
  if (candidate == null) {
    context.commit("status", "failed");
    return;
  }
  context.commit("candidate", candidate);
  //var candidate = await getCandidate({ job_guid: data.job_guid, application_guid: data.application_guid })

  var jobQuestions = await getJobQuestions({ job_guid: data.job_guid });
  context.commit("jobQuestions", jobQuestions.questions);

  var candidateQuestions = await getCandidateQuestions({
    job_guid: data.job_guid,
    application_guid: data.application_guid,
  });
  context.commit("candidateQuestions", candidateQuestions);

  var qs = prepareQuestions({
    jobQuestions: jobQuestions.questions,
    candidateQuestions: candidateQuestions,
  });

  //var candidate = context.rootGetters['applications/applicationsByJobGuid'](data.job_guid);
  // console.log("candidate is ")
  // console.log(candidate);

  context.commit("candidateBasicQuestions", qs.candidateBasicQuestions);
  context.commit("motivationQuestions", qs.motivationQuestions);

  context.commit("status", "success");
};

const setCandidate = (context, candidate) => {
  context.commit("candidate", candidate);
};

export default {
  reset: reset,
  load: load,
  setCandidate: setCandidate,
};
