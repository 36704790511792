var { getJobStatistics } = require("../../requests/global");
var { createDatacollection } = require("../../utils/chartDataHelper.js");

const loadJobStatistics = async (context, data) => {
  context.commit("status", "pending");
  let statistics = await getJobStatistics({});
  context.commit("jobStatistics", statistics);
  let jobStatisticsForDashboard =
    createJobStatisticsDatacollections(statistics);
  context.commit("jobStatisticsForDashboard", jobStatisticsForDashboard);
  context.commit("status", "success");
};

function createJobStatisticsDatacollections(statistics) {
  let salaryDatacollection = getSalaryDatacollection(
    statistics.jobApplicationsSalary
  );
  let applicationDatacollection = getApplicationsDatacollection(
    statistics.applicationsPerDay
  );

  return {
    salaryDatacollection: salaryDatacollection,
    applicationDatacollection: applicationDatacollection,
  };
}

function getSalaryDatacollection(jobApplicationsSalary) {
  let salary = jobApplicationsSalary.filter((element) => {
    return element.meanSalary != null;
  });
  salary = salary.slice(-5);
  return createDatacollection(salary, "jobTitle", "meanSalary");
}

function getApplicationsDatacollection(applicationsPerDay) {
  return createDatacollection(
    applicationsPerDay,
    "applicationDate",
    "numberOfApplications"
  );
}

export default {
  loadJobStatistics: loadJobStatistics,
};
