<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="text-left" cols="12">
          <p>Följ kontaktade kandidater, hantera status.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <CandidateInterviews
            :items="bookedInterviewTimes"
            :title="'Bokade intervjuer'"
            :always-display="false"
            :text-when-empty="'Inga intervjuer bokade'"
            @open="openCandidate"
          ></CandidateInterviews>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="mx-auto" max-width="600" tile>
            <v-card-title>
              <h5>Lediga intervjutider</h5>
              <div class="flex-grow-1"></div>
              <v-icon color="primary" @click="$emit('updateView', 'calendar')"
                >edit</v-icon
              >
            </v-card-title>
            <v-list>
              <v-list-item-group color="primary">
                <transition-group name="list" mode="out-in">
                  <v-list-item
                    v-for="(item, i) in choosenInterviewTimes"
                    :key="item.interview_guid"
                    dense
                  >
                    <v-list-item-avatar>
                      <v-avatar size="24" color="accent"></v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.start + "-" + item.end.substring(11, 16)
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <AddToCalendar :items="item.addToCalendar" />
                    </v-list-item-content>
                  </v-list-item>
                </transition-group>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ApplicationsTable
            :items="contactedApplicants"
            :headers="headers"
            :table_mobile_limit="table_mobile_limit"
            :title="tableTitle"
            :current-view="currentView"
            @toggle-star="updateStar"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <CandidateInterviews
            :items="completedInterviewTimes"
            :title="'Genomförda intervjuer'"
            :always-display="false"
            :text-when-empty="'Inga genomförda intervjuer'"
            @open="openCandidate"
          ></CandidateInterviews>
        </v-col>
      </v-row>

      <!--    <v-layout justify-center>
      <v-btn
        dark
        rounded
        @click="chooseCandidates"
        class="confirm-action text-none mt-6"
        color="primary"
      >Gå vidare</v-btn>
    </v-layout>-->
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
import store from "../../store";

import ApplicationsTable from "./ApplicationsTable";
import CandidateInterviews from "./CandidateInterviews";
import snackbarMixin from "../../mixins/snackbar";
import basic from "../../mixins/basic";

import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/sv.js";

export default {
  components: {
    VueCal,
    ApplicationsTable,
    CandidateInterviews,
  },
  mixins: [basic, snackbarMixin],
  data: function () {
    return {
      show: false,
      tableTitle: "Kontaktade",
      showStarTooltip: false,
      showOnlyFavorites: false,
      currentView: "selectCandidates",
      showSnackbar: false,
      snackbartimeout: 3000,
      snackbarbottom: true,
      snackbarmultiline: true,
      snackbarMessage: "",
      snackBarColor: "primary",
      selectableCandidates: true,
      candidates: [],
      invitedCandidates: [],
      interviews: [],
      dialog: false,
      ripple_boolean: false,
      activeApplicant: { title: "[failed to get name]" },

      selected: [],
      status_width: 120,
      search: "",
    };
  },
  created() {},
  computed: {
    contactedApplicants: function () {
      if (this.vx_contactedApplicants.length > 0) {
        return JSON.parse(JSON.stringify(this.vx_contactedApplicants));
      } else {
        return [];
      }
    },
    showStarFilter: function () {
      for (var i = 0; i < this.applicants.length; i++) {
        if (this.applicants[i].starred) {
          return true;
        }
      }
      return false;
    },
    ...mapState("process", {
      job_guid: "job_guid",
      status: "status",
      vx_contactedApplicants: "contactedApplicants",
      headers: "headers",
      table_mobile_limit: "table_mobile_limit",
      job: "job",
      bookedInterviewTimes: "bookedInterviewTimes",
      choosenInterviewTimes: "choosenInterviewTimes",
      completedInterviewTimes: "completedInterviewTimes",
    }),
    filteredApplicants: function () {
      if (this.showOnlyFavorites) {
        return this.applicants.filter(function (item) {
          return item.starred == true;
        });
      } else {
        return this.applicants;
      }
    },
  },
  watch: {
    applicants: {
      deep: true,
      handler() {
        this.$store.commit("process/applicants", this.applicants);
      },
    },
    filteredApplicants: function () {
      if (this.filteredApplicants.length < 1) {
        this.showOnlyFavorites = false;
      }
    },
  },
  methods: {
    ...mapActions("candidateCard", ["setCandidate"]),
    openCandidate: function (application) {
      this.setCandidate(application);
      this.$router.push({
        name: "Candidate",
        params: {
          application_guid: application.application_guid,
          job_guid: this.job_guid,
          currentView: this.currentView,
        },
      });
    },
    ...mapActions("process", ["updateApplicant"]),
    chooseCandidates: function () {
      this.$emit("updateView", {});
    },
    updateStar: async function ({ item, status }) {
      try {
        item.starred = status;
        await this.updateApplicant(item);
        this.applicants = JSON.parse(
          JSON.stringify(this.vx_contactedApplicants)
        );
      } catch (e) {
        console.log(e);
        this._snackbarError("Kunde inte spara ändring");
      }
    },
  },
};
</script>
