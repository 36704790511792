module.exports = {
  job_guid: null,
  job: {},
  jobPostingSettings: null,
  jobPostingSettingsStatus: 'initialize',
  applicants: [],
  contactedApplicants: [],
  status: "initialize",
  contactedApplicantsStatus: [
    "INVITED",
    "BOOKED",
    "COMPLETED",
    "REJECTED",
    "HIRED",
  ],
  applicantValidStatusToChangeTo: {
    APPLIED: ["INVITED", "HIRED", "REJECTED"],
    INVITED: ["HIRED", "REJECTED"],
    BOOKED: ["REJECTED", "HIRED"],
    COMPLETED: ["REJECTED", "HIRED"],
    REJECTED: ["APPLIED", "INVITED", "HIRED"],
    HIRED: ["REJECTED", "APPLIED"],
  },
  choosenApplicants: [],
  choosenInterviewTimes: [],
  bookedInterviewTimes: [],
  completedInterviewTimes: [],
  interviewMinimum: 3,
  table_mobile_limit: 700,
  interviewTimesLeftToChoose: null,
  valid: false,
  validChoosenApplicants: false,
  validChoosenInterviewTimes: false,
  breadcrumbs: [
    { key: "manage", text: "Hantera", type: "manage", disabled: false },
    {
      key: "candidates",
      text: "Kandidater",
      type: "invite",
      disabled: false,
    },
    {
      key: "calendar",
      text: "Intervjutider",
      type: "invite",
      disabled: true,
    },
    { key: "invite", text: "Kontakta", type: "invite", disabled: true },
  ],
  headers: [
    { text: "Favorit", value: "starred", sortable: false, width: 50 },
    { text: "Score", value: "score", width: 105 },
    { text: "Matchning (%)", value: "matchJobPercentage", width: 135 },

    {
      text: "Referens",
      align: "left",
      sortable: true,
      value: "name",
    },
    {
      text: "Kommentar",
      align: "left",
      sortable: true,
      value: "employee_comment",
    },
    { text: "Status", value: "statusText", sortable: true, width: 100 },
  ],
};
