<template>
  <v-snackbar
    v-model="v"
    :color="color"
    :multi-line="mode === 'multi-line'"
    :bottom="y === 'bottom'"
    :left="x === 'left'"
    :right="x === 'right'"
    :top="y === 'top'"
    :timeout="timeout"
    :vertical="mode === 'vertical'"
  >
    {{ text }}
    <template v-slot:action="{}">
      <v-btn v-if="dismissable" dark text @click="updateVisibility(false)"
        >Stäng</v-btn
      >
    </template>
  </v-snackbar>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("snackbar", [
      "visible",
      "text",
      "x",
      "y",
      "color",
      "timeout",
      "dismissable",
      "mode",
    ]),
  },
  watch: {
    visible: function () {
      this.v = this.visible;
    },
    v: function () {
      this.updateVisibility(this.v);
    },
  },
  created: function () {},
  methods: {
    ...mapActions("snackbar", {
      updateVisibility: "visible", // -> this.visible
    }),
  },
  data: () => ({
    v: false,
  }),
};
</script>
