<template>
  <div class="task-container full-width-div">
    <v-row justify="center">
      <v-col class="text-left mb-0 pt-0" cols="12" lg="12">
        <p class="text-left">
          Välj intervjutider i kalendern. Det går även att ange standardtexter
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="6">
            <v-textarea
              v-model="default_meeting_info"
              label="Mötesinformation (address, länkar till digitala möten, etc)"
              placeholder="Standardtext som ska läggas med i mötesinbjudan"
              rows="2"
              hide-details
              outlined
            ></v-textarea>
          </v-col>
          <v-col cols="12" lg="6">
            <v-textarea
              v-model="default_employer_comment"
              label="Kommentar till kandidaten"
              placeholder="Standardtext som ska läggas med i mötesinbjudan"
              rows="2"
              hide-details
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="mx-auto" max-width="600" tile>
          <div class="vue-cal-container">
            <vue-cal
              ref="vuecal"
              class="vuecal--full-height-delete"
              :locale="'sv'"
              :selected-date="today"
              small
              :time-from="8 * 60"
              :time-to="18 * 60"
              :time-cell-height="45"
              :disable-views="['years', 'year', 'month']"
              hide-weekends
              :active-view.sync="activeView"
              :cell-click-hold="false"
              :events="dEvents"
              :click-to-navigate="false"
              :dblclick-to-navigate="false"
              :on-event-click="onEventClick"
              :on-event-create="onEventCreate"
              @cell-click="
                $refs.vuecal.createEvent($event, {
                  title: 'Möte',
                  classes: ['blue-event'],
                })
              "
            ></vue-cal>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="mx-auto" max-width="600" tile>
          <v-card-title>
            <h5>Lediga intervjutider</h5>
            <div class="flex-grow-1"></div>
          </v-card-title>
          <v-list>
            <v-list-item-group color="primary">
              <transition-group name="list" mode="out-in">
                <v-list-item
                  v-for="(item, i) in dEvents"
                  :key="i"
                  dense
                  @click="onListEventClick(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="primary">{{ icon }}</v-icon>
                      {{ item.start + "-" + item.end.substring(11, 16) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      color="primary"
                      @click.stop="removeEvent(item.start)"
                      >close</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </transition-group>
              <v-list-item v-if="interviewTimesLeftToChoose > 0">
                <v-list-item-content>
                  <v-alert dense text type="info" class="ma-0" color="secondary"
                    >{{
                      interviewTimesLeftToChoose -
                      (dEvents.length - events.length)
                    }}
                    intervjutider kvar att välja</v-alert
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="changesMade && contactedApplicants.length > 0">
                <v-list-item-content>
                  <v-row justify="center">
                    <v-col class="flex-grow-0">
                      <v-btn
                        class="confirm-action text-none"
                        dark
                        rounded
                        color="primary"
                        @click="saveEvents()"
                        >Spara ändringar</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row justify-center>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              v-if="validChoosenInterviewTimes && validChoosenApplicants"
              dark
              rounded
              class="confirm-action text-none my-6  "
              color="primary"
              @click="nextStep"
              >Gå vidare</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      width="600"
      class="mx-auto"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title>
          <v-layout>
            <v-flex xs10>
              <h4>
                <v-icon color="black">{{ icon }}</v-icon>
                {{ startDate }}
              </h4>
            </v-flex>
            <v-flex xs2 class="text-right">
              <v-icon color="primary" @click="dialog = false">close</v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row wrap justify="space-between">
            <v-col xs12 sm5>
              <span>Starttid</span>
              <VueCtkDateTimePicker
                v-model="startTime"
                color="#182540"
                label="Välj tid"
                formatted="HH:mm"
                format="HH:mm"
                :only-time="true"
                :no-label="true"
                :inline="true"
                input-size="sm"
                :minute-interval="10"
                :disabled-hours="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
              />
            </v-col>
            <v-col xs12 sm5>
              <span>Sluttid</span>
              <VueCtkDateTimePicker
                v-model="endTime"
                color="#182540"
                label="Välj tid"
                formatted="HH:mm"
                format="HH:mm"
                :only-time="true"
                :no-label="true"
                :inline="true"
                input-size="sm"
                :minute-interval="10"
                :disabled-hours="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                >Mötesinformation (address, länkar till digitala möten, etc)
                *obligatorisk
              </span>
              <v-textarea
                v-model="meeting_info"
                hide-details
                :rows="2"
                outlined
              ></v-textarea>
              <span>Kommentar till kandidaten</span>
              <v-textarea
                v-model="employer_comment"
                hide-details
                :rows="2"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-row
                class="my-0"
                v-if="!(meeting_info != null && meeting_info.length > 0)"
              >
                <v-col cols="12">
                  <v-alert text type="secondary"
                    >Fyll i mötesinformation för att gå vidare</v-alert
                  >
                </v-col>
              </v-row>
              <v-row class="my-0" v-if="!validTime" justify-center>
                <v-col cols="12">
                  <v-alert type="error"
                    >Starttiden för mötet måste vara innan sluttiden</v-alert
                  >
                </v-col>
              </v-row>
              <v-row class="my-0" v-else-if="createActions" justify="center">
                <v-btn
                  v-if="meeting_info != null && meeting_info.length > 0"
                  class="confirm-action text-none"
                  dark
                  rounded
                  color="primary"
                  @click="addEvent()"
                  >Lägg till intervjutid</v-btn
                >
              </v-row>
              <v-row
                class="my-0"
                v-else-if="editActions"
                justify="space-around"
              >
                <v-btn
                  class="confirm-action text-none"
                  dark
                  text
                  rounded
                  color="error"
                  @click="removeEvent(startDateTime)"
                  >Ta bort</v-btn
                >
                <v-btn
                  v-if="meeting_info != null && meeting_info.length > 0"
                  class="confirm-action text-none"
                  dark
                  rounded
                  color="primary"
                  @click="updateEvent(startDateTime)"
                  >Spara</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/sv.js";
import { mapState, mapActions } from "vuex";
import store from "../../store";
import snackbarMixin from "../../mixins/snackbar";
import basic from "../../mixins/basic";

import { addToCal } from "../../utils/addToCalendar.js";

export default {
  components: { VueCal },
  mixins: [basic, snackbarMixin],
  props: {
    candidates: Array,
  },
  data: () => ({
    activeView: "week",
    title: "Intervju",
    icon: "calendar_today",
    startTime: "",
    endTime: "",
    startDate: "",
    endDate: "",
    startDateTime: "",
    content: "",
    default_employer_comment: "",
    employer_comment: "",
    default_meeting_info: "",
    meeting_info: "",
    class: "event",
    addToCal: null,
    event: {},
    dEvents: [],
    selectedEvent: {},
    selectedEventTemp: {},
    editActions: false,
    createActions: false,
    dialog: false,
    createDialog: false,
    editDialog: false,
    moreevents: [
      {
        start: "2018-11-20 14:00",
        end: "2018-11-20 18:00",
        title: "Need to go shopping",
        icon: "shopping_cart", // Custom attribute.
        content: "Click to see my shopping list",
        employee_comment:
          "My shopping list is rather long:<br><ul><li>Avocadoes</li><li>Tomatoes</li><li>Potatoes</li><li>Mangoes</li></ul>", // Custom attribute.
        class: "leisure",
      },
      {
        start: "2018-11-22 10:00",
        end: "2018-11-22 15:00",
        title: "Golf with John",
        icon: "golf_course", // Custom attribute.
        content: "Do I need to tell how many holes?",
        employee_comment: "Okay.<br>It will be a 18 hole golf course.", // Custom attribute.
        class: "sport",
      },
    ],
  }),
  watch: {
    events: function () {
      //this.dEvents = JSON.parse(JSON.stringify(this.events));
    },
    dEvents: {
      deep: true,
      handler() {
        if (
          this.contactedApplicants == null ||
          this.contactedApplicants.length < 1
        ) {
          this.$store.dispatch(
            "process/choosenInterviewTimes",
            JSON.parse(JSON.stringify(this.dEvents))
          );
        }
      },
    },
  },
  mounted: function () {
    this.dEvents = JSON.parse(JSON.stringify(this.events));
  },
  computed: {
    changesMade: function () {
      return !(JSON.stringify(this.events) == JSON.stringify(this.dEvents));
    },
    ...mapState("process", {
      job: "job",
      events: "choosenInterviewTimes",
      interviewTimesLeftToChoose: "interviewTimesLeftToChoose",
      validChoosenInterviewTimes: "validChoosenInterviewTimes",
      validChoosenApplicants: "validChoosenApplicants",
      contactedApplicants: "contactedApplicants",
    }),
    validTime: function () {
      if (
        this.startTime.substring(0, 2) > this.endTime.substring(0, 2) ||
        (this.startTime.substring(0, 2) == this.endTime.substring(0, 2) &&
          this.startTime.substring(3, 5) >= this.endTime.substring(3, 5))
      ) {
        return false;
      } else {
        return true;
      }
    },
    today: function () {
      return this.getFullDate(new Date());
    },
  },
  methods: {
    ...mapActions("process", ["updateInterviewTimes"]),
    nextStep() {
      this.$emit("updateView", null);
    },
    updateView(view) {
      this.$emit("updateView", view);
    },
    async saveEvents() {
      try {
        await this.updateInterviewTimes(this.dEvents);
        this.$store.dispatch(
          "process/choosenInterviewTimes",
          JSON.parse(JSON.stringify(this.dEvents))
        );
      } catch (e) {
        this._snackbarError("Kunde inte spara");
      }
      //this.$emit("updateEvents", { events: this.dEvents });
    },
    closeSchedule() {
      this.$emit("closeSchedule", {
        showSchedule: false,
      });
    },
    roundToHour(date) {
      var p = 60 * 60 * 1000; // milliseconds in an hour
      return new Date(Math.floor(date.getTime() / p) * p);
    },
    getFullDate(date) {
      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      return [
        date.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd,
      ].join("-");
    },
    onEventClick(event, e) {
      this.dialog = true;
      this.createActions = false;
      this.editActions = true;
      this.employer_comment = "";
      this.employer_comment = event.employer_comment;
      this.meeting_info = "";
      this.meeting_info = event.meeting_info;

      var self = this;
      setTimeout(function () {
        self.startDate = event.start.substring(0, 10);
        self.endDate = event.end.substring(0, 10);
        self.startTime = event.start.substring(11, 16);
        self.endTime = event.end.substring(11, 16);
        self.startDateTime = self.startDate + " " + self.startTime;
      }, 400);
      // Prevent navigating to narrower view (default vue-cal behavior).
      //e.stopPropagation();
    },
    onListEventClick(event, e) {
      this.dialog = true;
      this.createActions = false;
      this.editActions = true;
      this.employer_comment = "";
      this.employer_comment = event.employer_comment;
      this.meeting_info = "";
      this.meeting_info = event.meeting_info;

      var self = this;
      setTimeout(function () {
        self.startDate = event.start.substring(0, 10);
        self.endDate = event.end.substring(0, 10);
        self.startTime = event.start.substring(11, 16);
        self.endTime = event.end.substring(11, 16);
        self.startDateTime = self.startDate + " " + self.startTime;
      }, 400);
    },
    removeEvent(startDate) {
      for (var i = 0; i < this.dEvents.length; i++) {
        if (this.dEvents[i].start == startDate) {
          this.dEvents.splice(i, 1);
          break;
        }
      }
      this.dialog = false;
    },
    updateEvent(startDate) {
      //this.event = {};
      //this.event.start = this.startDate + " " + this.startTime;
      //this.event.end = this.startDate + " " + this.endTime;
      //this.event.title = this.title;
      //this.event.content = this.content;
      //this.event.class = this.class;
      //this.event.employer_comment = this.employer_comment;
      //this.event.meeting_info = this.meeting_info;
      this.removeEvent(startDate);
      this.addEvent();
      this.dEvents.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      });
    },
    addEvent() {
      this.dialog = false;
      this.createActions = true;
      this.editActions = false;
      this.event = {};
      this.event.start = this.startDate + " " + this.startTime;
      this.event.end = this.startDate + " " + this.endTime;
      //this.event.title = this.title;
      this.event.content = this.content;
      this.event.class = this.class;
      this.event.employer_comment = this.employer_comment;
      this.event.meeting_info = this.meeting_info;
      this.event.addToCalendar = addToCal({
        start: this.event.start,
        end: this.event.end,
        title: this.job.title,
      });
      this.dEvents.push(this.event);
      this.dEvents.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      });
    },
    onEventCreate(event, e) {
      if (new Date(event.start) - new Date() < 10000) {
        this._snackbarInfo("Välj en tid i framtiden");
        return;
      }
      this.dialog = true;
      this.createActions = true;
      this.editActions = false;
      this.employer_comment = this.default_employer_comment;
      this.meeting_info = this.default_meeting_info;
      var self = this;
      setTimeout(function () {
        self.startDate = self.getFullDate(event.start);
        self.startTime =
          event.start.getHours() < 10
            ? "0" + event.start.getHours() + ":00"
            : event.start.getHours() + ":00";
        self.endTime =
          event.start.getHours() + 1 < 10
            ? "0" + (event.start.getHours() + 1) + ":00"
            : event.start.getHours() + 1 + ":00";
        self.startDateTime = self.startDate + " " + self.startTime;
      }, 400);

      // Prevent navigating to narrower view (default vue-cal behavior).
      //e.stopPropagation();
    },
  },
};
</script>
