<template>
  <div>
    <div v-for="item in items" :key="item.title">
      <prominent-list-item
        :imgUrl="item.imgUrl"
        :title="item[titleKey]"
        :subtitle="item[subtitleKey]"
      >
        <template v-slot:action>
          <v-switch
            color="open"
            v-model="item.published"
            inset
          ></v-switch>
        </template>
        <v-slot></v-slot
      ></prominent-list-item>
      <v-divider></v-divider>
    </div>
  </div>
</template>
<script>
import ProminentListItem from "../../atom/listitem/ProminentListItem";

export default {
  components: { ProminentListItem },
  data() {
    return {};
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    titleKey: {
      type: String,
      default: "title",
    },
    subtitleKey: {
      type: String,
      default: "subtitle",
    },
  },
};
</script>