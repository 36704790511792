<template>
  <div class="task-container full-width-div">
    <v-row justify="center">
      <v-col class="text-left ma-0 pt-0" cols="12" lg="12">
        <!--   <p>
          Tryck på
          <v-btn class="text-none" rounded dark color="primary">
            <v-icon large>add</v-icon>
          </v-btn>&nbsp;för att välja kandidater att kalla på intervju
        </p>-->
        <p class="mb-0">Granska och välj kandidater.</p>
        <!--
        <p>
          Om man tidigare valt några favoriter visas endast dessa, för att visa alla kandidater, klicka på
          <v-icon color="primary">star</v-icon>
        </p>-->
      </v-col>
      <v-container>
        <v-row v-if="$vuetify.breakpoint.xs" dense>
          <v-col cols="6" sm="6">
            <v-card class="mx-auto px-0 overflow-y-auto" max-width="600" tile>
              <v-card-title class="px-2 py-2">
                <h5>Matchningar ({{ topCandidates.length }})</h5>
                <div class="flex-grow-1"></div>
                <div v-if="showStarFilter">
                  <v-tooltip
                    v-if="showOnlyFavorites"
                    v-model="showStarTooltip"
                    color="black"
                    top
                    nudge-bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="primary"
                        v-on="on"
                        @click="
                          showStarTooltip = false;
                          showOnlyFavorites = !showOnlyFavorites;
                        "
                        >star</v-icon
                      >
                    </template>
                    <span>Visar endast favoriter</span>
                  </v-tooltip>

                  <v-tooltip
                    v-else
                    v-model="showStarTooltip"
                    color="black"
                    top
                    nudge-bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="primary"
                        v-on="on"
                        @click="
                          showStarTooltip = true;
                          showOnlyFavorites = !showOnlyFavorites;
                        "
                        >star_outline</v-icon
                      >
                    </template>
                    <span>
                      Visar alla kandidater
                      <br />Klicka för att endast visa favoriter
                    </span>
                  </v-tooltip>
                </div>
              </v-card-title>
              <v-list min-height="200" max-height="300">
                <!-- class="overflow-scroll" -->
                <!-- <v-subheader>Score</v-subheader> -->
                <v-list-item-group color="primary">
                  <transition-group name="list">
                    <v-list-item
                      v-for="(item, i) in topCandidates"
                      :key="i"
                      class="px-1"
                      dense
                      @click="addCandidate(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon v-if="item.starred" small color="primary"
                            >star</v-icon
                          >
                          <v-icon v-else small color="grey"
                            >star_outline</v-icon
                          >
                          <v-btn
                            x-small
                            depressed
                            :width="20"
                            :min-width="20"
                            class="mx-0 my-0 px-0 py-0 button-not-clickable"
                            :color="item.matchColor"
                            >{{ item.score }}</v-btn
                          >
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <!--   <v-list-item-icon>
                  <v-icon large color="primary">info</v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon large color="primary">add_circle</v-icon>
                      </v-list-item-icon>-->
                    </v-list-item>
                  </transition-group>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6">
            <v-card class="mx-auto px-0 overflow-y-auto" max-width="600" tile>
              <v-card-title class="px-2 py-2">
                <h5>Valda</h5>
              </v-card-title>
              <v-list min-height="200">
                <!-- <v-subheader>Score</v-subheader> -->
                <v-list-item-group color="primary">
                  <transition-group name="list">
                    <v-list-item
                      v-for="(item, i) in choosenCandidates"
                      :key="i"
                      class="px-1"
                      dense
                      @click="removeCandidate(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon v-if="item.starred" small color="primary"
                            >star</v-icon
                          >
                          <v-icon v-else small color="grey"
                            >star_outline</v-icon
                          >
                          <v-btn
                            x-small
                            depressed
                            :width="20"
                            :min-width="20"
                            class="mx-0 my-0 px-0 py-0 button-not-clickable"
                            :color="item.matchColor"
                            >{{ item.score }}</v-btn
                          >
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <!-- <v-list-item-icon>
                    <v-icon color="primary">close</v-icon>
                      </v-list-item-icon>-->
                      <!--   <v-list-item-icon>
                  <v-icon large color="primary">info</v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon large color="primary">add_circle</v-icon>
                      </v-list-item-icon>-->
                    </v-list-item>
                  </transition-group>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" sm="6">
            <v-card class="mx-auto overflow-y-auto" max-width="600" tile>
              <v-card-title>
                <h5>Matchningar ({{ topCandidates.length }})</h5>
                <div class="flex-grow-1"></div>
                <div v-if="showStarFilter">
                  <v-btn v-if="showOnlyFavorites" icon>
                    <v-tooltip
                      v-model="showStarTooltip"
                      color="black"
                      top
                      nudge-bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          large
                          color="primary"
                          v-on="on"
                          @click="
                            showStarTooltip = false;
                            showOnlyFavorites = !showOnlyFavorites;
                          "
                          >star</v-icon
                        >
                      </template>
                      <span>Visar endast favoriter</span>
                    </v-tooltip>
                  </v-btn>

                  <v-btn v-else icon>
                    <v-tooltip
                      v-model="showStarTooltip"
                      color="black"
                      top
                      nudge-bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          large
                          color="primary"
                          v-on="on"
                          @click="
                            showStarTooltip = true;
                            showOnlyFavorites = !showOnlyFavorites;
                          "
                          >star_outline</v-icon
                        >
                      </template>
                      <span>
                        Visar alla kandidater
                        <br />Klicka för att endast visa favoriter
                      </span>
                    </v-tooltip>
                  </v-btn>
                </div>
              </v-card-title>
              <v-list min-height="300" max-height="300">
                <!-- class="overflow-scroll" -->
                <!-- <v-subheader>Score</v-subheader> -->
                <v-list-item-group color="primary">
                  <transition-group name="list">
                    <v-list-item
                      v-for="(item, i) in topCandidates"
                      :key="i"
                      dense
                      @click="addCandidate(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon v-if="item.starred" color="primary"
                            >star</v-icon
                          >
                          <v-icon v-else color="grey">star_outline</v-icon>
                          <v-btn
                            small
                            depressed
                            class="mx-2 button-not-clickable"
                            :color="item.matchColor"
                            >{{ item.score }}</v-btn
                          >
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon color="primary">add</v-icon>
                      </v-list-item-icon>
                      <!--   <v-list-item-icon>
                  <v-icon large color="primary">info</v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon large color="primary">add_circle</v-icon>
                      </v-list-item-icon>-->
                    </v-list-item>
                  </transition-group>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="mx-auto overflow-y-auto" max-width="600" tile>
              <v-card-title>
                <h5>Kandidater att kalla på intervju</h5>
              </v-card-title>
              <v-list min-height="300" max-height="300">
                <!-- <v-subheader>Score</v-subheader> -->
                <v-list-item-group color="primary">
                  <transition-group name="list">
                    <v-list-item
                      v-for="(item, i) in choosenCandidates"
                      :key="i"
                      dense
                      @click="removeCandidate(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon v-if="item.starred" color="primary"
                            >star</v-icon
                          >
                          <v-icon v-else color="grey">star_outline</v-icon>
                          <v-btn
                            small
                            depressed
                            class="mx-2 button-not-clickable"
                            :color="item.matchColor"
                            >{{ item.score }}</v-btn
                          >
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon color="primary">close</v-icon>
                      </v-list-item-icon>
                      <!--   <v-list-item-icon>
                  <v-icon large color="primary">info</v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon large color="primary">add_circle</v-icon>
                      </v-list-item-icon>-->
                    </v-list-item>
                  </transition-group>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row justify-center>
      <v-col class="d-flex justify-center">
        <v-btn
          v-if="validChoosenApplicants"
          dark
          rounded
          class="confirm-action text-none my-6"
          color="primary"
          @click="nextStep"
          >Gå vidare</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import store from "../../store";

export default {
  components: {},
  props: {
    jobProp: Object,
    events: Array,
    preChooseApplicationGuid: String,
  },
  data: function () {
    return {
      applicants: [],
      showStarTooltip: false,
      showOnlyFavorites: false,
      currentView: "selectCandidates",
      showSnackbar: false,
      snackbartimeout: 3000,
      snackbarbottom: true,
      snackbarmultiline: true,
      snackbarMessage: "",
      snackBarColor: "primary",
      candidates: [],
      invitedCandidates: [],
      interviews: [],
      dialog: false,
      ripple_boolean: false,
      activeApplicant: { title: "[failed to get name]" },
      job: this.jobProp,
      table_mobile_limit: 700,
      selected: [],
      status_width: 120,
      search: "",
    };
  },
  created() {
    this.applicants = JSON.parse(JSON.stringify(this.vx_applicants));
    if (this.preChooseApplicationGuid != null) {
      this.addCandidate({ guid: this.preChooseApplicationGuid });
    }
    if (this.topCandidates.length < 1) {
      this.showOnlyFavorites = false;
    }
    //this.job.applicants = JSON.parse(JSON.stringify(this.jobProp.applicants));
    /* for (var i = 0; i < this.job.applicants.length; i++) {
      this.job.applicants[i].choosen = false;
    } */
    /* if (this.$vuetify.breakpoint.smAndDown) {
      this.status_width = 100;
    } else {
      this.status_width = 200;
    } */
  },
  computed: {
    ...mapState("process", {
      vx_applicants: "applicants",
      headers: "headers",
      validChoosenApplicants: "validChoosenApplicants",
      contactedApplicantsStatus: "contactedApplicantsStatus",
    }),
    showStarFilter: function () {
      var index = this.topCandidates.findIndex((elem) => {
        return elem.starred == true;
      });
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    },
    topCandidates: function () {
      return this.applicants.filter((item) => {
        if (this.contactedApplicantsStatus.includes(item.status)) {
          return false;
        }
        if (item.choosen) {
          return false;
        } else if (this.showOnlyFavorites) {
          if (item.starred) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

      /*    var self = this;
        if (this.applicants.length > 0) {

        return this.applicants.filter(function(item) {

          if (self.showOnlyFavorites) {
            return item.choosen == false && item.starred == true;
          } else {
            return item.choosen == false;
          }
        });
      } else {
        return [];
      } */
    },
    choosenCandidates: function () {
      if (this.applicants.length > 0) {
        return this.applicants.filter(function (item) {
          return item.choosen == true;
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    showStarFilter: function () {
      if (!this.showStarFilter) {
        this.showOnlyFavorites = false;
      }
    },
    topCandidates: function () {
      if (this.topCandidates.length < 1) {
        this.showOnlyFavorites = false;
      }
    },
    applicants: {
      deep: true,
      handler() {
        this.$store.dispatch("process/applicants", this.applicants);
      },
    },
  },
  methods: {
    nextStep: function () {
      this.$emit("updateView", null);
    },
    removeApplicant(item, list) {
      //console.log(item);
      //console.log("Splicing soon");
      for (var i = 0; i < list.length; i++) {
        //console.log(list.guid);
        //console.log(item.guid);
        if (list[i].guid == item.guid) {
          //console.log("Splicing");
          //list.splice(i, 1);
          list[i].choosen = true;
          break;
        }
      }
    },
    updateView: function (view) {
      this.$emit("updateView", view);
    },
    updateCandidates: function (data) {
      this.$emit("updateCandidates", { candidates: this.applicants });
    },
    addCandidate: function (data) {
      var tempApp = this.applicants;
      for (var i = 0; i < tempApp.length; i++) {
        if (tempApp[i].guid == data.guid) {
          tempApp[i].choosen = true;
        }
      }
      this.applicants = [];
      this.applicants = tempApp;
    },
    removeCandidate: function (data) {
      //console.log("Removing");
      var tempApp = this.applicants;
      for (var i = 0; i < tempApp.length; i++) {
        //console.log(this.job.applicants[i]);
        if (tempApp[i].guid == data.guid) {
          tempApp[i].choosen = false;
        }
      }
      this.applicants = [];
      this.applicants = tempApp;
    },
    callInterview: function (data) {
      for (var i = 0; i < this.applicants.length; i++) {
        if (this.applicants[i].guid == data) {
          this.activeApplicant = this.applicants[i];
          this.dialog = true;
        }
      }
    },
    clicked: function (data) {
      //console.log("clicked" + data);
    },
  },
};
</script>
<style>
.bottom-button-group {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  /* background-color: #182540; */
  background-color: #038c8c;
  z-index: 20;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
