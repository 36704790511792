const groupedQuestions = (state, groupedQuestions) => {
  state.groupedQuestions = groupedQuestions;
};

const status = (state, status) => {
  state.status = status;
};

const breadcrumbs = (state, breadcrumbs) => {
  state.breadcrumbs = breadcrumbs;
};

export default {
  groupedQuestions,
  status,
  breadcrumbs,
};
