<template>
  <div>
    <delete-job-dialog
      v-model="showDeleteJobDialog"
      :job="selectedJob"
    ></delete-job-dialog>
    <v-row v-if="this.$vuetify.breakpoint.smAndDown">
      <v-col>
        <v-card :max-width="maxWidth">
          <v-toolbar flat>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-col v-if="jobs == null || jobs.length == 0">
            <span> Du har inga utkast. </span></v-col
          >
          <v-list v-else two-line>
            <v-list-item-group>
              <template v-for="(item, index) in jobs">
                {{ item.applicantss }}
                <v-list-item :key="item.job_guid" @click="editJob(item)">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="item.cityText"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-text="item.department"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon @click.stop="openDeleteJobDialog(item)"
                      >delete</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <Card v-else :title="title">
      <template v-slot:cardOptions>
        <v-text-field
          :value="search"
          append-icon="search"
          label="Sök"
          single-line
          hide-details
          @input="searchUpdate($event)"
        ></v-text-field>
        <!-- 
      <v-menu :close-on-content-click="false" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Filter</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="filter.active"
                  color="primary"
                  hide-details
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>{{
                text.showOnlyActiveJobsText
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu> -->
      </template>
      <v-col><span v-if="jobs == null || jobs.length == 0"> </span></v-col>
      <v-data-table
        class="clickable-rows-table"
        :headers="headers"
        :items="jobs"
        :search="search_table"
        :sort-by="['created_date']"
        :sort-desc="true"
        :sort="true"
        @click:row="editJob"
      >
        <template v-slot:item.statusText="{ item }">
          <v-tooltip v-model="item.tooltip" color="primary" left nudge-bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-avatar :color="item.statusColor" size="24"></v-avatar>
              </div>
            </template>
            <span>{{ item.statusText }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action="{ item }">
          <slot name="action">
            <v-icon @click.stop="openDeleteJobDialog(item)">delete</v-icon>
          </slot>
        </template>
        <!--   <template v-slot:item.statusText="{ item }">
            <v-btn
              depressed
              block
              class="button-not-clickable"
              :color="item.statusColor"
            >{{ item.statusText }}</v-btn>
          </template>-->
      </v-data-table>
    </Card>
  </div>
</template>


<script>
import DeleteJobDialog from "./DeleteJobDialog.vue";

import store from "../../store";
import { mapState, mapActions } from "vuex";
import filter from "../../utils/filter";
import Card from "../basic/Card";
export default {
  components: {
    Card,
    DeleteJobDialog,
  },
  data: function () {
    return {
      selectedJob: null,
      showDeleteJobDialog: false,
      maxWidth: 800,
      text: {
        key: "jobs",
        data: "jobs",
        get: "jobs",
        title: "Följ processer",
        cardTitle: "Processer",
        continueMessage: "Svar ändrade",
        continueBtnMessage: "Spara",
        missingAnswersMessage: "Svara på alla frågor för att gå vidare",
        missingAnswersBtnMessage: "Visa",
        showOnlyActiveJobsText: "Visa endast aktiva processer",
      },
      search: "",
      search_table: "",
      filter: {
        active: true,
      },
    };
  },
  computed: {
    ...mapState("applications", ["latestApplications"]),
  },
  props: {
    jobs: Array,
    title: String,
    headers: Array,
  },
  methods: {
    openDeleteJobDialog(job) {
      this.selectedJob = job;
      this.showDeleteJobDialog = true;
    },
    editJob(job) {
      this.$router.push({
        name: "EditJob",
        params: {
          job_guid: job.job_guid,
          jobStatus: job.status,
        },
        query: { status: job.status },
      });
    },
    rowClicked(item) {
      this.$emit("row", item);
    },
    openProcess(job) {
      this.$router.push({ name: "Job", params: { id: job.job_guid } });
    },
    searchUpdate(val) {
      var self = this;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(function () {
        self.search_table = val;
      }, this.debounceDelay);
    },
  },
};
</script>