<template>
  <v-row>
    <v-col><Button>Standard button</Button></v-col>
    <v-col><Button type="continue">Continue button</Button></v-col>
    <v-col><Button type="error">Error button</Button></v-col>
    <v-col><Button type="goto">Goto button</Button></v-col>
    <v-col><Button type="confirm">Confirm button</Button></v-col>
    <v-col><Button :text="true">Text button</Button></v-col>
    <v-col><Button :disabled="true">Disabled button</Button></v-col>
  </v-row>
</template>

<script>
import Button from "../../../components/basic/Button.vue";
export default {
  components: { Button },
};
</script>
