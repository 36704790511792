<template>
  <v-text-field v-model.number="dAnswer" type="number"></v-text-field>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
  },
  data() {
    return {
      dAnswer: this.answer ? this.answer : null,
      dValidAnswer: false,
    };
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.emitAnswers();
      },
      deep: true,
    },
  },
  mounted: function () {
    //console.log("Answer is: " + this.answer)
    //this.dAnswer = JSON.parse(this.answer);
    this.emitAnswers();
  },
  methods: {
    emitAnswers: function() {
this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer,
        });
    }
  },
};
</script>
