module.exports = {
  questionsKey: "jobQuestions",
  status: "initialize",
  groupedQuestions: null,
  tabSortOrder: [
    { tab: "create", sortKey: 1 },
    { tab: "profile", sortKey: 2 },
    { tab: "questions", sortKey: 3 },
  ],
  breadcrumbs: [
    {
      key: "basic",
      tab: "create",
      text: "Tjänstebeskrivning",
      type: "create",
      valid: false,
      disabled: false,
      showWhenAllQuestionsValid: true,
      disabledInDraft: false
    },
    {
      key: "req",
      tab: "profile",
      text: "Matchningsfrågor",
      type: "profile",
      valid: false,
      disabled: true,
      showWhenAllQuestionsValid: true,
      disabledInDraft: false
    },
    {
      key: "motivation",
      tab: "questions",
      text: "Motivation och värderingar",
      type: "questions",
      valid: false,
      disabled: true,
      showWhenAllQuestionsValid: true,
      disabledInDraft: false
    },
    {
      key: "overview",
      tab: "overview",
      text: "Översikt",
      type: "overview",
      valid: false,
      disabled: false,
      showWhenAllQuestionsValid: true,
      disabledInDraft: false
    },
    {
      key: "manageJobPostings",
      tab: "manageJobPostings",
      text: "Dela och Publicera",
      type: "manageJobPostings",
      valid: true,
      disabled: false,
      showWhenAllQuestionsValid: false,
      disabledInDraft: true
    },
  ],
};
