<template>
  <v-btn
    :class="{ 'font-weight-regular': !active, 'font-weight-bold': active }"
    class="text-none my-1"
    elevation="0"
    small
    :outlined="outlined && !active"
    @click="$emit('clicked', null)"
    :color="active ? 'grey lighten-2' : ''"
    :text="!active"
    >{{ name }}</v-btn
  >
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
};
</script>