<template>
  <div class="task-container full-width-div">
    <div>
      <v-card class="mx-auto" max-width="2000" tile>
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <v-card-title>
              <h5>Kandidater att kalla på intervju</h5>
            </v-card-title>
            <v-list>
              <!-- <v-subheader>Score</v-subheader> -->
              <v-list-item-group color="primary">
                <transition-group name="list">
                  <v-list-item
                    v-for="(item, i) in choosenApplicants"
                    :key="i"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon v-if="item.starred" color="primary"
                          >star</v-icon
                        >
                        <v-icon v-else color="grey">star_outline</v-icon>
                        <v-btn
                          small
                          depressed
                          class="mx-2 button-not-clickable"
                          :color="item.matchColor"
                          >{{ item.score }}</v-btn
                        >
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </transition-group>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-title>
              <h5>Valda intervjutider</h5>
              <div class="flex-grow-1"></div>
            </v-card-title>
            <v-list>
              <v-list-item-group color="primary">
                <transition-group name="list" mode="out-in">
                  <v-list-item
                    v-for="(item, i) in choosenInterviewTimes"
                    :key="i"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="primary">calendar_today</v-icon>
                        {{ item.start + "-" + item.end.substring(11, 16) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </transition-group>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
      <v-row
        ><v-col>
          <div class="d-flex justify-center" justify-center>
            <v-btn
              dark
              rounded
              class="confirm-action text-none my-6"
              color="primary"
              @click="inviteDialog = true"
              >Bjud in kandidater</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="inviteDialog" max-width="400">
        <v-card class="mx-auto" tile>
          <v-card-title>
            <h5>Kalla på intervju</h5>
            <div class="flex-grow-1"></div>
          </v-card-title>
          <v-card-text>
            <p>Vill du kalla dessa kandidater på intervju?</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-none" rounded text @click="inviteDialog = false"
              >Nej</v-btn
            >
            <v-btn
              class="text-none"
              rounded
              color="primary"
              @click="inviteCandidates"
              >Ja</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "../../store";
import Spinner from "../misc/Spinner";

export default {
  components: {
    Spinner,
  },
  data: function () {
    return {
      inviteDialog: false,
    };
  },
  computed: {
    ...mapState("process", ["choosenApplicants", "choosenInterviewTimes"]),
  },
  methods: {
    inviteCandidates: function () {
      this.inviteDialog = false;
      this.$emit("sendInvitations", null);
    },
  },
};
</script>
