
<template>
    <basic-info-sheet :color=color>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                    class="d-flex justify-start justify-md-center"
                  >
                  <v-img v-if="imgUrl != null" contain height="152" :src="imgUrl"></v-img>
                  <v-icon v-else :color="iconColor" x-large>{{icon}}</v-icon>
                  </v-col>
                  <v-col class="my-2" cols="12" md="7">
                    <slot>
                    <h3 class="mt-0" :class="{'white--text' : color != 'white'}">{{title}}</h3>
                    <span :class="{'white--text' : color != 'white'}"
                      >{{text}}
                    </span>
                    </slot>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="d-flex justify-start justify-md-end align-end"
                  >
                    <a
                      :href="url"
                      target="_blank"
                    >
                      <BigButton
                        :text-color="'white'"
                        :color="'secondary'"
                        :text="false"
                        >Läs mer</BigButton
                      ></a
                    >
                  </v-col>
                </v-row>
    </basic-info-sheet>
</template>
<script>
import BasicInfoSheet from '../atom/sheet/BasicInfoSheet.vue'
import BigButton from '../basic/BigButton'
import InfoSheet from '../basic/InfoSheet'
export default {
  components: {
    BigButton,
    InfoSheet,
    BasicInfoSheet
    },
    props: {
      text: String,
      title: String,
      color: String,
      icon: String,
      iconColor: String,
      url: String,
      imgUrl: String
    }
}
</script>