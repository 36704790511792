const jobById = (state) => (job_guid) => {
  return state.jobs.find((elem) => elem.job_guid === job_guid);
};

const getFromAllJobById = (state) => (job_guid) => {
  let job = state.allJobs.find((elem) => elem.job_guid === job_guid);
};

export default {
  jobById: jobById,
  getFromAllJobById: getFromAllJobById
};
