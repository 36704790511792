<template>
  <div>
    <v-text-field
      v-if="!disabled"
      placeholder="Sök jobb"
      clearable
      clear-icon="close"
      @input="searchUpdate($event)"
      @mouseup="jobFocus"
    ></v-text-field>
    <v-expansion-panels v-if="dAnswer.length > 0" v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header
          >Valda jobb ({{ dAnswer.length }})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody class="simple-table-full-width removeable-rows">
                <tr
                  v-for="job in dAnswer"
                  :key="job.id"
                  @click="
                    if (!disabled) {
                      remove(dAnswer, treeAnswer, job);
                    }
                  "
                >
                  <td>{{ job.name }}</td>
                  <td>
                    <v-icon small>close</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      hide-overlay
      :transition="dialogTransition"
      max-width="600px"
    >
      <v-card
        v-if="this.$vuetify.breakpoint.mdAndUp"
        class="autocomplete popup"
      >
        <v-card-title>
          <v-layout>
            <v-flex xs12>
              <h2>Jobb</h2>
            </v-flex>
            <v-flex xs2 class="text-right">
              <v-icon large color="black" @click="dialog = false">close</v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-flex xs12 md12>
            <v-text-field
              ref="jobDialogInput"
              v-model="search"
              placeholder="Sök..."
              clearable
              clear-icon="close"
              @input="searchUpdate($event)"
              @mouseup="jobFocus"
            ></v-text-field>
          </v-flex>
          <v-flex
            v-if="
              (search != null && search.length > 2) ||
              showAllJobs == true ||
              dAnswer.length > 0
            "
            xs12
          >
            <v-treeview
              v-model="treeAnswer"
              selectable
              selected-color="primary"
              :items="jobsTree"
              :search="search_tree"
              :filter="filter"
              :open.sync="open"
              @update:open="toggleExpand"
              @update:active="toggleExpand"
            ></v-treeview>
          </v-flex>
          <v-btn
            v-else
            class="text-none"
            rounded
            color="primary"
            @click="showAllJobs = true"
            >Visa alla</v-btn
          >
        </v-card-text>
      </v-card>
      <v-card v-else class="autocomplete popup">
        <v-col>
          <v-text-field
            ref="jobDialogInput"
            v-model="search"
            append-icon="search"
            placeholder="Sök..."
            clearable
            clear-icon="close"
            prepend-inner-icon="keyboard_backspace"
            @input="searchUpdate($event)"
            @mouseup="jobFocus"
            @click:prepend-inner="dialog = false"
          ></v-text-field>
          <v-treeview
            v-if="
              search.length > 2 || showAllJobs == true || dAnswer.length > 0
            "
            v-model="treeAnswer"
            selectable
            selected-color="primary"
            :items="jobsTree"
            :search="search_tree"
            :filter="filter"
            :open.sync="open"
            @update:open="toggleExpand"
            @update:active="toggleExpand"
          ></v-treeview>
          <v-btn
            v-else
            class="text-none"
            rounded
            color="primary"
            @click="showAllJobs = true"
            >Visa alla</v-btn
          >
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    answer: Array,
    id: Number,
    tab: String,
    title: String,
    jobsTree: Array,
    jobsFlat: Array,
    answerAlternatives: Array,
    groupAnswers: Boolean,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    introText: String,
    instructionText: String,
    atbotOKText: {
      type: String,
      default: "OK",
    },
  },
  data() {
    return {
      showAllJobs: false,
      panel: null,
      treeAnswer: [],
      dAnswer: [],
      valueAnswer: [],
      dValidAnswer: false,
      timeoutID: false,
      debounceDelay: 320,
      jobs: [],
      search: "",
      search_tree: null,
      caseSensitive: false,
      open: [],
      dialog: false,
      limit: true,
      initialLoad: true,
    };
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    selected_jobs() {
      //console.log("looping");
      var temp_jobs = [];
      this.iterObj(this.jobsTree);
      //console.log(testLoop);
      for (var i = 0; i < this.jobs.length; i++) {
        temp_jobs.push(this.jobs[i]);
      }

      return temp_jobs;
    },
    dialogTransition: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "dialog-bottom-transition";
      } else {
        return "scale-transition";
      }
    },
  },
  watch: {
    treeAnswer: {
      handler: function () {
        //console.log(this.treeAnswer);
        var tempAnswer = [];
        this.dAnswer = [];
        for (var i = 0; i < this.treeAnswer.length; i++) {
          for (var k = 0; k < this.jobsFlat.length; k++) {
            //console.log(this.treeAnswer[i])
            //console.log(this.jobsFlat[k].id)
            if (this.treeAnswer[i] == this.jobsFlat[k].id) {
              tempAnswer.push(this.jobsFlat[k]);
              break;
            }
          }
        }
        this.dAnswer = JSON.parse(JSON.stringify(tempAnswer));
      },
    },
    dAnswer: {
      handler: function () {
        //console.log("this.initialLoad");
        //console.log(this.initialLoad);
        //console.log("found change" + this.dAnswer.length);
        if (!this.initialLoad) {
          this.initialLoad = true;
          return;
        }
        if (this.dAnswer.length > 0) {
          this.dValidAnswer = true;
        } else {
          this.dValidAnswer = false;
        }
        //console.log(this.dValidAnswer);
        this.$emit("changeAnswer", {
          id: this.id,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer,
        });
      },
      deep: true,
    },
    search_tree: function () {
      //console.log(JSON.stringify(this.filter()));
      this.open = [];
      if (this.search_tree == null) {
        return;
      } else if (this.search_tree.length > 2) {
        for (var i = 0; i < this.jobsFlat.length; i++) {
          //console.log(this.jobsFlat[i].brite_title.toLowerCase());
          if (
            this.jobsFlat[i].name
              .toLowerCase()
              .includes(this.search_tree.toLowerCase())
          ) {
            this.open.push(
              parseInt(String(this.jobsFlat[i].id).substring(0, 2))
            );
            this.open.push(
              parseInt(String(this.jobsFlat[i].id).substring(0, 3))
            );
            this.open.push(
              parseInt(String(this.jobsFlat[i].id).substring(0, 4))
            );
            this.open.push(this.jobsFlat[i].id);
          }
        }
        //console.log("open is");
        //console.log(this.open);
        //this.open = this.allOpen;
        //console.log("asdasd");
      }
    },
  },
  mounted: function () {
    //console.log("this.answer");
    //console.log(JSON.stringify(this.answer));
    if (this.answer != null) {
      var a = this.answer;
      var tempAnswer = [];
      for (var i = 0; i < a.length; i++) {
        this.treeAnswer.push(parseInt(a[i].id));
        for (var k = 0; k < this.jobsFlat.length; k++) {
          //console.log(this.treeAnswer[i])
          //console.log(this.jobsFlat[k].id)
          if (a[i].id == this.jobsFlat[k].id) {
            tempAnswer.push(this.jobsFlat[k]);
            break;
          }
        }
      }
      this.dAnswer = tempAnswer;
      this.panel = null;
      //this.treeAnswer = JSON.stringify(a);
      //this.treeAnswer = JSON.parse(JSON.stringify(this.answer));
      /*    setTimeout(function() {
      alert("Boo!");
      self.treeAnswer = JSON.parse(
        JSON.stringify([1141, 1142, 1143, 1144, 1145, 1146, 1149])
      );
    }, 3000); */
    }
  },
  methods: {
    findId: function (obj, id) {
      for (var i = 0; i < obj.length; i++) {
        //console.log("Object is" + obj[i].id);
        //console.log("id is: " + id);
        if (obj[i].id == id) {
          //console.log("Lap find: " + i);
          return i;
        }
      }
      return -1;
    },
    find: function (obj, id) {
      for (var i = 0; i < obj.length; i++) {
        //console.log("Object is" + obj[i].id);
        //console.log("id is: " + id);
        if (obj[i] == id) {
          //console.log("Lap find: " + i);
          return i;
        }
      }
      return -1;
    },
    remove(answerList, treeList, item) {
      //console.log(item.id);
      //const index = list.indexOf(item.id);
      const indexTree = this.find(treeList, item.id);
      if (indexTree >= 0) treeList.splice(indexTree, 1);
      const indexAnswer = this.findId(answerList, item.id);
      if (indexAnswer >= 0) answerList.splice(indexAnswer, 1);
    },
    jobFocus: function () {
      //if (this.$vuetify.breakpoint.xsAndUp) {
      if (true) {
        setTimeout(() => {
          this.dialog = true;
          //console.log(this.$refs);
          this.$refs.jobDialogInput.focus();
        }, 1);
      }
    },
    iterObj: function (obj) {
      for (var key in obj) {
        //console.log(key + ": " + obj[key]);
        if (obj[key] !== null && typeof obj[key] === "object") {
          // Recurse into children
          this.iterObj(obj[key]);
        } else if (obj[key] === "id") {
        }
      }
    },
    toggleExpand: function (a) {
      //console.log(a);
    },
    searchUpdate(val) {
      //console.log(val);
      var self = this;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(function () {
        self.search_tree = val;
      }, this.debounceDelay);
    },
  },
};
</script>
<style></style>
