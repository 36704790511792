<template>
  <div class="page-wrapper mx-auto page-normal">
    <PageHeader :title="page.title" :showDivider="false"></PageHeader>
    <PageActions :alert-bar="alertBar">
      <ContinueBar
        :message="page.continueMessage"
        :btn-message="page.continueBtnMessage"
      ></ContinueBar>
    </PageActions>
    <PageContent :loading="loading" :loading-message="loadingMessage">
      <v-row>
        <v-col>
          <v-row v-if="drafts.length > 0">
            <v-col>
              <switch-button
                v-model="showDrafts"
                :label="'Visa utkast (' + drafts.length + ')'"
              >
              </switch-button>
            </v-col>
          </v-row>
          <v-row v-if="showDrafts" justify="center">
            <v-col cols="12" lg="10" xl="8">
              <job-list
                :title="'Utkast'"
                :headers="draftHeaders"
                :jobs="drafts"
              >
                <template v-slot:action="{ item }">
                
                </template>
              </job-list>
             
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row v-if="this.$vuetify.breakpoint.smAndDown">
                <v-col>
                  <v-card max-width="800">
                    <v-toolbar flat>
                      <v-toolbar-title>Processer</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu :close-on-content-click="false" bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-tune</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>Filter</v-list-item-title>
                                <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-action>
                                <v-switch
                                  v-model="filter.active"
                                  color="primary"
                                  hide-details
                                ></v-switch>
                              </v-list-item-action>
                              <v-list-item-title>{{
                                page.showOnlyActiveJobsText
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-toolbar>
                    <v-col v-if="jobs == null || jobs.length == 0">
                      <span>
                        Du har inga pågående processer just nu.
                      </span></v-col
                    >
                    <v-list v-else two-line>
                      <v-list-item-group>
                        <template v-for="(item, index) in filteredJobs">
                          <v-list-item
                            :key="item.job_guid"
                            @click="openProcess(item)"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                class="text--primary"
                                v-text="item.cityText"
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                v-text="item.department"
                              ></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-list-item-action-text
                                v-if="item.hasOwnProperty('applicants')"
                                v-text="item.applicants.length + ' ansökningar'"
                              ></v-list-item-action-text>
                              <v-list-item-action-text
                                v-else
                                v-text="'Inga ansökningar'"
                              ></v-list-item-action-text>
                              <v-avatar
                                :color="item.statusColor"
                                size="24"
                              ></v-avatar>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else justify="center">
                <v-col cols="12" lg="10" xl="8">
                  <Card :title="page.cardTitle">
                    <template v-slot:cardOptions>
                      <v-text-field
                        :value="search"
                        append-icon="search"
                        label="Sök"
                        single-line
                        hide-details
                        @input="searchUpdate($event)"
                      ></v-text-field>

                      <v-menu :close-on-content-click="false" bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-tune</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>Filter</v-list-item-title>
                                <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-action>
                                <v-switch
                                  v-model="filter.active"
                                  color="primary"
                                  hide-details
                                ></v-switch>
                              </v-list-item-action>
                              <v-list-item-title>{{
                                page.showOnlyActiveJobsText
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </template>
                    <v-col
                      ><span v-if="jobs == null || jobs.length == 0">
                        Du har inga pågående processer just nu.
                      </span></v-col
                    >
                    <v-data-table
                      :mobile-breakpoint="mobileBreakpoint"
                      class="clickable-rows-table"
                      :headers="headers"
                      :items="filteredJobs"
                      :search="search_table"
                      :sort-by="['created_date']"
                      :sort-desc="true"
                      :sort="true"
                      @click:row="openProcess"
                    >
                      <template v-slot:item.statusText="{ item }">
                        <v-tooltip
                          v-model="item.tooltip"
                          color="primary"
                          left
                          nudge-bottom
                        >
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-avatar
                                :color="item.statusColor"
                                size="24"
                              ></v-avatar>
                            </div>
                          </template>
                          <span>{{ item.statusText }}</span>
                        </v-tooltip>
                      </template>
                      <!--   <template v-slot:item.statusText="{ item }">
            <v-btn
              depressed
              block
              class="button-not-clickable"
              :color="item.statusColor"
            >{{ item.statusText }}</v-btn>
          </template>-->
                    </v-data-table>
                  </Card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </PageContent>
  </div>
</template>
<script>
import store from "../store";
import { mapState, mapActions } from "vuex";
import router from "../router";

import filter from "../utils/filter";

import alertBarMixin from "../mixins/alertBar";
import snackbarMixin from "../mixins/snackbar";
import basicMixin from "../mixins/basic";
import handleLoad from "../mixins/handleLoad";
import JobList from "../components/organism/JobDraftsList.vue";
import SwitchButton from "../components/atom/select/SwitchButton.vue";

export default {
  mixins: [basicMixin, snackbarMixin, alertBarMixin, handleLoad],
  components: { JobList, SwitchButton },
  data: () => {
    return {
      page: {
        key: "jobs",
        data: "jobs",
        get: "jobs",
        title: "Följ processer",
        cardTitle: "Processer",
        continueMessage: "Svar ändrade",
        continueBtnMessage: "Spara",
        missingAnswersMessage: "Svara på alla frågor för att gå vidare",
        missingAnswersBtnMessage: "Visa",
        showOnlyActiveJobsText: "Visa endast aktiva processer",
      },
      filter: {
        active: true,
      },
      search: "",
      search_table: "",
      jobs: [],
      drafts: [],
      showDrafts: false,
      promtSaveOnChanges: false,
      showMissing: false,
      validateTabs: true,
      currentSectionValid: false,
      watchQuestions: false,
      loading: false,
      loadingMessage: null,
      showDeleteJobDialog: false,
    };
  },
  watch: {
    storeData: function () {
      this.jobs = this.storeData;
      this.drafts = this.jobDrafts;
    },
  },
  computed: {
    ...mapState("jobs", [
      "status",
      "breadcrumbs",
      "headers",
      "draftHeaders",
      "activeJobStatus",
      "upcomingInterviews",
      "jobDrafts",
    ]),
    ...mapState("applications", ["latestApplications"]),
    filteredJobs: function () {
      return filter.jobList({
        list: this.jobs,
        filter: this.filter,
        activeJobStatus: this.activeJobStatus,
      });
    },
  },
  methods: {
    ...mapActions("candidateCard", ["setCandidate"]),
    openCandidate: function (application) {
      this.setCandidate(application);
      this.$router.push({
        name: "Candidate",
        params: {
          application_guid: application.guid,
          job_guid: application.job_guid,
          currentView: "selectCandidates",
        },
      });
    },
    openProcess(job) {
      this.$router.push({ name: "Job", params: { id: job.job_guid } });
    },
    searchUpdate(val) {
      var self = this;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(function () {
        self.search_table = val;
      }, this.debounceDelay);
    },
  },
  mounted: function () {},
};
</script>
