const initialLoad = async (context, data) => {
  if (context.state.status == "initialize") {
    context.commit("status", "loaded");
    context.dispatch("user/getUserInformation", null, { root: true });
    context.dispatch("companyQuestions/get", null, { root: true });
  }
  // Fetch all required data...
};

export default {
  initialLoad: initialLoad,
};
