<template>
  <v-row>
    <v-col cols="12"><heading-1>Heading 1</heading-1></v-col>
    <v-col cols="12"><heading-2>Heading 2</heading-2></v-col>
    <v-col cols="12"><heading-3>Heading 3</heading-3></v-col>
    <v-col cols="12"><heading-4>Heading 4</heading-4></v-col>
    <v-col cols="12"><heading-5>Heading 5</heading-5></v-col>
    <v-col cols="12"><subtle-heading>Subtle heading</subtle-heading></v-col>
  </v-row>
</template>

<script>
import Heading1 from "../../../components/atom/heading/Heading1.vue";
import Heading2 from "../../../components/atom/heading/Heading2.vue";
import Heading3 from '../../../components/atom/heading/Heading3.vue';
import Heading4 from '../../../components/atom/heading/Heading4.vue';
import Heading5 from '../../../components/atom/heading/Heading5.vue';
import SubtleHeading from '../../../components/atom/heading/SubtleHeading.vue';

export default {
  components: { Heading1, Heading2, Heading3, Heading4, Heading5,SubtleHeading },
};
</script>
