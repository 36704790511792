<template>
  <div>
    <v-select
      v-if="answerAlternatives.length > 2"
      v-model="dAnswer"
      :items="answerAlternatives"
      item-value="val"
      item-text="text"
      color="white"
      :disabled="disabled"
    ></v-select>
    <v-radio-group v-else v-model="dAnswer" row>
      <v-radio
        v-for="alternative in answerAlternatives"
        :key="alternative.val"
        color="primary"
        :label="alternative.text"
        :value="alternative.val"
        :disabled="disabled"
      ></v-radio>
    </v-radio-group>
    <v-text-field
      v-if="dAnswer == 1 && disabled"
      v-model="answer_text"
      :disabled="disabled"
      placeholder="Ej angivet"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    disabled: Boolean,
  },
  data() {
    return {
      dAnswer: this.answer,
      dValidAnswer: false,
      typing: false,
      typingDone: false,
    };
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer,
        });
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
