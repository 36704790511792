<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-text-field hide-details placeholder="Blocket företagsId" filled rounded dense></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
export default {
  
}
</script>