<template>
  <div class="mx-auto page-normal d-flex flex-column">
    <PageHeader
      :back="true"
      :title="page.title"
      :showDivider="false"
    ></PageHeader>
    <PageActions
      v-if="applicants.length > 0"
      :progress="progress"
      :alert-bar="alertBar"
    >
      <template slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 mb-2" :items="inviteActions">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              v-if="item.hide == null || item.hide != true"
              :disabled="item.disabled"
              :class="{
                'text-decoration-underline': item.key == key,
                clickable: !item.disabled,
              }"
              @click="goTo(item.key)"
              >{{ item.text }}</v-breadcrumbs-item
            >
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </PageActions>
    <PageContent :loading="loading" :loading-message="page.loadingMessage">
      <v-row
        ><v-col cols="12">
          <AlertBar
            v-if="alertBar.visible"
            :visible="alertBar.visible"
            :message="alertBar.message"
            :btn-message="alertBar.btnMessage"
          ></AlertBar>
          <v-row v-else>
            <v-col>
              <v-alert
                v-if="showSendInvitesMessage"
                class="mt-4"
                dissmissable
                dismissible
                type="success"
                color="secondary"
                >Kandidater kallade på intervju!</v-alert
              >
              <div class="d-flex flex-wrap">
                <h2 v-if="job != null" class="mt-0 d-inline text-left">
                  {{ job.title }}
                </h2>

                <v-spacer></v-spacer>
                <!--   <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="white"
                class="text-none mr-2"
              >
                <v-icon>publish</v-icon>
              </v-btn>
            </template>
            <span>Publicera tjänsten på arbetsförmedlingen</span>
            </v-tooltip> -->
                <v-menu
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      class="text-none mr-2"
                      color="white"
                      v-on="on"
                    >
                      <v-icon>share</v-icon>
                    </v-btn>
                  </template>
                  <v-card width="350">
                    <v-card-text>
                      Länk för delning, annonser, etc.
                      <v-text-field
                        ref="applicationLink"
                        :value="applicationLink"
                      ></v-text-field>
                      <v-row>
                        <v-col>
                          <v-btn
                            class="text-none"
                            color="primary"
                            rounded
                            @click="copyApplicationLink"
                            >Kopiera</v-btn
                          >
                          <v-alert
                            v-if="copiedApplicationLink"
                            style="vertical-align: middle"
                            class="ml-2 mb-0 d-inline-block"
                            dense
                            text
                            type="success"
                          >
                            Kopierad!
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      class="text-none"
                      color="white"
                      v-on="on"
                    >
                      <v-icon>settings</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="editProcess">
                      <v-icon color="primary">build</v-icon>
                      <v-list-item-title>Redigera tjänst</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="finishProcessDialog = true">
                      <v-icon color="primary">check_circle</v-icon>
                      <v-list-item-title>Tillsätt tjänst</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteProcessDialog = true">
                      <v-icon color="primary">delete</v-icon>
                      <v-list-item-title>Radera tjänst</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <!--  <v-row>
                <v-col>
                  <div>
                    <small
                      ><a
                        class="text-decoration-underline"
                        target="_blank"
                        :href="jobApplicationLink"
                        >Sök detta jobbet på "arbetsförmedlingen"</a
                      ></small
                    >
                  </div>
                </v-col>
              </v-row> -->
              <Dialog v-model="finishProcessDialog" title="Tillsätt tjänst?">
                Genom att tillsätta tjänsten meddelar du samtliga kandidater som
                ej gått vidare i processen om att de ej gått vidare. Ett
                automatiskt email skickas till kandidaten med eventuella nästa
                steg.
                <template v-slot:actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="text-none"
                    @click="finishProcessDialog = false"
                    >Avbryt</v-btn
                  >
                  <Button :type="'error'" @clicked="finishProcess"
                    >Ja, tillsätt</Button
                  >
                </template>
              </Dialog>
              <Dialog
                v-model="deleteProcessDialog"
                title="Vill du radera jobb?"
              >
                Denna handling kan inte ångras.
                <template v-slot:actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="text-none"
                    @click="deleteProcessDialog = false"
                    >Avbryt</v-btn
                  >
                  <Button :type="'error'" @clicked="deleteProcess"
                    >Ja, radera</Button
                  >
                </template>
              </Dialog>
              <Spinner v-if="loading" :message="'Hämtar ansökningar'" />
              <v-row>
                <v-col>
                  <ManageCandidates
                    v-if="key == 'manage'"
                    :current-view="key"
                    @updateView="updateView"
                  />
                  <PickCandidates
                    v-if="key == 'candidates'"
                    :pre-choose-application-guid="dPreChooseApplicationGuid"
                    @updateView="updateView"
                  />
                  <PickInterviewTimes
                    v-if="key == 'calendar'"
                    @updateView="updateView"
                  />
                  <InviteCandidates
                    v-if="key == 'invite'"
                    @updateView="updateView"
                    @sendInvitations="sendInvitations"
                  />
                  <FollowInvitations
                    v-if="key == 'follow'"
                    :current-view="key"
                    @updateView="updateView"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </PageContent>
  </div>
</template>
<script>
import router from "../router";
import store from "../store";
import { mapState, mapActions, mapGetters } from "vuex";

import Spinner from "../components/misc/Spinner";
import ManageCandidates from "../components/Process/ManageCandidates";
import PickCandidates from "../components/Process/PickCandidates";
import PickInterviewTimes from "../components/Process/PickInterviewTimes";
import InviteCandidates from "../components/Process/InviteCandidates";
import FollowInvitations from "../components/Process/FollowInvitations";

import alertBarMixin from "../mixins/alertBar";
import snackbarMixin from "../mixins/snackbar";
import basicMixin from "../mixins/basic";
import handleLoad from "../mixins/handleLoad";

export default {
  components: {
    ManageCandidates,
    PickCandidates,
    PickInterviewTimes,
    InviteCandidates,
    FollowInvitations,
    Spinner,
  },
  mixins: [basicMixin, snackbarMixin, alertBarMixin],
  props: {
    forceKey: String,
    preChooseApplicationGuid: String,
  },
  data() {
    return {
      copiedApplicationLink: false,
      deleteProcessDialog: false,
      finishProcessDialog: false,
      dPreChooseApplicationGuid: null,
      page: {
        key: "process",
        data: "process",
        get: "process",
        title: "Ansökningar",
        cardTitle: "Processer",
        continueMessage: "continueMessage",
        continueBtnMessage: "continueBtnMessage",
        loadingMessage: "Hämtar ansökningar",
      },
      showSendInvitesMessage: false,
      loading: true,
      firstLoad: true,
      type: "manage",
      jobApplicationLink: null,
      key: "manage",
      manageActions: [],
      inviteActions: [
        { key: "manage", text: "Hantera", type: "manage", disabled: false },
        {
          key: "candidates",
          text: "Kandidater",
          type: "invite",
          disabled: false,
        },
        {
          key: "calendar",
          text: "Intervjutider",
          type: "invite",
          disabled: true,
        },
        { key: "invite", text: "Bjud in", type: "invite", disabled: true },
        {
          key: "follow",
          text: "Översikt",
          type: "follow",
          disabled: false,
          hide: true,
        },
      ],
      /*  followInvitesBreadcrumb: {
        key: "follow",
        text: "Inbjudningar",
        type: "follow",
        disabled: false,
      }, */
      action: "",
    };
  },
  computed: {
    ...mapGetters("applications", ["applicationsByJobGuid"]),
    progress: function () {
      for (var i = 0; i < this.inviteActions.length; i++) {
        if (this.key == this.inviteActions[i].key) {
          return (100 * (i + 1)) / this.inviteActions.length;
        }
      }
      return null;
    },
    applicationLink: function () {
      var baseURL = "https://my.brite.jobs/signup?job=";
      if (process.env.NODE_ENV == "development") {
        baseURL = "https://at-dev-brite.web.app/signup?job=";
      }
      if (process.env.NODE_ENV == "demo") {
        baseURL = "https://at-demo-brite.web.app/signup?job=";
      }
      if (process.env.NODE_ENV == "staging") {
        baseURL = "https://at-staging-brite.web.app/signup?job=";
      }
      return baseURL + this.job.job_guid;
    },
    ...mapState("applications", {
      status: "status",
      applications: "applications",
    }),
    ...mapState("process", [
      "job",
      "applicants",
      "contactedApplicants",
      "choosenApplicants",
      "choosenInterviewTimes",
      "valid",
      "validChoosenApplicants",
      "validChoosenInterviewTimes",
      "breadcrumbs",
    ]),
  },
  watch: {
    status: function () {
      this.handleLoad();
    },
    validChoosenInterviewTimes: function () {
      this.handleBreadCrumbs();
    },
    validChoosenApplicants: function () {
      this.handleBreadCrumbs();
    },
  },
  mounted: function () {
    this.handleLoad();
    this.handleBreadCrumbs();
    this.jobApplicationLink =
      "https://demos-brite.web.app/jobs/apply.html?job=" +
      this.$route.params.id;
    if (process.env.NODE_ENV == "development") {
      this.jobApplicationLink =
        "https://demos-brite.web.app/jobs/apply.html?mode=dev&job=" +
        this.$route.params.id;
    }
  },
  methods: {
    copyApplicationLink: function () {
      this.copiedApplicationLink = false;
      let textToCopy = this.$refs.applicationLink.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
      this.copiedApplicationLink = true;
    },
    finishProcess: async function () {
      try {
        await this.updateJobStatus({
          job_guid: this.job.job_guid,
          status: "FINISHED",
        });
        this._snackbarSuccess("Jobb tillsatt");
        router.push({ name: "Processes" });
      } catch (error) {
        console.log(error);
        this._snackbarError("Kunde inte tillsätta jobbet");
        this.finishProcessDialog = false;
      }
    },
    deleteProcess: async function () {
      await this.updateJobStatus({
        job_guid: this.job.job_guid,
        status: "DELETED",
      });
      this._snackbarSuccess("Jobb raderat");
      router.push({ name: "Processes" });
    },
    editProcess: function () {
      this.$router.push({
        name: "EditJob",
        params: {
          job_guid: this.job.job_guid,
          jobStatus: this.job.status,
        },
        query: { status: this.job.status },
      });
    },
    sendInvitations: async function () {
      try {
        this.loading = true;
        await this.inviteToInterviews();
        this.showSendInvitesMessage = true;
        this.inviteActions[this.inviteActions.length - 1].hide = false;
        this.key = "follow";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
        this._snackbarError("Kunde inte skicka inbjudan till intervju!");
      }
    },
    handleBreadCrumbs: function () {
      for (var i = 0; i < this.inviteActions.length; i++) {
        if (this.inviteActions[i].key == "calendar") {
          if (
            this.validChoosenApplicants ||
            this.choosenInterviewTimes.length > 0
          ) {
            this.inviteActions[i].disabled = false;
          } else {
            this.inviteActions[i].disabled = true;
          }
        }
        if (this.inviteActions[i].key == "invite") {
          this.inviteActions[i].disabled = true;
          if (this.valid) {
            this.inviteActions[i].disabled = false;
          }
        }
      }
    },
    updateView: function (key) {
      if (key != null) {
        this.key = key;
        return;
      }
      if (this.key == "manage") {
        this.key = "candidates";
      } else if (this.key == "candidates") {
        this.key = "calendar";
      } else if (this.key == "calendar") {
        this.key = "invite";
      } else if (this.key == "invite") {
        this.inviteActions[this.inviteActions.length - 1].hide = false;
        this.key = "follow";
        this.showSendInvitesMessage = true;
      }
      // this.key = "candidates";
    },
    goTo: function (key) {
      this.key = key;
    },
    handleLoad: function () {
      if (this.status == "success") {
        this.key = "manage";
        this.resetProcessData();
        this.loadProcessData({ job_guid: this.$route.params.id });
        if (this.job == null) {
          this.handleAlertBar({
            visible: true,
            message:
              "Kunde inte hitta detta jobb, är du säker på att länken är korrekt?",
            btnMessage: "Ladda om sidan",
          });
          this.loading = false;
          return;
        }
        /*  var applicationTemp = this.applicationsByJobGuid(this.$route.params.id);
        if (applicationTemp.length > 0) {
          this.loadProcessData({
            job_guid: this.$route.params.id,
            applicants: this.applicationsByJobGuid(this.$route.params.id),
          });
          console.log(this.contactedApplicants); */
        if (this.contactedApplicants.length > 0) {
          this.inviteActions[this.inviteActions.length - 1].hide = false;
          this.key = "follow";
        }
        if (this.firstLoad == false) {
          this.dPreChooseApplicationGuid = null;
        }
        if (this.firstLoad == true && this.preChooseApplicationGuid != null) {
          if (this.preChooseApplicationGuid != null) {
            this.dPreChooseApplicationGuid = this.preChooseApplicationGuid;
          }
          if (this.forceKey != null) {
            this.key = this.forceKey;
          }
          this.firstLoad = false;
        }
        this.loading = false;
        this.handleBreadCrumbs();
      } else {
        store.dispatch("jobs/jobs", null);
      }
    },
    ...mapActions("process", {
      loadProcessData: "loadProcessData",
      resetProcessData: "resetProcessData",
      inviteToInterviews: "inviteToInterviews",
      updateJobStatus: "updateJobStatus",
    }),
  },
};
</script>
