<template>
  <v-row>
    <v-col v-for="item in jobSummary" :key="item.text" cols="12" md="6" lg="6">
      <v-list-item class="px-0">
        <v-list-item-avatar size="50" color="secondary">
          <v-icon color="white">{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle
            class="text--primary"
            v-text="item.text"
          ></v-list-item-subtitle>
          <v-list-item-title
            class="font-weight-bold"
            v-text="item.value"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    jobSummary: Object,
  },
};
</script>
