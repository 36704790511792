const jobList = ({ list, filter, activeJobStatus }) => {
  if (list == null || list.length < 1) {
    return [];
  }

  var validFilter = {};
  for (const property in filter) {
    if (
      filter[property] != null &&
      filter[property] != undefined &&
      filter[property] != false
    ) {
      if (property == "active") {
        validFilter["status"] = activeJobStatus;
      }
    }
  }
  list = list.filter(function (item) {
    for (var key in validFilter) {
      if (key == "status") {
        return validFilter[key].includes(item.status);
      }
      if (item[key] === undefined || item[key] != validFilter[key]) {
        return false;
      }
    }
    return true;
  });
  return list;
};

export default {
  jobList: jobList,
};
