<template>
  <div>
    <h3>Annonsering</h3>
    <v-row>
      <v-col cols="12">
        <v-row
          ><v-col>
            <v-expansion-panels
              accordion
              multiple
              flat
              class="transparent-expansion-panel"
            >
              <v-expansion-panel
                color="primary"
                elevation-0
                v-for="(integration, key) in integrations"
                :key="key"
              >
                <v-expansion-panel-header class="py-0">
                  <v-list-item two-line class="px-0">
                    <v-list-item-content class="py-0">
                      <h4>{{ integration.title }}</h4>
                      <span>{{ integration.subTitle }}</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon :color="integration.statusColor">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-list-item-action-text>{{
                        integration.statusText
                      }}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                  <v-col
                    cols="12"
                    v-if="
                      integration.settingsValid &&
                      integration.status != 'PENDING'
                    "
                  >
                    <switch-button
                      label="Integrationsstatus"
                      :status="integration.active"
                      v-on:update:status="
                        integration.active = $event;
                        saveSettings();
                      "
                    ></switch-button>
                  </v-col>
                  </v-row>
                  <component
                    :is="integration.component"
                    v-bind:settings.sync="integration.settings"
                    v-bind:settingsValid.sync="integration.settingsValid"
                    :active="integration.active"
                    @save="saveSettings"
                  ></component>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <brite-love-integrations></brite-love-integrations>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BriteLoveIntegrations from "@/components/organism/BriteLoveIntegrations.vue";
import InfoBanner from "../../organism/InfoBanner";
import BlocketIntegrationSettings from "../Integrations/BlocketIntegrationSettings.vue";
import AmsIntegrationSettings from "../Integrations/AmsIntegrationSettings.vue";
import SwitchButton from "../../atom/select/SwitchButton.vue";

export default {
  components: {
    InfoBanner,
    BriteLoveIntegrations,
    BlocketIntegrationSettings,
    AmsIntegrationSettings,
    SwitchButton,
  },
  created: async function () {
    this.integrations = await this.getCompanyJobPostingSettings();
  },
  data: function () {
    return {
      integrations: {
        blocket: {
          component: "BlocketIntegrationSettings",
          title: "Blocket Jobs",
          subTitle: "Annonsera på Blocket Jobs",
          status: "AKTIV",
          statusColor: "open",
        },
        ams: {
          component: "AmsIntegrationSettings",
          title: "Arbetsförmeedlingen",
          subTitle: "Annonsera på Arbetsförmeedlingen",
          status: "AKTIV",
          statusColor: "open",
        },
      },
    };
  },
  methods: {
    saveSettings: async function () {
      if (
        this.integrations.ams.active &&
        this.integrations.ams.status != "PENDING"
      ) {
        this.integrations.ams.status = "ACTIVE";
      }
      if (
        !this.integrations.ams.active &&
        this.integrations.ams.status != "PENDING"
      ) {
        this.integrations.ams.status = "INACTIVE";
      }
      await this.saveCompanyJobPostingSettings(this.integrations);
    },
    ...mapActions("companyProfile", [
      "getCompanyJobPostingSettings",
      "saveCompanyJobPostingSettings",
    ]),
  },
};
</script>