import {
  getJobs,
  updateJob,
  updateApplication,
  sendInvites,
  setApplicationStatus,
  setInterviewTimes,
  postInviteToTest,
  getJobPostingSettingsRequest,
  putJobPostingSettingsRequest
} from "../../requests/global";


const getJobPostingSettings = async (context, jobGuid) => {
  try {
    context.commit('jobPostingSettingsStatus', 'pending');
    let settings = await getJobPostingSettingsRequest({ body: null, jobGuid: jobGuid });
    let jobPostingSettings = (() => {
      let tempSettings = {};
      for (const [key, value] of Object.entries(settings)) {
        tempSettings[key] = {};
        tempSettings[key].published =
          settings[key].status == "PUBLISHED" ? true : false;
      }
      return tempSettings;
    })();
    context.commit('jobPostingSettings', jobPostingSettings);
    context.commit('jobPostingSettingsStatus', 'success');
  } catch (e) {
    console.log(e);
    context.commit('jobPostingSettingsStatus', 'failed');
  }
}

const updateJobPostingSettings = async (context, data) => {
  let jobPostingSettings = (() => {
    let settings = {};
    for (const [key, value] of Object.entries(data.settings)) {
      settings[key] = {};
      settings[key].status =
        data.settings[key].published == true
          ? "PUBLISHED"
          : "NOT_PUBLISHED";
    }
    return settings;
  })();

  await putJobPostingSettingsRequest({ body: jobPostingSettings, jobGuid: data.jobGuid });
  context.commit('jobPostingSettings', data.settings);
}

const inviteToTest = async (context, application) => {
  await postInviteToTest({
    job_guid: context.state.job_guid,
    application_guid: application.guid,
  });
  application.invitedToTest = true;
  updateApplicationsAfterRequest(context, application);
};
const updateApplicationsAfterRequest = function (context, application) {
  var updatedApplications = JSON.parse(
    JSON.stringify(context.state.applicants)
  );
  for (var i = 0; i < updatedApplications.length; i++) {
    if (updatedApplications[i].guid == application.guid) {
      updatedApplications[i] = JSON.parse(JSON.stringify(application));
    }
  }
  context.dispatch(
    "applications/updateApplications",
    { job_guid: context.state.job_guid, applicants: updatedApplications },
    { root: true }
  );
  context.dispatch("applicants", updatedApplications);
};

const updateJobStatus = async (context, data) => {
  await updateJob({ job_guid: data.job_guid, body: { status: data.status } });
  context.commit("applications/status", "initialize", { root: true });
  context.commit("jobs/status", "initialize", { root: true });
};

const inviteToInterviews = async (context, data) => {
  var invites = await sendInvites({
    job_guid: context.state.job_guid,
    body: {
      applications: context.state.choosenApplicants,
      interviewTimes: context.state.choosenInterviewTimes,
    },
  });
  context.dispatch("resetProcessData");
  context.dispatch("jobs/jobs", null, { root: true });
  context.commit("applications/status", "initialize", { root: true });
};

const updateInterviewTimes = async (context, data) => {
  var update = await setInterviewTimes({
    job_guid: context.state.job_guid,
    body: { interviewTimes: data },
  });
};

const processes = async (context, data) => {
  var jobs = await getJobs();
};

const resetProcessData = (context, data) => {
  context.commit("job", null);
  context.commit("job_guid", null);
  context.commit("applicants", []);
  context.commit("contactedApplicants", []);
  context.commit("choosenInterviewTimes", []);
  context.commit("choosenApplicants", []);
  context.commit("interviewTimesLeftToChoose", null);
  context.commit("valid", false);
  context.commit("validChoosenApplicants", false);
  context.commit("validChoosenInterviewTimes", false);
};

const loadProcessData = (context, data) => {
  // Reset if needed
  if (context.state.job_guid != null) {
    context.dispatch("resetProcessData");
  }

  // Get job info
  context.commit("job_guid", data.job_guid);
  var job = context.rootState.jobs.allJobs.filter((elem) => {
    return elem.job_guid == data.job_guid;
  });
  if (job.length < 1) {
    context.commit("job", null);
    return;
  }
  context.commit("job", job[0]);

  // Load previous interview times
  if (job[0].hasOwnProperty("interviews")) {
    var available = job[0].interviews.filter((interview) => {
      return interview.interview_status == "AVAILABLE";
    });
    var booked = job[0].interviews.filter((interview) => {
      return interview.interview_status == "BOOKED";
    });
    var completed = job[0].interviews.filter((interview) => {
      return interview.interview_status == "COMPLETED";
    });

    context.dispatch("choosenInterviewTimes", available);
    context.dispatch("bookedInterviewTimes", booked);
    context.dispatch("completedInterviewTimes", completed);
  }

  // Get applications
  var applicants = context.rootGetters["applications/applicationsByJobGuid"](
    data.job_guid
  );
  if (applicants != undefined && applicants != null && applicants != []) {
    context.dispatch("applicants", applicants);
  }
};

const setApplicantStatus = async (context, data) => {
  var applicant = data.applicant;
  var status = data.status;
  var tempApp = JSON.parse(JSON.stringify(context.state.applicants));
  for (var i = 0; i < tempApp.length; i++) {
    if (tempApp[i].guid == applicant.guid) {
      tempApp[i] = JSON.parse(JSON.stringify(applicant));
    }
  }
  // Update backend
  await setApplicationStatus({
    job_guid: context.state.job_guid,
    application_guid: applicant.guid,
    body: { status: status },
  });
  // Update applications
  context.dispatch(
    "applications/updateApplications",
    { job_guid: context.state.job_guid, applicants: tempApp },
    { root: true }
  );
  // Update applicants in process
  context.dispatch("applicants", tempApp);
};

const updateApplicant = async (context, applicant) => {
  var tempApp = JSON.parse(JSON.stringify(context.state.applicants));
  for (var i = 0; i < tempApp.length; i++) {
    if (tempApp[i].guid == applicant.guid) {
      tempApp[i] = JSON.parse(JSON.stringify(applicant));
    }
  }
  // Update backend
  await updateApplication({
    job_guid: context.state.job_guid,
    application_guid: applicant.guid,
    body: applicant,
  });
  // Update applications
  context.dispatch(
    "applications/updateApplications",
    { job_guid: context.state.job_guid, applicants: tempApp },
    { root: true }
  );
  // Update applicants in process
  context.dispatch("applicants", tempApp);
};

const applicants = (context, data) => {
  //console.log("contacted")
  var chosen = data.filter(function (item) {
    return item.choosen == true;
  });
  var contacted = data.filter(function (item) {
    return context.state.contactedApplicantsStatus.includes(item.status);
  });
  //console.log(contacted)
  context.commit("choosenApplicants", chosen);
  context.commit("contactedApplicants", contacted);
  context.commit("applicants", data);
  //console.log("contacted")

  context.dispatch("validate", null);
};
const choosenInterviewTimes = (context, data) => {
  context.commit("choosenInterviewTimes", data);

  context.dispatch("validate", null);
};

function addShowDetailedInterviewInfoBoolean(data) {
  for (var i = 0; i < data.length; i++) {
    data[i].showDetailedInterviewInfo = false;
  }
}
const bookedInterviewTimes = (context, data) => {
  addShowDetailedInterviewInfoBoolean(data);
  context.commit("bookedInterviewTimes", data);
};

const completedInterviewTimes = (context, data) => {
  addShowDetailedInterviewInfoBoolean(data);

  context.commit("completedInterviewTimes", data);
};

const validate = (context, data) => {
  var status = true;
  var numberOfApplicantsWhoCanBookInterview =
    context.state.choosenApplicants.length +
    context.state.contactedApplicants.length -
    (context.state.bookedInterviewTimes.length +
      context.state.completedInterviewTimes.length);
  if (context.state.choosenApplicants.length > 0) {
    context.commit("validChoosenApplicants", true);
  } else {
    context.commit("validChoosenApplicants", false);
    status = false;
  }
  if (
    context.state.choosenInterviewTimes.length >=
    numberOfApplicantsWhoCanBookInterview + context.state.interviewMinimum
  ) {
    context.commit("validChoosenInterviewTimes", true);
  } else {
    context.commit("validChoosenInterviewTimes", false);
    status = false;
  }
  //context.commit("validChoosenInterviewTimes", true);

  context.commit(
    "interviewTimesLeftToChoose",
    Math.max(
      0,
      context.state.choosenApplicants.length +
      context.state.contactedApplicants.length +
      context.state.interviewMinimum -
      context.state.choosenInterviewTimes.length
    )
  );
  context.commit("valid", status);
};

export default {
  updateJobPostingSettings: updateJobPostingSettings,
  getJobPostingSettings: getJobPostingSettings,
  inviteToTest: inviteToTest,
  loadProcessData: loadProcessData,
  applicants: applicants,
  choosenInterviewTimes: choosenInterviewTimes,
  validate: validate,
  processes: processes,
  updateApplicant: updateApplicant,
  resetProcessData: resetProcessData,
  inviteToInterviews: inviteToInterviews,
  bookedInterviewTimes: bookedInterviewTimes,
  completedInterviewTimes: completedInterviewTimes,
  setApplicantStatus: setApplicantStatus,
  updateInterviewTimes: updateInterviewTimes,
  updateJobStatus: updateJobStatus,
};
