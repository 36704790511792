<template>
  <div class="mx-auto page-normal">
    <PageHeader
      :back="job_guid != null ? true : false"
      :title="page.title"
      :showDivider="false"
    ></PageHeader>
    <PageActions
      :progress="progress"
      :breadcrumbs="breadcrumbs"
      :breadcrumbs-stepper="true"
      :alert-bar="alertBar"
      :current-tab="currentTab"
      @switch-tab="switchTab"
    >
      <ContinueBar
        v-if="promtSaveOnChanges"
        :visible="changesPending"
        :message="page.continueMessage"
        :btn-message="page.continueBtnMessage"
        @continue="updateJob"
      ></ContinueBar>
    </PageActions>
    <PageContent
      class="pt-4"
      :loading="loading"
      :loading-message="loadingMessage"
    >
      <div v-if="currentTab == 'created'">
        <v-row>
          <v-col>
            <h1>Toppen, din tjänst är skapad och redo att delas</h1>
          </v-col>
        </v-row>
        <JobOverview :job-summary="jobSummary"></JobOverview>
        <v-row justify="center">
          <v-col cols="9" md="6">
            <v-row class="justify-space-around">
              <Button class="mb-2" type="goto" @clicked="goToCreatedJob"
                >Dela tjänsten</Button
              >
              <Button type="goto" :text="true" @clicked="editCreatedJob"
                >Tillbaka till redigering</Button
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-if="currentTab == 'manageJobPostings'">
        <manage-job-postings
          @clicked="currentTab = 'overview'"
          :jobGuid="job_guid"
        ></manage-job-postings>
      </div>
      <div v-if="'overview' == currentTab">
        <v-row>
          <v-col>
            <div v-if="job_guid == null || currentJobStatus == 'DRAFT'">
              <h1>Översikt</h1>
              <p>Tryck på skapa tjänst för att slutföra</p>
            </div>
            <div v-else>
              <h1>Översikt</h1>
              <p></p>
            </div>
            <p></p> </v-col
        ></v-row>
        <JobOverview class="pt-4" :job-summary="jobSummary"></JobOverview>
        <v-row class="mt-4">
          <v-col>
            <v-row justify="center">
              <Button
                v-if="
                  (job_guid == null || currentJobStatus == 'DRAFT') &&
                  allQuestionsValid
                "
                :type="'confirm'"
                @clicked="setJobToCreated"
                >Skapa tjänst</Button
              ></v-row
            >
          </v-col>
        </v-row>
      </div>

      <div v-else-if="groupedQuestions.length > 0">
        <div v-for="cat in groupedQuestions" :key="cat.tab">
          <v-row v-if="cat.tab == currentTab">
            <QBase
              v-for="question in cat.questions"
              :key="question.id"
              v-bind="question"
              :show-missing="showMissing"
            >
              <template v-slot:question>
                <component
                  :is="question.kind"
                  v-bind="question"
                  @changeAnswer="answersChanged"
                ></component>
              </template>
            </QBase>
          </v-row>
        </div>

        <!--  <div v-if="currentTab =='confirmCreateJob'">Bekräfta</div> -->
        <!--   <v-layout v-if="job_guid != null" justify-center>
          <Button :type="'confirm'" @clicked="updateJob" v-if="true"
            >Spara ändringar</Button
          >
          <InfoBar
            v-else
            :visible="true"
            @continue="showMissing = true"
            :message="page.missingAnswersMessage"
            :btnMessage="page.missingAnswersBtnMessage"
          />
        </v-layout> -->
        <v-row class="my-4" v-if="createdJobGuid == null" justify-center>
          <v-col class="d-flex justify-center">
            <Button
              v-if="currentSectionValid && progress != 100"
              :type="'confirm'"
              @clicked="nextStep"
              >Fortsätt</Button
            >
            <InfoBar
              v-else-if="currentTab != 'manageJobPostings' && progress != 100"
              :visible="true"
              :message="page.missingAnswersMessage"
              :btn-message="page.missingAnswersBtnMessage"
              @continue="showMissing = true"
            />
          </v-col>
        </v-row>
      </div>
    </PageContent>
  </div>
</template>
<script>
import store from "../store";
import { mapState, mapActions } from "vuex";
import router from "../router";

import questionsMixin from "../mixins/jobQuestions";
import alertBarMixin from "../mixins/alertBar";
import snackbarMixin from "../mixins/snackbar";
import basicMixin from "../mixins/basic";

import JobOverview from "../components/job/JobOverview";
import ManageJobPostings from "../components/templates/createjob/ManageJobPostings.vue";

export default {
  components: { JobOverview, ManageJobPostings },
  mixins: [basicMixin, snackbarMixin, questionsMixin, alertBarMixin],
  props: {
    job_guid: {
      default: null,
      type: String,
    },
    jobStatus: {
      type: String,
      default: null,
    },
    startTab: String,
  },
  data: () => {
    return {
      page: {
        key: "jobQuestions",
        title: "Skapa tjänst",
        continueMessage: "Tjänst ändrad",
        continueBtnMessage: "Spara",
        missingAnswersMessage: "Svara på alla frågor för att gå vidare",
        missingAnswersBtnMessage: "Visa",
      },
      useSummary: true,
      jobSummary: {
        title: { text: "Titel", icon: "mdi-account-circle", value: "" },
        place: { text: "Plats", icon: "place", value: "" },
        salary: { text: "Lön", icon: "mdi-currency-usd", value: 0 },
        workHours: { text: "Arbetstid", icon: "access_time", value: "" },
      },
      currentJobStatus: null,
      promtSaveOnChanges: false,
      showMissing: false,
      validateTabs: true,
      currentSectionValid: false,
      allQuestionsValid: false,
      watchQuestions: false,
      loading: false,
      loadingMessage: null,
      canContinue: false,
      collapseOnScroll: true,
      createdJobGuid: null,
    };
  },
  watch: {
    currentSectionValid: function () {
      this.setBreadCrumbsVisibility();
    },
    currentTab: function () {
      this.setBreadCrumbsVisibility();

      if (this.currentTab == "overview") {
        this.allQuestionsValid = this.isAllQuestionsValid();
      }
    },
  },
  beforeMount: function () {
    store.commit("jobQuestions/status", "initialize");
    if (this.startTab != null) {
      this.currentTab = this.startTab;
    }

    if (this.job_guid != null) {
      this.currentJobStatus =
        this.jobStatus != null ? this.jobStatus : this.$route.query.status;
      this.page.title = "Redigera tjänst";
      this.promtSaveOnChanges = true;
      this.setBreadCrumbsVisibility();

    }
    this.handleLoad(
      this.status,
      this.groupedQuestions,
      this.questionsKey,
      this.job_guid
    );
  },
  computed: {
    ...mapState("jobQuestions", ["status", "questionsKey", "breadcrumbs"]),
    progress: function () {
      for (var i = 0; i < this.breadcrumbs.length; i++) {
        if (this.breadcrumbs[i].tab == this.currentTab) {
          return (100 * (i + 1)) / this.breadcrumbs.length;
        }
      }
    },
  },
  mounted: function () {
    if (this.job_guid != null) {
      this.checkQuestions();
    }
  },
  methods: {
    setBreadCrumbsVisibility: function () {
      store.dispatch("jobQuestions/breadcrumbs", {
        tab: this.currentTab,
        status: this.currentSectionValid,
        jobStatus: this.currentJobStatus,
        allQuestionsValid: this.isAllQuestionsValid(),
        showAll: this.currentJobStatus == "PUBLISHED", //this.job_guid != null,
      });
    },
    ...mapActions("process", {
      updateJobStatus: "updateJobStatus",
    }),
    setJobToCreated: async function () {
      try {
        await this.updateJobStatus({
          job_guid: this.job_guid,
          status: "PUBLISHED",
        });
        this.currentJobStatus = "PUBLISHED";
        this.$route.query.status = "PUBLISHED";
        this.setBreadCrumbsVisibility();
        this.currentTab = "manageJobPostings";
      } catch (error) {
        this._snackbarError("Kunde inte skapa tjänst");
        console.log(error);
      }
    },
    goToCreatedJob: function () {
      this.$router.push({ name: "Job", params: { id: this.job_guid } });
    },
    editCreatedJob: function () {
      this.currentTab = "create";
    },
    updateSummary: function (question) {
      if (question.answer != null || question.answer_text != null) {
        if (question.id == 5) {
          this.jobSummary.salary.value = question.answer;
        } else if (question.id == 6) {
          this.jobSummary.place.value = question.answer
            .map((e) => e.name)
            .join(", ");
        } else if (question.id == 7) {
          var arr = question.answerAlternatives;
          var brr = question.answer;
          var res = arr.filter((f) => brr.includes(f.val));
          this.jobSummary.workHours.value = res.map((e) => e.text).join(" & ");
        } else if (question.id == 29) {
          this.jobSummary.title.value = question.answer_text;
        }
      }
    },
    updateJob: async function () {
      try {
        this.loadingMessage = "Sparar ändringar...";
        this.loading = true;
        await store.dispatch("jobQuestions/updateJob", {
          questions: this.groupedQuestions,
          job_guid: this.job_guid,
        });
        this.loading = false;
        this.changesPending = false;
        await store.dispatch("jobs/jobs");
        //this.$router.push({ name: "Job", params: { id: this.job_guid } });
      } catch (e) {
        console.log(e);
        this._snackbarError(
          "Fel vid sparande av tjänst"
        );
        console.log("Error saving job");
        this.loading = false;
      }
    },
    createJob: async function () {
      try {
        this.loadingMessage = "Skapar jobb...";
        this.loading = true;
        this.createdJobGuid = await store.dispatch(
          "jobQuestions/createNewJob",
          this.groupedQuestions
        );
        this.loading = false;
        this.changesPending = false;
        //this._snackbarSuccess("Tjänst skapad");
        this.$router.push({
          name: "EditJob",
          params: {
            job_guid: this.createdJobGuid,
            startTab: "profile",
            jobStatus: "DRAFT",
          },
          query: { status: "DRAFT" },
        });
        // this.$router.push({
        //   name: "EditJob",
        //   params: {
        //     job_guid: this.createdJobGuid,
        //     startTab: "created",
        //   },
        // });
        // router.push({ name: "Processes" });
      } catch (e) {
        console.log(e);
        this._snackbarError("Fel vid skapande av tjänst");
        console.log("Error creating job");
        this.loading = false;
      }
    },
    switchTab: function (tab) {
      this.currentTab = tab;
    },
    nextStep: async function () {
      if (this.currentTab == "create" && this.job_guid == null) {
        await this.createJob();
        return;
      }
      await this.updateJob();
      this.$vuetify.goTo(0, {
        duration: 200,
        offset: 0,
        easing: "easeOutQuad",
      });
      try {
        this.currentTab =
          this.breadcrumbs[
            this.breadcrumbs.findIndex((element) => {
              return element.tab == this.currentTab;
            }) + 1
          ].tab;
      } catch (e) {
        // this.currentTab = "confirmCreateJob";
      }
    },
  },
};
</script>
