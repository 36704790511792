<template>
  <div class="page-wrapper mx-auto d-flex flex-column page-normal">
    <PageHeader :title="pageInfo.title" :showDivider="false"></PageHeader>
    <PageContent :loading="false" :loading-message="'loadingMessage'">
      <job-list :title="'Utkast'" :jobs="jobs"></job-list>
    </PageContent>
  </div>
</template>
<script>
import GeneralSettings from "@/components/templates/Settings/GeneralSettings.vue";
import ThickDivider from "@/components/atom/line/ThickDivider";

import JobPostingSettings from "@/components/templates/Settings/JobPostingSettings.vue";
import FlatToggleButtonList from "../../components/basic/lists/FlatToggleButtonList";
import Page from "../../components/layout/Page";
import PageHeader from "../../components/layout/PageHeader.vue";
import PageContent from "../../components/layout/PageContent.vue";
import JobPublishingList from "../../components/organism/JobPublishingList.vue";
import ManageJobPostings from "../../components/templates/createjob/ManageJobPostings.vue";
import JobList from "../../components/organism/JobDraftsList.vue";

export default {
  components: {
    FlatToggleButtonList,
    Page,
    GeneralSettings,
    JobPostingSettings,
    ThickDivider,
    JobPublishingList,
    ManageJobPostings,
    PageHeader,
    PageContent,
    JobList,
  },
  data() {
    return {
      pageInfo: {
        title: "Processer",
        loading: false,
        pageSize: "normal",
      },
      items: ["Generellt", "Annonsering"],
      settings: ["GeneralSettings", "JobPostingSettings"],
      navigation: 0,
      jobs: [
        {
          responsible: "demo_hotell",
          status: "DRAFT",
          title: "Creative Producer",
          department: "Creative",
          city: [
            {
              id: "ChIJywtkGTF2X0YRZnedZ9MnDag",
              place_id: "ChIJywtkGTF2X0YRZnedZ9MnDag",
              description: "Stockholm, Sweden",
              name: "Stockholm",
            },
          ],
          application_period_start: null,
          application_period_end: "2021-09-21T00:00:00.000Z",
          job_guid: "4ce3bc45-08dc-11ec-a366-42010a59010e",
          created_date: "2021-08-29",
          statusText: "Utkast",
          statusColor: "grey",
          cityText: "Stockholm",
        },
        {
          responsible: "demo_hotell",
          status: "DRAFT",
          title: "asdasd",
          department: "asdasd",
          city: [{ id: 1, name: "Hela Sverige", description: "Hela Sverige" }],
          application_period_start: null,
          application_period_end: "2021-09-13T00:00:00.000Z",
          job_guid: "9c45f943-0f29-11ec-a366-42010a59010e",
          created_date: "2021-09-06",
          statusText: "Utkast",
          statusColor: "grey",
          cityText: "Hela Sverige",
        },
        {
          responsible: "demo_hotell",
          status: "DRAFT",
          title: "Test",
          department: "Test",
          city: [{ id: 1, name: "Hela Sverige", description: "Hela Sverige" }],
          application_period_start: null,
          application_period_end: "2021-09-29T00:00:00.000Z",
          job_guid: "03269c14-0f2d-11ec-a366-42010a59010e",
          created_date: "2021-09-06",
          statusText: "Utkast",
          statusColor: "grey",
          cityText: "Hela Sverige",
        },
        {
          responsible: "demo_hotell",
          status: "DRAFT",
          title: "asdas",
          department: "asdas",
          city: [],
          application_period_start: null,
          application_period_end: "2021-09-14T00:00:00.000Z",
          job_guid: "c5c26749-0f31-11ec-a366-42010a59010e",
          created_date: "2021-09-06",
          statusText: "Utkast",
          statusColor: "grey",
          cityText: "",
        },
        {
          responsible: "demo_hotell",
          status: "DRAFT",
          title: "asdask",
          department: "avdelning",
          city: [],
          application_period_start: null,
          application_period_end: "2021-09-13T00:00:00.000Z",
          job_guid: "21bf7baf-0f33-11ec-a366-42010a59010e",
          created_date: "2021-09-06",
          statusText: "Utkast",
          statusColor: "grey",
          cityText: "",
        },
        {
          responsible: "demo_hotell",
          status: "DRAFT",
          title: "asdask",
          department: "avdelning",
          city: [{ id: 1, name: "Hela Sverige", description: "Hela Sverige" }],
          application_period_start: null,
          application_period_end: "2021-09-13T00:00:00.000Z",
          job_guid: "9db55cc9-0f3b-11ec-a366-42010a59010e",
          created_date: "2021-09-06",
          statusText: "Utkast",
          statusColor: "grey",
          cityText: "Hela Sverige",
        },
        {
          responsible: "demo_hotell",
          status: "DRAFT",
          title: "Test",
          department: "test",
          city: [{ id: 1, name: "Hela Sverige", description: "Hela Sverige" }],
          application_period_start: null,
          application_period_end: "2021-09-21T00:00:00.000Z",
          job_guid: "35a07277-13c1-11ec-a366-42010a59010e",
          created_date: "2021-09-12",
          statusText: "Utkast",
          statusColor: "grey",
          cityText: "Hela Sverige",
        },
      ],
    };
  },
};
</script>