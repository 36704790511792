module.exports = {
  applications: null,
  latestApplications: null,
  status: "initialize",
  applicantsStatusDefinition: {
    APPLIED: { statusText: "Ansökt", statusColor: "open" },
    INVITED: { statusText: "Inbjuden", statusColor: "ongoing" },
    BOOKED: { statusText: "Bokat", statusColor: "ongoing" },
    COMPLETED: { statusText: "Haft intervju", statusColor: "ongoing" },
    REJECTED: { statusText: "Avböjt", statusColor: "closed" },
    HIRED: { statusText: "Anställd!", statusColor: "finished" },
  },
};
