var {
  nativeLogin,
  resetPassword,
  changePassword,
  getUserInfo,
  createUserPost,
} = require("../../requests/global");

const createUser = async (context, data) => {
  let newUser = await createUserPost({ body: data });
  context.dispatch("token", newUser.token);
  return;
};

const isLoggedIn = (context, data) => {
  context.commit("isLoggedIn", data);
};

const getUserInformation = async (context, data) => {
  context.commit("status", "pending");
  let userInformation = await getUserInfo({});
  context.commit("userInformation", userInformation);
  context.commit("companyInformation", userInformation.company);
  context.commit("companyPermissions", userInformation.company.account.permissions);
  context.commit("status", "success");
};

const getCompanyInformation = (context, data) => {
  context.dispatch("getUserInformation", null);
};

const verify = (context, data) => {
  return context.state.token != null ? true : false;
};

const load = (context, data) => {
  var token = $cookies.get("token");
  if (token != null) context.dispatch("token", token);
  return;
};

const token = (context, token) => {
  context.commit("token", token);
  if (token != null) {
    $cookies.set(
      "token",
      token,
      context.state.tokenExpirationTime,
      null,
      null,
      false
    );
    context.dispatch("isLoggedIn", true);
  }
};

const login = async (context, data) => {
  var res = await nativeLogin({
    body: {
      username: data.username,
      email: data.username,
      password: data.password,
    },
  });
  if (res.token != null) {
    context.dispatch("token", res.token);
  }
  return;
};

const logout = async (context, data) => {
  $cookies.remove("token");
  window.location.reload();
  return;
};

const passwordReset = async (context, data) => {
  var reset = await resetPassword({ body: { action: "RESET", email: data } });
};

const updatePassword = async (context, data) => {
  var change = await changePassword({
    body: {
      action: "CHANGE",
      token: data.token,
      newPassword: data.newPassword,
      currentPassword: data.currentPassword,
    },
  });
};

export default {
  getUserInformation: getUserInformation,
  getCompanyInformation: getCompanyInformation,
  isLoggedIn: isLoggedIn,
  token: token,
  load: load,
  verify: verify,
  login: login,
  logout: logout,
  passwordReset,
  updatePassword,
  createUser,
};
