<template>
  <div class="mx-auto page-normal">
    <PageHeader :title="page.title"></PageHeader>
    <PageActions :alert-bar="alertBar">
      <ContinueBar
        :message="page.continueMessage"
        :btn-message="page.continueBtnMessage"
      ></ContinueBar>
    </PageActions>
    <PageContent :loading="loading" :loading-message="loadingMessage">
      <!-- {{upcomingInterviews}} -->

      <v-row>
        <v-col
          v-if="upcomingInterviews != null && upcomingInterviews.length > 0"
          cols="12"
          md="6"
        >
          <SimpleList
            :items="upcomingInterviews"
            prepend-type="calendar"
            :key-name="'job_guid'"
            :item-title="'name'"
            :item-subtitle="'title'"
            :item-subtitle2="'description'"
            :item-action-text="'start'"
            :item-action-avatar-color="'ongoing'"
            :title="'Kommande intervjuer'"
            @listItemClick="openProcess"
          />
        </v-col>
        <v-col
          v-if="latestApplications != null && latestApplications.length > 0"
          cols="12"
          md="6"
        >
          <SimpleList
            :items="latestApplications"
            prepend-type="briteScore"
            :key-name="'application_guid'"
            :item-title="'name'"
            :item-subtitle="'jobTitle'"
            :item-subtitle2="''"
            :item-action-text="'applicationDate'"
            :item-action-avatar-color="'statusColor'"
            :title="'Senaste ansökningarna'"
            @listItemClick="openCandidate"
          >
          </SimpleList>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <ActionCard
            :type="'DEFAULT'"
            :title="jobs.length + ' processer'"
            :action-text="'Se processer'"
            @clicked="$router.push('/p')"
          />
        </v-col>
      </v-row>
    </PageContent>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

import filter from "../utils/filter";

import alertBarMixin from "../mixins/alertBar";
import snackbarMixin from "../mixins/snackbar";
import basicMixin from "../mixins/basic";
import handleLoad from "../mixins/handleLoad";

export default {
  mixins: [basicMixin, snackbarMixin, alertBarMixin, handleLoad],
  data: () => {
    return {
      page: {
        key: "jobs",
        data: "jobs",
        get: "jobs",
        title: "Översikt",
        cardTitle: "Processer",
        continueMessage: "Svar ändrade",
        continueBtnMessage: "Spara",
        missingAnswersMessage: "Svara på alla frågor för att gå vidare",
        missingAnswersBtnMessage: "Visa",
        showOnlyActiveJobsText: "Visa endast aktiva processer",
      },
      filter: {
        active: true,
      },
      search: "",
      search_table: "",
      jobs: [],
      promtSaveOnChanges: false,
      showMissing: false,
      validateTabs: true,
      currentSectionValid: false,
      watchQuestions: false,
      loading: false,
      loadingMessage: null,
    };
  },
  computed: {
    ...mapState("jobs", [
      "status",
      "breadcrumbs",
      "headers",
      "activeJobStatus",
      "upcomingInterviews",
    ]),
    ...mapState("applications", ["latestApplications"]),
    filteredJobs: function () {
      return filter.jobList({
        list: this.jobs,
        filter: this.filter,
        activeJobStatus: this.activeJobStatus,
      });
    },
  },
  watch: {
    storeData: function () {
      this.jobs = this.storeData;
    },
  },
  methods: {
    ...mapActions("candidateCard", ["setCandidate"]),
    openCandidate: function (application) {
      this.setCandidate(application);
      this.$router.push({
        name: "Candidate",
        params: {
          application_guid: application.guid,
          job_guid: application.job_guid,
          currentView: "selectCandidates",
        },
      });
    },
    openProcess(job) {
      this.$router.push({ name: "Job", params: { id: job.job_guid } });
    },
    searchUpdate(val) {
      // console.log(val);
      var self = this;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(function () {
        self.search_table = val;
      }, this.debounceDelay);
    },
  },
  mounted: function () {},
};
</script>
