<template>
  <v-row class="fullscreen-bg ma-0" justify="center" align="center">
    <v-col cols="12" sm="8" md="6" lg="4" xl="3">
      <img
        class="brite-logo-white mx-auto d-block mb-8"
        src="https://storage.googleapis.com/brite-resources/logo/Brite_logo_white%404x.png"
      />
      <Card :title="'Skapa konto'">
        <v-container>
          <v-row
            ><v-col cols="12">
              <p class="mx-0">
                Du skapar nu ett gratiskonto där du får genomföra en hel
                rekrytering utan kostnad. Inga kontouppgifter behövs och ditt
                konto uppgraderas inte automatiskt.
              </p>
              <v-form ref="create-account-form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="firstName"
                  required
                  :rules="[rules.required, rules.min]"
                  label="Förnamn"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  :rules="[rules.required, rules.min]"
                  label="Efternamn"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  :rules="rules.emailRules"
                  name="brite-employee-username"
                  required
                  label="Email"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  color="primary"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  :rules="[rules.required, rules.password]"
                  :type="showPassword ? 'text' : 'password'"
                  name="brite-employee-password"
                  label="Lösenord"
                  hint="Minst 8 tecken, liten bokstav, stor bokstav och en siffra"
                  required
                  loading
                  solo
                  @click:append="showPassword = !showPassword"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      :value="passwordProgress"
                      :color="passwordProgressColor"
                      absolute
                      height="7"
                    ></v-progress-linear>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="passwordConfirm"
                  :rules="[rules.passwordMatch]"
                  required
                  label="Bekräfta lösenord"
                  :type="showPassword ? 'text' : 'password'"
                  solo
                ></v-text-field>
                <div class="d-flex justify-center">
                  <Button
                    v-if="showCreateAccountBtn"
                    :disabled="!valid"
                    :type="'continue'"
                    @clicked="createAccount"
                    >Skapa konto</Button
                  >
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </Card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import basicMixin from "../mixins/basic";
import snackbarMixin from "../mixins/snackbar";
import store from "../store";

export default {
  mixins: [basicMixin, snackbarMixin],
  props: {
    source: String,
  },
  data() {
    return {
      showCreateAccountBtn: true,
      valid: false,
      subscription: "BASIC",
      username: null,
      password: "",
      firstName: "",
      lastName: "",
      passwordConfirm: "",
      showPassword: false,
      passwordLength: 0,
      validSubscriptionTypes: ["BASIC", "PRO", "PREMIUM"],
      email: "",
      rules: {
        required: (value) => !!value || "Obligatorisk",
        min: (value) => value.length > 0 || "Obligatorisk",
        password: (v) =>
          this.passwordRegex.test(v) ||
          "Minst 8 tecken, liten bokstav, stor bokstav och en siffra",
        passwordMatch: (v) => v == this.password || "Lösenorden matchar inte",
        emailRules: [
          (v) => !!v || "",
          (v) => /.+@.+\../.test(v) || "Ej godkänd e-post",
        ],
      },
      passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      scalePage: false,
      loading: false,
      imgFix: false,
      drawer: null,
      errorMessage: null,
      nextPath: "c",
    };
  },
  computed: {
    passwordProgress() {
      return Math.min(100, this.password.length * 10);
    },
    passwordProgressColor() {
      if (this.passwordRegex.test(this.password)) {
        return ["#D94032", "#F2AF5C", "#038C8C"][
          Math.floor(this.passwordProgress / 40)
        ];
      }
      return "#D94032";
    },
  },
  created() {
    this.email = this.$route.query.email;
    if (this.validSubscriptionTypes.includes(this.$route.query.subscription)) {
      this.subscription = this.$route.query.subscription;
    }
  },
  methods: {
    ...mapActions("user", ["createUser"]),
    createAccount: async function () {
      try {
        this.showCreateAccountBtn = false;
        if (this.validate()) {
          await this.createUser({
            firstName: this.firstName,
            lastName: this.lastName,
            username: this.email.toLowerCase(),
            email: this.email.toLowerCase(),
            password: this.password,
            companyName: this.email.toLowerCase(),
            demo: false,
            isAdmin: true,
            subscription: "BASIC",
          });
          this.$router.push({ path: "/" });
        }
      } catch (e) {
        this.showCreateAccountBtn = true;
      }
    },
    validate: function () {
      return this.$refs["create-account-form"].validate();
    },
    login: async function (e) {
      try {
        this.errorMessage = null;
        this.loading = true;
        e.preventDefault();
        await store.dispatch("user/login", {
          username: this.username,
          password: this.password,
        });
        this.loading = false;
        this.scalePage = true;
      } catch (e) {
        this.errorMessage = e;
        if (e.response) {
          if (e.response.status == 401) {
            this.errorMessage = "Felaktigt användarnamn eller lösenord!";
          }
        }
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>
