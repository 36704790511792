<template>
  <basic-info-sheet :color="color">
    <v-row>
      <v-col cols="12" md="4" class="d-flex justify-start justify-md-center">
        <v-img class="align-self-center" v-if="imgUrl != null" contain height="100" :src="imgUrl"></v-img>
        <v-icon class="align-self-center" v-else :color="iconColor" x-large>{{ icon }}</v-icon>
      </v-col>
      <v-col class="my-2" cols="12" md="7"><slot>Text</slot></v-col>
    </v-row>
  </basic-info-sheet>
</template>
<script>
import BasicInfoSheet from "../../atom/sheet/BasicInfoSheet";
export default {
  components: { BasicInfoSheet },
  props: {
    color: {
      type: String,
      default: "white",
    },
    imgUrl: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>