const groupedQuestions = (state, groupedQuestions) => {
  state.groupedQuestions = groupedQuestions;
};

const status = (state, status) => {
  state.status = status;
};

export default {
  groupedQuestions,
  status,
};
