import store from "../store";

import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import ContinueBar from "../components/basic/ContinueBar";
import AlertBar from "../components/basic/AlertBar";

import QBase from "../components/q/QBase";
import QSingle from "../components/q/QSingle";
import QMulti from "../components/q/QMulti";
import QSlide from "../components/q/QSlide";
import QCities from "../components/q/QCities";
import QTextShort from "../components/q/QTextShort";
import QNumberShort from "../components/q/QNumberShort";
import QDatepicker from "../components/q/QDatepicker";
import QText from "../components/q/QText";
import QPicture from "../components/q/QPicture";
import QJobs from "../components/q/QJobs";

export default {
  components: {
    PageHeader,
    PageContent,
    PageActions,
    QBase,
    QSingle,
    QMulti,
    QSlide,
    QCities,
    QTextShort,
    QNumberShort,
    QText,
    QJobs,
    QDatepicker,
    QPicture,
    ContinueBar,
    AlertBar,
  },
  data: () => {
    return {
      groupedQuestions: [],
      changesPending: false,
      currentTab: null,
      alertBar: {
        visible: false,
        message: "null",
        btnMessage: "Snull",
      },
    };
  },
  computed: {},
  beforeMount: function () {
    this.handleLoad(this.status, this.groupedQuestions, this.questionsKey);
  },
  watch: {
    status: function (status) {
      this.handleLoad(this.status, this.groupedQuestions, this.questionsKey);
    },
    currentTab: function () {
      this.checkQuestions();
    },
    groupedQuestions: {
      handler: function () {
        this.checkQuestions();
      },
      deep: true,
    },
  },
  methods: {
    checkQuestions: function () {
      try {
        if (this.currentTab == null && this.groupedQuestions.length > 0) {
          this.currentTab = this.groupedQuestions[0].tab;
        }
        this.changesPending = false;
        this.groupedQuestions.forEach((tab, index) => {
          if (tab.tab == this.currentTab) {
            var sectionValid = true;
            for (var i = 0; i < tab.questions.length; i++) {
              if (
                JSON.stringify(tab.questions[i].answer) !=
                JSON.stringify(
                  store.state[this.page.key].groupedQuestions[index].questions[
                    i
                  ].answer
                )
              ) {
                this.changesPending = true;
                console.log(tab.questions[i].answer);
                console.log(store.state[this.page.key].groupedQuestions[index].questions[i].answer);
              } else if (
                JSON.stringify(tab.questions[i].answer_text) !=
                JSON.stringify(
                  store.state[this.page.key].groupedQuestions[index].questions[
                    i
                  ].answer_text
                )
              ) {
                this.changesPending = true;
              }
              if (tab.questions[i].validAnswer != true) {
                sectionValid = false;
              }
            }
            tab.sectionValid = sectionValid;
            this.currentSectionValid = sectionValid;
            if (this.useSummary) {
              this.updateSummary();
            }
          }
        });
      } catch (e) {
        console.log(e);
        // this.changesPending = true;
      }
    },
    async saveQuestions() {
      try {
        this.loading = true;
        this.loadingMessage = "Sparar...";
        await store.dispatch("companyQuestions/save", this.groupedQuestions);
        this.loading = false;
        this._snackbarSuccess("Sparat");
        this.changesPending = false;
      } catch (e) {
        console.log(e);
        var snackbarMessage = "Kunde in spara, försök igen.";
        if (e == "notAuthorized") {
          snackbarMessage =
            "Du har inte behörighet att ändra arbetsgivarprofilen.";
        }
        this._snackbarError(snackbarMessage);
        console.log("Error saving");
        this.loading = false;
      }
    },
    answersChanged(data) {
      this.groupedQuestions.forEach((tab) => {
        if (this.currentTab == tab.tab) {
          var q = tab.questions;
          for (var i = 0; i < q.length; i++) {
            if (q[i].id == data.id) {
              q[i].answer = data.answer ? data.answer : null;
              q[i].answer_text = data.answer_text ? data.answer_text : null;
              q[i].validAnswer = data.validAnswer;
              break;
            }
          }
          tab.questions = [];
          tab.questions = q;
        }
      });
    },
    set: function (questions) {
      this.groupedQuestions = questions;
    },
    getFromStore: async function (storeQuestionsKey) {
      try {
        this.loading = true;
        this.showAlertBox = false;
        switch (storeQuestionsKey) {
          case "companyQuestions":
            await store.dispatch("companyQuestions/get", null);
            break;
          case "jobQuestions":
            await store.dispatch(storeQuestionsKey + "/get", null);
            break;
        }
        this.loading = false;
      } catch (e) {
        console.log("we got an error");
        this.alertBar.visible = true;
        this.alertBar.message = "Kunde inte ladda info";
        this.alertBar.btnMessage = "Ladda om sidan";
        this.loading = false;
        this.showAlertBox = true;
        console.log(e);
      }
    },
    handleLoad(status, list, storeQuestionsKey) {
      switch (status) {
        case "initialize":
          this.getFromStore(storeQuestionsKey);
          break;
        case "success":
          this.groupedQuestions = JSON.parse(
            JSON.stringify(store.state[storeQuestionsKey].groupedQuestions)
          );
          break;
      }
    },
  },
};
