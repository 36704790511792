<template>
  <div>
    <NotificationList
      :title="'Kommande intervjuer'"
      :text-if-empty="'Inga kommande intervjuer'"
      :text-field="'notificationText'"
      :sub-title-text-field="'start'"
      icon="today"
      :items="items"
      @clicked="openProcess"
    >
    </NotificationList>
  </div>
</template>
<script>
import NotificationList from "./NotificationList";

export default {
  components: {
    NotificationList,
  },
  props: {
    name: String,
    title: String,
    start: String,
    textField: String,
    interviews: Array,
    icon: String,
  },
  data: () => {
    return {
      items: [],
    };
  },
  created: function () {
    for (var i = 0; i < this.interviews.length; i++) {
      let item = JSON.parse(JSON.stringify(this.interviews[i]));
      item.notificationText = `${item.name} som ${item.title}`;
      this.items.push(item);
    }
  },
  methods: {
    openProcess(item) {
      this.$router.push({ name: "Job", params: { id: item.job_guid } });
    },
  },
};
</script>
