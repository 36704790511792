<template>
  <div>
    <v-container class="py-0">
      <v-row>
        <v-col>
          <div class="py-0 d-flex mt-0 mb-0">
            <div class="d-flex align-center pb-3">
              <v-icon
                v-if="back"
                :ripple="false"
                large
                color="black"
                @click="$router.go(-1)"
                >arrow_back</v-icon
              >
            </div>
            <heading3 class="mb-0">{{ title }}</heading3>
          </div>
        </v-col>
      </v-row>
      <ThickDivider v-if="showDivider"></ThickDivider>
    </v-container>
  </div>
</template>
<script>

import ThickDivider from "../../components/atom/line/ThickDivider";
import Heading3 from '../../components/atom/heading/Heading3'

export default {
  components: { ThickDivider, Heading3 },
  props: {
    title: String,
    back: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: true,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {};
  },
};
</script>
<style>
.make-it-stick {
  position: sticky;
  top: 0px;
}
</style>
