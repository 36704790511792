<template>
  <div class="mx-auto page-normal">
    <SectionHeader :title="page.title" :show-divider="false" ></SectionHeader>
    <PageActions
      :progress="null"
      :breadcrumbs="breadcrumbs"
      :breadcrumbs-stepper="true"
      :alert-bar="alertBar"
      :showDivider="false"
    >
      <ContinueBar
        :visible="changesPending"
        :message="page.continueMessage"
        :btn-message="page.continueBtnMessage"
        @continue="saveQuestions"
      ></ContinueBar>
    </PageActions>
    <PageContent :loading="loading" :loading-message="loadingMessage">
      <v-row
        v-if="
          companyInformation != null &&
          !companyInformation.account.status.setupComplete
        "
        ><v-col
          ><InfoSheet
            ><span class="white--text"
              >För att kunna skapa en tjänst måste du fylla i din
              företagsprofil</span
            ></InfoSheet
          ></v-col
        ></v-row
      >
      <div v-for="(cat) in groupedQuestions" :key="cat.tab">
        <v-row v-if="cat.tab == currentTab">
          <QBase
            v-for="question in cat.questions"
            :key="question.id"
            v-bind="question"
          >
            <template v-slot:question>
              <component
                :is="question.kind"
                v-bind="question"
                @changeAnswer="answersChanged"
              ></component>
            </template>
          </QBase>
        </v-row>
      </div>
    </PageContent>
  </div>
</template>
<script>
import store from "../store";
import { mapState } from "vuex";

import questionsMixin from "../mixins/companyQuestions";
import alertBarMixin from "../mixins/alertBar";
import snackbarMixin from "../mixins/snackbar";
import basicMixin from "../mixins/basic";

export default {
  mixins: [basicMixin, snackbarMixin, questionsMixin, alertBarMixin],
  props: {
    message: String,
  },
  data: () => {
    return {
      page: {
        key: "companyQuestions",
        title: "Företag",
        continueMessage: "Vill du spara dina ändringar?",
        continueBtnMessage: "Spara",
      },
      promtSaveOnChanges: true,
      validateTabs: false,
      loading: false,
      loadingMessage: null,
      canContinue: false,
      collapseOnScroll: true,
    };
  },
  watch: {},
  computed: {
    ...mapState("companyQuestions", ["status", "questionsKey", "breadcrumbs"]),
    ...mapState("user", ["companyInformation"]),
  },
  mounted: function () {},
  methods: {},
};
</script>
