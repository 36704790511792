<template>
  <div class="mx-auto page-normal">
    <PageHeader
      :back="true"
      :title="page.title"
      :showDivider="false"
    ></PageHeader>
    <PageActions :alert-bar="alertBar" :visible="true"></PageActions>
    <PageContent :loading="loading" :loading-message="page.loadingMessage">
      <div v-if="status == 'success'">
        <v-card class="mx-auto" tile>
          <v-card-title>
            <v-btn
              depressed
              height="80"
              width="80"
              class="black--text button-not-clickable mr-6"
              :color="candidate.matchColor"
            >
              <h2 class="ma-0 pa-0">{{ candidate.score }}</h2>
            </v-btn>
            <div>
              <h2 class="mr-6 my-0">{{ candidate.name }}</h2>
              <h3 class="mr-6 my-0">{{ job.title }}</h3>
              <div v-if="candidate.email != null">
                <heading-4 v-if="identifyCandidate">{{
                  candidate.email
                }}</heading-4>
              </div>
            </div>
            <v-spacer></v-spacer>
            <span
              v-if="candidate.invitedToTest == false && candidate.score == null"
              class="mr-2"
            >
              <v-tooltip
                open-on-hover
                bottom
                color="primary"
                style="width: 300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <Button
                      :type="'continue'"
                      @clicked="displayTestCandidateDialog = true"
                      >Testa kandidat</Button
                    >
                  </span>
                </template>
                <span v-html="testCandidateTooltipText"></span>
              </v-tooltip>
              <Dialog
                v-model="displayTestCandidateDialog"
                :title="'Testa kandidat'"
              >
                {{ testCandidateDialog[accountSubscription].text }}
                <template v-slot:actions>
                  <v-spacer></v-spacer>
                  <Button
                    text
                    class="text-none"
                    @clicked="displayTestCandidateDialog = false"
                    >Avbryt</Button
                  >
                  <Button
                    :type="'continue'"
                    @clicked="sendTestInviteToCandidate"
                    >Ja, testa kandidat</Button
                  >
                </template>
              </Dialog>
            </span>
            <v-btn
              v-if="
                candidate.testReportUrl != null &&
                candidate.testReportUrl.length > 0
              "
              class="text-none mr-2"
              color="white"
            >
              <a
                class="no-decoration"
                :href="candidate.testReportUrl"
                target="_blank"
              >
                Ladda ned testrapport <v-icon>download</v-icon></a
              >
            </v-btn>
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  v-bind="attrs"
                  class="text-none mr-2"
                  v-on="on"
                >
                  <v-icon>arrow_drop_down</v-icon>
                  {{ candidate.statusText }}
                  <v-list-item-avatar
                    size="24"
                    :color="candidate.statusColor"
                  ></v-list-item-avatar>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in changeDropdown"
                  :key="i"
                  @click="changeStatus(item.status)"
                >
                  <v-list-item-title>{{ item.statusText }}</v-list-item-title>
                  <v-list-item-avatar
                    size="24"
                    :color="item.statusColor"
                  ></v-list-item-avatar>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-icon
              v-if="candidate.starred"
              x-large
              color="primary"
              @click.stop="updateCandidate({ starred: false })"
              >star</v-icon
            >
            <v-icon
              v-else
              x-large
              color="grey"
              @click.stop="updateCandidate({ starred: true })"
              >star_outline</v-icon
            >
            <Dialog
              v-model="changeStatusDialog"
              :title="changeDialog[pendingStatus].title"
            >
              {{ changeDialog[pendingStatus].text }}
              <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="text-none"
                  @click="changeStatusDialog = false"
                  >Avbryt</v-btn
                >
                <Button
                  :type="changeDialog[pendingStatus].type"
                  @clicked="confirmStatusChange(pendingStatus)"
                  >{{ changeDialog[pendingStatus].btnText }}</Button
                >
              </template>
            </Dialog>
            <!--
          <v-select
            v-model="candidate.statusText"
            :items="['Inbjuden', 'Haft intervju', 'Anställd']"
            prepend-icon="map"
            menu-props="auto"
            hide-details
            label="Select"
            single-line
          ></v-select>
          <div class="d-inline">
            <v-list-item two-line>
              <v-list-item-avatar size="48" :color="candidate.statusColor"></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <h3 class="mt-0 font-weight-bold">{{candidate.statusText}}</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </div>-->
          </v-card-title>
          <v-card-text>
            <v-row v-if="candidate.score == null">
              <v-col v-if="candidate.invitedToTest">
                Denna kandidat genomför just nu testet. När kandidaten är klar
                kommer du se dennes testresultat här.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in scoreOverview" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td class="text-center">
                          <v-tooltip
                            v-if="!item.valid"
                            v-model="item.validTooltip"
                            color="primary"
                            top
                            nudge-bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon large color="grey lighten 3" v-on="on"
                                >search_off</v-icon
                              >
                            </template>
                            <span
                              >Kandidaten har inte angett denna
                              information</span
                            >
                          </v-tooltip>
                          <div v-else-if="item.icons">
                            <v-tooltip
                              v-if="!item.matchQuestionsValid"
                              v-model="item.validTooltip"
                              color="primary"
                              top
                              nudge-bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon large color="grey lighten 3" v-on="on"
                                  >search_off</v-icon
                                >
                              </template>
                              <span
                                >Kandidaten har inte svarat på alla
                                matchningsfrågor</span
                              >
                            </v-tooltip>

                            <v-progress-circular
                              :rotate="-90"
                              :size="40"
                              :width="4"
                              :value="item.percentage"
                              color="secondary"
                            >
                              {{ item.percentage }}
                            </v-progress-circular>
                          </div>
                          <v-rating
                            v-else
                            length="10"
                            :value="item.stars + 1"
                            background-color="grey lighten-4"
                            :color="item.color"
                            readonly
                          ></v-rating>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <!--  <v-col cols="12" md="4">
              {{job.applicants.findIndex(elem => elem.guid == candidate.guid)+1}}/{{job.applicants.length}}
              <v-icon
                v-if="candidate.match > page.fireThreshold"
                color="primary"
                x-large
              >local_fire_department</v-icon>
              <v-icon v-else color="primary" x-large>mdi-snowflake</v-icon>
              </v-col>-->
              <v-col v-if="candidate.email != null && !identifyCandidate" cols="12" class="py-0">
                <div style="width:200px">
                  <switch-button
                    v-model="identifyCandidate"
                    :label="'Visa email'"
                  ></switch-button>
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <v-textarea v-model="commentDraft" label="Kommentar" outlined />
                <v-btn
                  v-if="commentChanged"
                  rounded
                  color="primary"
                  class="text-none text-center float-right"
                  @click="updateCandidate({ comment: commentDraft })"
                  >Spara</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <MotivationComparison
          :candidate="candidate"
          :items="motivationQuestions"
        />
        <CandidateBasic :items="candidateBasicQuestions" />
      </div>
    </PageContent>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

//import snackbarMixin from "../mixins/snackbar";
//import basicMixin from "../mixins/basic";
import alertBar from "../mixins/alertBar";
import { getMotivationColor } from "../utils/misc";

import MotivationComparison from "../components/candidate/MotivationComparison";
import CandidateBasic from "../components/candidate/CandidateBasic";
import { candidate } from "../store/CandidateCard/state";
import { setApplicationStatus } from "../requests/global";
import PageHeader from "../components/layout/PageHeader.vue";
import PageActions from "../components/layout/PageActions.vue";
import PageContent from "../components/layout/PageContent.vue";
import Button from "../components/basic/Button.vue";
import basicMixin from "../mixins/basic";
import SwitchButton from "../components/atom/select/SwitchButton.vue";

export default {
  mixins: [alertBar, basicMixin],
  components: {
    MotivationComparison,
    CandidateBasic,
    PageHeader,
    PageActions,
    PageContent,
    SwitchButton,
  },
  props: {
    application_guid: String,
    job_guid: String,
  },
  data: function () {
    return {
      page: {
        title: "Kandidat",
        loadingMessage: "Laddar kandidat",
        fireThreshold: 59,
        changeStatusTitle: "Ändra status",
      },
      accountSubscription: "BAS",
      testCandidateTooltipText: `Tryck här för att testa denna kandidat med personlighets- och begåvningstest.<br/>
        Kandidaten gör vanligtvis testet inom 48 timmar. När kandidaten gjort testet kommer du se resultatet här.`,
      displayTestCandidateDialog: false,
      testCandidateDialog: {
        PREMIUM: {
          text: "Genom att gå vidare beställer du ett testresultat från denna kandidat. Vanligtvis har du testresultatet tillhanda inom 48 timmar. Med Brite Premium ingår 300 testresultat per månad. Därefter kostar varje levererat testresultat 400 kr styck och faktureras månadsvis.",
        },
        PRO: {
          text: "Genom att gå vidare beställer du ett testresultat från denna kandidat. Vanligtvis har du testresultatet tillhanda inom 48 timmar. Med Brite Unlimited kan du testa hur många kandidater som helst. Du betalar således inget extra för detta testresultat.",
        },
        BAS: {
          text: "Genom att gå vidare beställer du ett testresultat från denna kandidat. Vanligtvis har du testresultatet tillhanda inom 48 timmar. Med Brite Lite kostar varje levererat testresultat 400 kr styck och faktureras månadsvis.",
        },
      },
      identifyCandidate: false,
      candidateTestInviteStatus: "pending",
      commentDraft: null,
      pendingStatus: "HIRED",
      matchQuestionsValidTooltip: false,
      changeStatusDialog: false,
      applicantAllStatusDropdown: [
        {
          status: "INVITED",
          statusText: "Kalla på intervju",
          statusColor: "primary",
        },
        { status: "BOOKED", statusText: "book", statusColor: "primary" },
        {
          status: "COMPLETED",
          statusText: "Intervju genomförd",
          statusColor: "primary",
        },
        { status: "HIRED", statusText: "Anställ!", statusColor: "finished" },
        {
          status: "REJECTED",
          statusText: "Tacka nej",
          statusColor: "error",
        },
      ],
    };
  },
  watch: {
    status: function () {
      if (this.status == "failed") {
        this.handleAlertBar({
          visible: true,
          message:
            "Kunde inte ladda kandidat, är du säker på att länken är korrekt?",
          btnMessage: "Ladda om sidan",
        });
      }
    },
    candidate: {
      immediate: true,
      deep: true,
      handler() {
        this.commentDraft = this.candidate.employee_comment;
      },
    },
  },
  beforeMount: function () {
    this.handleLoad();
  },
  computed: {
    scoreOverview: function () {
      return this.kpiStars.filter((item) => !item.hide);
    },
    kpiStars: function () {
      return [
        {
          key: "briteScore",
          name: "Brites rekommendation",
          valid: this.candidate.score != null,
          hide: this.candidate.score == null,
          stars: this.candidate.score / parseFloat(10),
          color: this.candidate.matchColor,
        },
        {
          key: "gmaScore",
          name: "Generell begåvning",
          valid: this.candidate.gmaScore != null,
          hide: this.candidate.gmaScore == null,
          stars: this.candidate.gmaScore,
          color: "primary",
        },
        {
          key: "integrityScore",
          name: "Integritet",
          valid: this.candidate.integrityScore != null,
          hide: this.candidate.integrityScore == null,
          stars: this.candidate.integrityScore,
          color: "accent",
        },
        {
          key: "motivationScore",
          name: "Motivation",
          valid: this.candidate.motivationScore != null,
          hide: false,
          stars: this.candidate.motivationScore,
          color: "success",
        },
        {
          key: "matchStatus",
          type: "icon",
          name: "Matchar tjänsten",
          valid: true,
          hide: false,
          matchQuestionsValid: this.candidate.matchQuestionsValid,
          percentage: this.candidate.matchJobPercentage,
          status: this.candidate.matchJob,
          stars: false,
          icons: true,
          color: "success",
        },
      ];
    },
    commentChanged: function () {
      if (this.commentDraft == this.candidate.employee_comment) {
        return false;
      } else {
        return true;
      }
    },
    changeDialog: function () {
      return {
        HIRED: {
          title: `Vill du anställa ${this.candidate.name}?`,
          text: "Om du klickar 'Ja, anställ' så får kandidaten ett mail med information om anställningen.",
          type: "continue",
          btnText: "Ja, anställ",
        },
        REJECTED: {
          title: `Tack nej till ${this.candidate.name}?`,
          text: "Om du klickar 'Tacka nej' så kommer kandidaten notifieras och försvinner från listan över aktiva ansökningar",
          type: "error",
          btnText: "Tacka nej",
        },
      };
    },
    changeDropdown: function () {
      if (this.candidate.hasOwnProperty("status")) {
        var validChanges = this.applicationValidChangeStatus(
          this.candidate.status
        );
        const dropDownValues = this.applicantAllStatusDropdown.filter(
          (elem) => {
            var text = validChanges.findIndex((status) => {
              return status == elem.status;
            });
            return text > -1;
          }
        );
        return dropDownValues;
      }
    },
    ...mapGetters("process", ["applicationValidChangeStatus"]),
    ...mapState("candidateCard", [
      "status",
      "motivationQuestions",
      "candidateBasicQuestions",
      "candidate",
    ]),
    ...mapState("applications", ["applicantsStatusDefinition"]),
    ...mapState("process", ["job", "applicantValidStatusToChangeTo"]),
    loading: function () {
      return !(this.status == "success" || this.status == "failed");
    },
  },
  methods: {
    updateCandidate: async function ({ starred, comment }) {
      try {
        if (starred != null) {
          this.candidate.starred = starred;
        }
        if (comment != null) {
          this.candidate.employee_comment = comment;
        }
        await this.updateApplicant(this.candidate);
      } catch (e) {
        console.log(e);
        this._snackbarError("Kunde inte spara ändring");
      }
    },
    confirmStatusChange: async function (status) {
      try {
        this.changeStatusDialog = false;
        await this.setApplicantStatus({
          applicant: this.candidate,
          status: status,
        });
        this.candidate.status = status;
        this.candidate.statusText =
          this.applicantsStatusDefinition[status].statusText;
        this.candidate.statusColor =
          this.applicantsStatusDefinition[status].statusColor;
        await this.updateApplicant(this.candidate);
        console.log("Confirming status change");
      } catch (e) {
        console.log(e);
        this._snackbarError("Kunde inte uppdatera kandidatens status");
      }
    },
    changeStatus: function (status) {
      if (status == "INVITED") {
        this.$router.push({
          name: "Job",
          params: {
            id: this.job.job_guid,
            forceKey: "candidates",
            preChooseApplicationGuid: this.application_guid,
          },
        });
        return;
      }
      this.pendingStatus = status;
      console.log("correft clock");
      this.changeStatusDialog = true;
    },
    sendTestInviteToCandidate: async function () {
      try {
        await this.inviteToTest(this.candidate);
        this._snackbarSuccess("Inbjudan skickad!");
      } catch (e) {
        this._snackbarError("Kunde inte skicka inbjudan");
      }
    },
    ...mapActions("candidateCard", ["load"]),
    ...mapActions("process", [
      "updateApplicant",
      "setApplicantStatus",
      "inviteToTest",
    ]),
    handleLoad: function () {
      console.log(this.application_guid + " : " + this.job_guid);
      this.load({
        job_guid: this.job_guid,
        application_guid: this.application_guid,
      });
    },
  },
};
</script>
