import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import request from "./request";
import utils from "./utils/misc";
import process from "./store/Process";
import applications from "./store/Applications";
import jobs from "./store/Jobs";
import user from "./store/User";
import companyQuestions from "./store/Company";
import companyProfile from "./store/CompanyProfile";
import jobQuestions from "./store/JobQuestions";
import jobStatistics from "./store/Statistics";
import candidateCard from "./store/CandidateCard";
import snackbar from "./store/Snackbar";
import dataservice from "./store/Dataservice";

//import interceptor from './requests/interceptors'

Vue.use(Vuex);

var getDefaultState = () => {
  return {
    items: [],
    status: "empty",
  };
};

export default new Vuex.Store({
  modules: {
    process: process,
    jobs: jobs,
    user: user,
    applications: applications,
    companyQuestions: companyQuestions,
    companyProfile: companyProfile,
    jobQuestions: jobQuestions,
    snackbar: snackbar,
    candidateCard: candidateCard,
    jobStatistics: jobStatistics,
    dataservice: dataservice,
  },
  state: {
    org_name: null,
    isLoggedIn: null,
    token: null,
    encryptedAccessToken: "",
    encryptedSessionToken: "",
    job_status: "initialise",
    company_status: "initialise",
    create_status: "initialise",
    old_company: null,
    profile: null,
    create: [{ name: "test" }],
    processes: null,
    account: null,
  },
  mutations: {
    logout(state) {
      $cookies.remove("encrypted_access_token");
      $cookies.remove("encrypted_session_token");
      state.isLoggedIn = false;
      router.push({ path: "l" });
      location.reload();
    },
    isLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    encryptedAccessToken(state, token) {
      $cookies.set(
        "encrypted_access_token",
        token,
        48 * 60 * 60,
        null,
        null,
        false
      );
      state.encryptedAccessToken = token;
    },
    encryptedSessionToken(state, token) {
      $cookies.set(
        "encrypted_session_token",
        token,
        48 * 60 * 60,
        null,
        null,
        false
      );
      state.encryptedSessionToken = token;
    },
    company(state, company) {
      state.company = company;
    },
    profile(state, profile) {
      state.profile = profile;
    },
    create(state, create) {
      state.create = create;
    },
    account(state, account) {
      state.account = account;
    },
    jobs(state, jobs) {
      state.jobs = jobs;
    },
    job_status(state, job_status) {
      state.job_status = job_status;
    },
    company_status(state, company_status) {
      state.company_status = company_status;
    },
    create_status(state, create_status) {
      state.create_status = create_status;
    },
  },
  actions: {
    company(state, company) {
      return new Promise((resolve, reject) => {
        request.methods
          .saveCompany(company)
          .then((result) => {
            //console.log("we have updated company")
            state.commit("company", JSON.parse(JSON.stringify(company)));
            resolve();
          })
          .catch((err) => {
            //console.log("fail comapny update")
            reject();
          });
      });
    },
    jobs(state, jobs) {
      for (var i = 0; i < jobs.length; i++) {
        try {
          for (var k = 0; k < jobs[i].applicants.length; k++) {
            //if not contacted
            jobs[i].applicants[k].choosen = false;
            if (!jobs[i].applicants[k].hasOwnProperty("starred")) {
              jobs[i].applicants[k].starred = false;
            }
            jobs[i].applicants[k].matchColor = utils.getMatchColor(
              jobs[i].applicants[k].match
            );
          }
        } catch (e) {
          log.console(e);
        }
      }
      state.commit("jobs", JSON.parse(JSON.stringify(jobs)));
    },
    uploadUrl(state, url) {
      return new Promise((resolve, reject) => {
        request.methods
          .uploadUrl(url)
          .then((result) => {
            //console.log("we have updated company")
            //state.commit('company', JSON.parse(JSON.stringify(company)));
            resolve(result);
          })
          .catch((err) => {
            //console.log("fail comapny update")
            reject(err);
          });
      });
    },
    uploadFile(state, formData) {
      return new Promise((resolve, reject) => {
        request.methods
          .uploadFile(formData)
          .then((result) => {
            //state.commit('company', JSON.parse(JSON.stringify(company)));
            resolve(result);
          })
          .catch((err) => {
            //console.log("fail comapny update")
            reject(err);
          });
      });
    },
  },
  getters: {
    specific_job: (state) =>
      function (id) {
        return state.jobs.filter(function (itm) {
          //console.log(itm.id);
          return itm.link_id == id;
        });
      },
    company: function (state) {
      return state.company;
    },
    jobs: function (state) {
      return state.jobs;
    },
    create: function (state) {
      return state.create;
    },
  },
});

/*
jobb:
      [
        {
          "id": 1,
          "title": "Snickare",
          "ag_user_id": 1,
          "job_categories_id": 1,
          "salary_lower": 34000,
          "salaray_upper": 40000,
          "application_period_start": "2019-04-23",
          "application_period_end": "2019-09-21",
          "department": "Byggindustri",
          "cities": "Stockholm",
          "description": "Ronja",
          "link_id": "6cfb738b-d72f-11e9-af60-42010aa6004d",
          "applicants": [
            { id: 1, title: "Fancy Frog", match: "91", status: "Avbruten", date: "2019-08-03" },
            { id: 2, title: "Cool Koala", match: "88", status: "Kallad", date: "2019-09-03" },
            { id: 3, title: "Hot Horse", match: "74", status: "Kalla på intervju", date: "" },
            { id: 4, title: "Zippy Zebra", match: "63", status: "Kalla på intervju", date: "" },

          ]
        },
        {
          "id": 2,
          "title": "Designer",
          "ag_user_id": 1,
          "job_categories_id": 2,
          "salary_lower": 24000,
          "salaray_upper": 26000,
          "application_period_start": "2019-05-23",
          "application_period_end": "2019-10-21",
          "department": "Grafik & Design",
          "cities": "Linköping",
          "description": "Emil",
          "link_id": "ea04e9ae-d72f-11e9-af60-42010aa6004d",
          "applicants": [
            { id: 1, title: "Fancy Frog", match: "91", status: "Avbruten", date: "2019-08-03" },
            { id: 2, title: "Cool Koala", match: "88", status: "Kallad", date: "2019-09-03" },
            { id: 3, title: "Hot Horse", match: "85", status: "Kalla på intervju", date: "" },
            { id: 4, title: "Zippy Zebra", match: "84", status: "Kalla på intervju", date: "" },
            { id: 5, title: "Tantrum T-Rex", match: "35", status: "Se upp!", date: "" },

          ]
        }
      ],



    process_items: [
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%"
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%"
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: "8%"
      },

      {
        name: "Honeycomb",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%"
      },
      {
        name: "Donut",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%"
      },
      {
        name: "KitKat",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%"
      },
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%"
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%"
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%"
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: "16%"
      },
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%"
      },
      {
        name: "Jelly bean",
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: "0%"
      },
      {
        name: "Lollipop",
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: "2%"
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: "8%"
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: "16%"
      },
      {
        name: "Jelly bean",
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: "0%"
      },
      {
        name: "Lollipop",
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: "2%"
      },
      {
        name: "Honeycomb",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%"
      },
      {
        name: "Donut",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%"
      },
      {
        name: "KitKat",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%"
      }
    ]

*/
