<template>
  <div class="page-wrapper mx-auto d-flex flex-column" :class="['page-' + pageSize]">
    <PageHeader :title="title" :back="back"></PageHeader>
    <PageContent :loading="loading">
      <!-- <PageBanner><slot name="banner"></slot></PageBanner> -->
      <slot>This page has no content</slot></PageContent
    >
  </div>
</template>
<script>
import PageHeader from "./PageHeader";
import PageActions from "./PageActions";
import PageBanner from "./PageBanner";
import PageContent from "./PageContent";
export default {
  components: {
    PageHeader,
    PageContent,
    PageActions,
    PageBanner,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: String,
      default: "normal", // narrow, normal, wide, fullwidth
    },
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>
