<template>
  <v-btn
    :disabled="disabled"
    :color="dColor"
    class="text-none"
    :text="dText"
    @click="$emit('clicked')"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  components: {},
  props: {
    color: {
      type: String,
      default: "primary",
    },
    text: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      dColor: null,
      dText: null,
    };
  },
  watch: {
    type: {
      immediate: true,
      handler() {
        switch (this.type) {
          case "continue":
            this.dColor = "secondary";
            this.dText = false;
            break;
          case "goto":
            this.dColor = "secondary";
            this.dText = this.text ? true : false;
            break;
          case "confirm":
            this.dColor = "primary";
            this.dText = false;
            break;
          case "cancel":
            this.dColor = "error";
            this.dText = true;
            break;
          case "error":
            this.dColor = "error";
            this.dText = false;
            break;

          default:
            this.dColor = this.color;
            this.dText = this.text;
        }
      },
    },
  },
};
</script>
