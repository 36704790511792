const token = (state, token) => {
  state.token = token;
};

const isLoggedIn = (state, status) => {
  state.isLoggedIn = status;
};

const userInformation = (state, status) => {
  state.userInformation = status;
};

const status = (state, status) => {
  state.status = status;
};

const companyInformation = (state, status) => {
  state.companyInformation = status;
};
const companyPermissions = (state, status) => {
  state.companyPermissions = status;
};

export default {
  token,
  isLoggedIn,
  userInformation,
  status,
  companyInformation,
  companyPermissions
};
