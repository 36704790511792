<template>
  <div>
    <FileUpload :original-url="dAnswer" @update="handleUpdate" />

    <!--  <v-text-field v-model="dAnswer" outlined></v-text-field>
    <v-row align="center" justify="center">
      <div style="display:inline-block;padding:25px;" class="grey lighten-2">
        <v-img style="display:block;height:100%;width:200px;"  contain :src="dAnswer" aspect-ratio="1"  widht="200" max-width="200"></v-img>
        </div>
    </v-row> -->
  </div>
</template>

<script>
import FileUpload from "./FileUpload";

export default {
  components: {
    FileUpload,
  },
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer_text: String,
    answer: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
  },
  data() {
    return {
      dAnswer: this.answer_text,
      dValidAnswer: false,
      typing: false,
      typingDone: false,
    };
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer_text: this.dAnswer,
          validAnswer: this.dValidAnswer,
        });
      },
      deep: true,
    },
  },
  mounted: function () {
    //console.log("Answer is: " + this.answer)
    //this.dAnswer = JSON.parse(this.answer);
  },
  methods: {
    handleUpdate(link) {
      this.dAnswer = link;
    },
  },
};
</script>
<style>
.v-responsive.v-image {
  display: block;
}
</style>
