/* Example arrays
    var sortOrder = [
    { tab: "aa", sortKey: 2 },
    { tab: "cc", sortKey: 1 },
  ];

  var original = [
    { tab: "aa", questions: "a" },
    { tab: "aa", questions: "b" },
    { tab: "cc", questions: "c" },
  ];
  */

const groupQuestions = (original, sortOrder) => {
  return sortByArray(groupObj(original, "tab"), sortOrder, "tab");
};

const unGroupQuestions = (grouped) => {
  return unGroup(grouped, "tab");
};

const groupObj = function (data, key) {
  var grouped = [];
  data.forEach((element) => {
    try {
    var ind = grouped.findIndex(
      (obj) => obj.tab.toLowerCase() == element.tab.toLowerCase()
    );
    if (ind == -1) {
      ind = grouped.length;
      grouped.push({ tab: element.tab, questions: [] });
    }
    grouped[ind].questions.push(element);
  }
  catch(e) {
    console.log("Had to skip parsing question");
    console.log(element);
    console.log(e);
  }
  });
  // console.log("grouped")
  // console.log(grouped)
  return grouped;
};

const sortByArray = function (list, order, tab) {
  // console.log("list")
  // console.log(list)
  // console.log(order)
  // console.log("order")
  var obj = JSON.parse(JSON.stringify(list));
  obj.forEach((element) => {
    element.sortKey = order.find((sort) => sort.tab == element.tab).sortKey;
  });
  var sorted = obj.sort((a, b) =>
    a.sortKey > b.sortKey ? 1 : b.sortKey > a.sortKey ? -1 : 0
  );
  // console.log("we passed sorting step")
  return sorted;
};

const unGroup = function (grouped, tab) {
  var unGrouped = [];
  grouped.forEach((element) => {
    unGrouped = unGrouped.concat(element.questions);
  });
  return unGrouped;
};

module.exports = {
  groupQuestions: groupQuestions,
  unGroupQuestions: unGroupQuestions,
};
