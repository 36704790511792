const applicants = (state, applicants) => {
  state.applicants = JSON.parse(JSON.stringify(applicants));
};
const job_guid = (state, job_guid) => {
  state.job_guid = job_guid;
};
const job = (state, job) => {
  state.job = JSON.parse(JSON.stringify(job));
};
const choosenInterviewTimes = (state, choosenInterviewTimes) => {
  state.choosenInterviewTimes = JSON.parse(
    JSON.stringify(choosenInterviewTimes)
  );
};
const bookedInterviewTimes = (state, bookedInterviewTimes) => {
  state.bookedInterviewTimes = JSON.parse(JSON.stringify(bookedInterviewTimes));
};

const completedInterviewTimes = (state, completedInterviewTimes) => {
  state.completedInterviewTimes = JSON.parse(
    JSON.stringify(completedInterviewTimes)
  );
};

const contactedApplicants = (state, contactedApplicants) => {
  state.contactedApplicants = JSON.parse(JSON.stringify(contactedApplicants));
};
const choosenApplicants = (state, choosenApplicants) => {
  state.choosenApplicants = JSON.parse(JSON.stringify(choosenApplicants));
};
const valid = (state, status) => {
  state.valid = status;
};
const validChoosenApplicants = (state, status) => {
  state.validChoosenApplicants = status;
};
const validChoosenInterviewTimes = (state, status) => {
  state.validChoosenInterviewTimes = status;
};
const interviewTimesLeftToChoose = (state, amount) => {
  state.interviewTimesLeftToChoose = amount;
};

const breadcrumbs = (state, breadcrumbs) => {
  state.breadcrumbs = breadcrumbs;
};

const jobPostingSettings = (state, jobPostingSettings) => {
  state.jobPostingSettings = jobPostingSettings;
};

const jobPostingSettingsStatus = (state, jobPostingSettingsStatus) => {
  state.jobPostingSettingsStatus = jobPostingSettingsStatus;
};


export default {
  jobPostingSettingsStatus,
  jobPostingSettings,
  applicants,
  contactedApplicants,
  choosenInterviewTimes,
  bookedInterviewTimes,
  completedInterviewTimes,
  choosenApplicants,
  valid,
  validChoosenApplicants,
  validChoosenInterviewTimes,
  interviewTimesLeftToChoose,
  breadcrumbs,
  job_guid,
  job,
};
