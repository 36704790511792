<template>
  <v-app>
    <!-- <Sidebar /> -->
    <v-content class="mt-0">
      <transition appear mode="out-in" name="fade">
        <keep-alive include="Processes">
          <router-view />
        </keep-alive>
      </transition>
      <!-- <BottomBar /> -->
    </v-content>
  </v-app>
</template>

<script>
import Sidebar from "../components/nav/Sidebar.vue";
import BottomBar from "../components/nav/BottomBar.vue";

export default {
  components: {
    Sidebar,
    BottomBar,
  },
};
</script>
