<template>
  <div class="page-wrapper mx-auto page-normal">
    <PageHeader :title="page.title" ></PageHeader>
    <PageContent :loading="loading" >
      <v-row>
        <v-col cols="12" md="8">
          <div class="support-message">
            <span
              >Innan du kontaktar supporten, testa att få svar på din fråga i
              vår FAQ.</span
            >
          </div>
          <div class="support-link">
            <a href="https://brite.jobs/help" target="_blank">FAQ</a>
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <div class="support-message">
            <span
              >Vid supportärenden, kontakta vår kundtjänst nedan så svarar vi så
              snart vi kan, senast inom 24 timmar.</span
            >
          </div>
          <div class="support-link">
            <a href="mailto:contact@brite.jobs" target="_blank"
              >contact@brite.jobs</a
            >
          </div>
          <!--  <div class="support-link">
            <a href="https://genius.com/discussions/94333-The-moon-landings-are-fake" target="_blank">070 123 456 78</a>
         </div> -->
        </v-col>
        <!--      <v-col cols="12" md="8">
        <div class="support-message">
           <span>Vid akuta ärenden, ring vår akutsupport på:</span>
           </div>
         <div class="support-link">
            <a href="mailto:contact@digifut.se" target="_blank">112</a>
         </div>
     </v-col> -->
      </v-row>
    </PageContent>
  </div>
</template>

<script>
import basicMixin from "../mixins/basic";

export default {
  mixins: [basicMixin],
  data: function () {
    return {
      loading: false,
      page: {
        key: "support",
        title: "Support",
      },
    };
  },
  components: {},
  methods: {},
};
</script>
<style scoped></style>
