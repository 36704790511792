
//Atom

// Line

import ThickDivider from '../components/atom/line/ThickDivider';
import ProminentListItem from '../components/atom/listitem/ProminentListItem';


import ProminentList from '../components/molecule/list/ProminentList';

import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import SectionHeader from "../components/layout/SectionHeader";


import InfoBar from "../components/basic/InfoBar";
import Dialog from "../components/basic/Dialog";
import ContinueBar from "../components/basic/ContinueBar";
import AlertBar from "../components/basic/AlertBar";
import SimpleList from "../components/basic/SimpleList";
import ActionCard from "../components/basic/ActionCard";

import Spinner from "../components/misc/Spinner";
import AddToCalendar from "../components/misc/AddToCalendar";

import Button from "../components/basic/Button";
import FlatToggleButton from "../components/basic/buttons/FlatToggleButton";
import BigButton from "../components/basic/BigButton";
import InfoSheet from "../components/basic/InfoSheet";
import Card from "../components/basic/Card";

import FlatToggleButtonList from '../components/basic/lists/FlatToggleButtonList';

export default {
  components: {
    ThickDivider,
    SectionHeader,
    PageHeader,
    PageActions,
    PageContent,
    Button,
    BigButton,
    InfoBar,
    ContinueBar,
    AlertBar,
    Card,
    InfoSheet,
    Dialog,
    Spinner,
    SimpleList,
    ActionCard,
    AddToCalendar,
    FlatToggleButtonList,
    FlatToggleButton,
    ProminentListItem,
    ProminentList
  },
  data: () => {
    return {
      mobileBreakpoint: 500,
      debounceDelay: 320,
    };
  },
};
