<template>
  <v-sheet class="pa-6 rounded-lg" :color=color elevation="0" rounded>
    <slot></slot>
  </v-sheet>
</template>
<script>
export default {
  props: {
      color: String
  },
};
</script>
