<template>
  <v-list-item>
    <v-list-item-avatar size="50" tile class="mr-8">
      <v-img contain :src="imgUrl"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
      <v-list-item-subtitle v-if="subtitle != null">{{
        subtitle
      }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action class="align-self-center">
        <slot name="action"></slot>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    imgUrl: {
      type: String,
      default: null,
    },
  },
};
</script>