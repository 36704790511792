import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("snackbar", {
      _snackbarSuccess: "success",
      _snackbarError: "error",
      _snackbarInfo: "info",
    }),
  },
};
