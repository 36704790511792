const status = (state, status) => {
  state.status = status;
};

const candidate = (state, candidate) => {
  state.candidate = candidate;
};

const job_guid = (state, job_guid) => {
  state.job_guid = job_guid;
};

const application_guid = (state, application_guid) => {
  state.application_guid = application_guid;
};

const applicationInfo = (state, applicationInfo) => {
  state.applicationInfo = applicationInfo;
};

const candidateQuestions = (state, candidateQuestions) => {
  state.candidateQuestions = candidateQuestions;
};

const jobQuestions = (state, jobQuestions) => {
  state.jobQuestions = jobQuestions;
};

const motivationQuestions = (state, motivationQuestions) => {
  state.motivationQuestions = motivationQuestions;
};

const candidateBasicQuestions = (state, candidateBasicQuestions) => {
  state.candidateBasicQuestions = candidateBasicQuestions;
};

export default {
  status,
  candidate,
  job_guid,
  application_guid,
  applicationInfo,
  candidateQuestions,
  jobQuestions,
  motivationQuestions,
  candidateBasicQuestions,
};
