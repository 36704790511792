<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="amsSettings.senderId"
            hide-details
            placeholder="AMS - SenderId"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" v-if="settingsChanged && settingsValid">
          <Button :type="'confirm'" @clicked="save">Spara</Button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import _ from "lodash";
import Button from "../../basic/Button.vue";
export default {
  components: { Button },
  props: {
    settings: Object,
    active: Boolean,
  },
  data() {
    return {
      settingsChanged: false,
      amsSettings: {
        senderId: "",
      },
      amsActive: false,
    };
  },
  created: function () {
    this.amsActive = this.active;
    if (this.settings != undefined && this.settings != {}) {
      this.amsSettings = JSON.parse(JSON.stringify(this.settings));
    }
    this.$emit("update:settingsValid", this.settingsValid);
  },
  computed: {
    settingsValid: function () {
      let valid = true;
      if (
        this.amsSettings.senderId == null ||
        this.amsSettings.senderId.length == 0
      ) {
        valid = false;
      }
      return valid;
    },
  },
  methods: {
    save: function () {
      this.$emit("update:settings", JSON.parse(JSON.stringify(this.amsSettings)));
      this.$emit("update:settingsValid", this.settingsValid);
      this.$emit("save", null);
      this.settingsChanged = false;
    },
  },
  watch: {
    amsSettings: {
      handler: function () {
        this.settingsChanged = !_.isEqual(this.amsSettings, this.settings);
      },
      deep: true,
    },
  },
};
</script>