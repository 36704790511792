<template>
  <basic-info-sheet>
    <h4>Så hittar du drömkandidaten</h4>
    <p>
      För att du ska hitta din perfekta kandidat så rekommenderar vi att du
      publicerar din tjänst på en annonsplattform för att få maximal spridning
      av din annons.</p><p> När kandidaterna sedan söker tjänsten via Brite så matchas
      de automatiskt och får poäng baserat på hur väl de matchar mot tjänsten.</p><p>
      Läs mer om Brite och vår matchningsprocess nedan. </p><p><a href="https://www.brite.jobs/blogg" target="_blank">Läs mer</a>
    </p>
  </basic-info-sheet>
</template>
<script>
import BasicInfoSheet from "../atom/sheet/BasicInfoSheet.vue";
export default {
  components: { BasicInfoSheet },
};
</script>