<template>
  <v-row>
    <v-col>
      <Card :title="'Info'">
        <v-card-text>
          <v-container>
            <v-row>
              <QBase
                v-for="question in items"
                :key="question.id"
                v-bind="question"
              >
                <template v-slot:question>
                  <component
                    :is="question.kind"
                    :disabled="true"
                    v-bind="question"
                  ></component>
                </template>
              </QBase>
            </v-row>
          </v-container>
        </v-card-text>
      </Card>
    </v-col>
  </v-row>
</template>

<script>
import QBase from "../q/QBase";
import QSingle from "../q/QSingle";
import QMulti from "../q/QMulti";
import QSlide from "../q/QSlide";
import QCities from "../q/QCities";
import QLicense from "../q/QLicense";
import QTextShort from "../q/QTextShort";
import QNumberShort from "../q/QNumberShort";
import QDatepicker from "../q/QDatepicker";
import QText from "../q/QText";
import QPicture from "../q/QPicture";
import QJobs from "../q/QJobs";

import snackbarMixin from "../../mixins/snackbar";
import basicMixin from "../../mixins/basic";

export default {
  components: {
    QBase,
    QSingle,
    QMulti,
    QSlide,
    QCities,
    QLicense,
    QTextShort,
    QNumberShort,
    QText,
    QJobs,
    QDatepicker,
    QPicture,
  },
  mixins: [basicMixin, snackbarMixin],
  props: {
    items: Array,
  },
  data: function () {
    return {
      /*       values: [
        [20, 20],
        [20, 21],
        [20, 50],
        [20, 60],
        [20, 70],
        [20, 80],
        [10, 95],
        [10, 95],
        [30, 75],
      ], */
    };
  },
};
</script>
