<template>
  <v-navigation-drawer
    v-if="!$vuetify.breakpoint.mdAndDown"
    width="230"
    app
    mobile-break-point="50"
  >
    <transition name="fade">
      <svg v-if="!loaded" id="fader" />
    </transition>
    <v-container>
      <v-row class="mt-4">
        <v-col>
          <div class="d-flex justify-center">
            <v-avatar size="80" class="mx-auto pa-4" color="grey lighten-1">
              <v-img contain :src="companyProfile.logoUrl" alt="John" />
            </v-avatar>
          </div>
          <h4 class="mt-1 text-center font-weight-black" color="white">
            {{ companyProfile.companyName }}
          </h4>
        </v-col>
      </v-row>
      <v-list flat dark>
        <v-list-item
          class="my-6"
          v-for="item in items"
          :key="item.id"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-regular"
              v-text="item.name"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
    <div class="bottom">
      <v-row>
        <v-col class="my-12">
          <v-img
            class="mx-auto"
            width="90"
            src="https://storage.googleapis.com/brite-resources/logo/Brite_logo_white%404x.png"
          />
        </v-col>
      </v-row>
      <!--  <v-subheader class="white--text">
        <a href="http://digifut.se/" target="_blank"
          >© Digifut Solutions {{ fullyear }}</a
        >
      </v-subheader> -->
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    loaded: false,
    items: [
      { id: 1, name: "Dashboard", icon: "speed", path: "/" },
      { id: 3, name: "Skapa tjänst", icon: "add", path: "/cr" },
      { id: 4, name: "Följ processer", icon: "mdi-sitemap", path: "/p" },
      { id: 5, name: "Inställningar", icon: "mdi-tune", path: "/s" },
      { id: 6, name: "Support", icon: "mdi-chat-processing", path: "/su" },
    ],
    activePage: 0,
    headerColor: "greenHeader",
  }),
  mounted: function () {
    if (process.env.NODE_ENV == "development") {
      this.items.push({
        id: 7,
        name: "Components",
        icon: "extension",
        path: "/component-library",
      });
    }
  },
  computed: {
    ...mapState("companyProfile", [
      "status",
      "logoUrl",
      "companyName",
      "companyProfile",
    ]),
    fullyear: function () {
      var fullyear = new Date();
      return fullyear.getFullYear();
    },
  },
  watch: {
    activePage: {
      handler: function () {
        if (
          this.activePage == 0 ||
          this.activePage == 3 ||
          this.activePage == 4
        ) {
          this.headerColor = "greenHeader";
        } else if (this.activePage == 1) {
          this.headerColor = "yellowHeader";
        } else if (this.activePage == 2) {
          this.headerColor = "redHeader";
        } else {
          this.headerColor = "greenHeader";
        }
      },
      deep: true,
    },
  },
  created: function () {
    setTimeout(() => {
      this.loaded = true;
    }, 50);
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
  background: #fafafa; /* 
    animation-duration: 5000ms;
    animation-timing-function: ease-in-out; */
}

.v-list-item__title {
  font-family: "Proxima Nova Bold";
  font-size: 1.2rem;
}

html .v-application--is-ltr .v-list-item__icon:first-child {
  margin: 12px;
  margin-right: 16px;
}

h1 {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 2rem;
  /* color: #038C8C; */
  -webkit-transition: color 1s ease-out;
  -moz-transition: color 1s ease-out;
  -o-transition: color 1s ease-out;
  transition: color 1s ease-out;
}

.greenHeader {
  color: #038c8c;
}

.redHeader {
  color: #d94032;
}
.yellowHeader {
  color: #f2af5c;
}

nav.theme--light.v-navigation-drawer {
  background-color: #182540;
  color: white;
}

.theme--light.v-list-item,
.theme--light.v-icon {
  color: rgba(255, 255, 255, 1) !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  .v-icon {
  color: rgba(255, 255, 255, 0.4) !important;
}

div.bottom {
  width: 100%;
  position: absolute;
  bottom: 5px;
}

div.bottom .v-subheader a {
  font-family: "Proxima Nova Thin";
  text-decoration: none;
  color: white;
}
</style>
