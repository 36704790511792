<template>
  <v-layout
    justify-center
    class="brite-spinner spinner-loading"
    :class="{ 'absolute-spinner': absoluteSpinner }"
  >
    <v-flex xs12 class="text-center">
      <!-- <div><img src="/img/B-spin.gif" alt="WebP rules." /></div> -->
      <div>
        <img
          class="brite-spinner-loading-img"
          src="https://storage.googleapis.com/brite-resources/img/content/Loading_main.gif"
          alt="WebP rules."
        />
      </div>
      <!-- <div class="spinner">
    <div class="dot1"></div>
    <div class="dot2"></div>
  </div> -->
      <span>{{ message }}</span>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: {
    message: String,
    absoluteSpinner: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.spinner {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #333;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
