module.exports = {
  status: "initialize",
  job_guid: null,
  application_guid: null,
  candidate: {},
  applicationInfo: null,
  candidateQuestions: [],
  jobQuestions: [],
  motivationQuestions: [],
  candidateBasicQuestions: [],
};
