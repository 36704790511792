<template>
  <v-textarea
    v-model="dAnswer"
    :readonly="disabled"
    :rows="disabled == true ? 5 : 2"
    auto-grow
  ></v-textarea>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    disabled: Boolean,
  },
  data() {
    return {
      dAnswer: this.answer_text,
      dValidAnswer: false,
      typing: false,
      typingDone: false,
    };
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.emitIfValid();
      },
      deep: true,
    },
  },
  mounted: function () {
    this.emitIfValid();
    //console.log("Answer is: " + this.answer)
    //this.dAnswer = JSON.parse(this.answer);
  },
  methods: {
    emitIfValid: function () {
      this.dValidAnswer = false;
      if (this.dAnswer != null && this.dAnswer != false && this.dAnswer != "") {
        this.dValidAnswer = true;
      }
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer_text: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
    },
  },
};
</script>
