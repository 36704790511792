var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.disabled)?_c('v-text-field',{attrs:{"placeholder":"Sök jobb","clearable":"","clear-icon":"close"},on:{"input":function($event){return _vm.searchUpdate($event)},"mouseup":_vm.jobFocus}}):_vm._e(),(_vm.dAnswer.length > 0)?_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Valda jobb ("+_vm._s(_vm.dAnswer.length)+")")]),_c('v-expansion-panel-content',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',{staticClass:"simple-table-full-width removeable-rows"},_vm._l((_vm.dAnswer),function(job){return _c('tr',{key:job.id,on:{"click":function($event){if (!_vm.disabled) {
                    _vm.remove(_vm.dAnswer, _vm.treeAnswer, job);
                  }}}},[_c('td',[_vm._v(_vm._s(job.name))]),_c('td',[_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)])}),0)]},proxy:true}],null,false,1405323780)})],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"hide-overlay":"","transition":_vm.dialogTransition,"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(this.$vuetify.breakpoint.mdAndUp)?_c('v-card',{staticClass:"autocomplete popup"},[_c('v-card-title',[_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('h2',[_vm._v("Jobb")])]),_c('v-flex',{staticClass:"text-right",attrs:{"xs2":""}},[_c('v-icon',{attrs:{"large":"","color":"black"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("close")])],1)],1)],1),_c('v-card-text',[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-text-field',{ref:"jobDialogInput",attrs:{"placeholder":"Sök...","clearable":"","clear-icon":"close"},on:{"input":function($event){return _vm.searchUpdate($event)},"mouseup":_vm.jobFocus},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(
            (_vm.search != null && _vm.search.length > 2) ||
            _vm.showAllJobs == true ||
            _vm.dAnswer.length > 0
          )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-treeview',{attrs:{"selectable":"","selected-color":"primary","items":_vm.jobsTree,"search":_vm.search_tree,"filter":_vm.filter,"open":_vm.open},on:{"update:open":[function($event){_vm.open=$event},_vm.toggleExpand],"update:active":_vm.toggleExpand},model:{value:(_vm.treeAnswer),callback:function ($$v) {_vm.treeAnswer=$$v},expression:"treeAnswer"}})],1):_c('v-btn',{staticClass:"text-none",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.showAllJobs = true}}},[_vm._v("Visa alla")])],1)],1):_c('v-card',{staticClass:"autocomplete popup"},[_c('v-col',[_c('v-text-field',{ref:"jobDialogInput",attrs:{"append-icon":"search","placeholder":"Sök...","clearable":"","clear-icon":"close","prepend-inner-icon":"keyboard_backspace"},on:{"input":function($event){return _vm.searchUpdate($event)},"mouseup":_vm.jobFocus,"click:prepend-inner":function($event){_vm.dialog = false}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(
            _vm.search.length > 2 || _vm.showAllJobs == true || _vm.dAnswer.length > 0
          )?_c('v-treeview',{attrs:{"selectable":"","selected-color":"primary","items":_vm.jobsTree,"search":_vm.search_tree,"filter":_vm.filter,"open":_vm.open},on:{"update:open":[function($event){_vm.open=$event},_vm.toggleExpand],"update:active":_vm.toggleExpand},model:{value:(_vm.treeAnswer),callback:function ($$v) {_vm.treeAnswer=$$v},expression:"treeAnswer"}}):_c('v-btn',{staticClass:"text-none",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.showAllJobs = true}}},[_vm._v("Visa alla")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }