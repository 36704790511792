<template>
  <div class="full-width-div">
    <div v-if="applicants.length < 1">
      <p>Denna tjänst har inga ansökningar än.</p>
    </div>
    <div v-else>
      <v-col class="text-left pa-0" cols="12">
        <p>Hantera ansökningar, granska kandidater och kalla på intervju.</p>
      </v-col>
      <ApplicationsTable
        :items="applicants"
        :headers="headers"
        :table_mobile_limit="table_mobile_limit"
        :title="tableTitle"
        :current-view="currentView"
        @toggle-star="updateStar"
      />
      <v-layout justify-center>
        <v-btn
          dark
          rounded
          class="confirm-action text-none mt-6"
          color="primary"
          @click="chooseCandidates"
          >Till intervjubokning</v-btn
        >
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import store from "../../store";

import ApplicationsTable from "./ApplicationsTable";
import snackbarMixin from "../../mixins/snackbar";

import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/sv.js";

export default {
  components: {
    VueCal,
    ApplicationsTable,
  },
  mixins: [snackbarMixin],
  data: function () {
    return {
      tableTitle: "Ansökningar",
      applicants: [],
      showStarTooltip: false,
      showOnlyFavorites: false,
      currentView: "selectCandidates",
      showSnackbar: false,
      snackbartimeout: 3000,
      snackbarbottom: true,
      snackbarmultiline: true,
      snackbarMessage: "",
      snackBarColor: "primary",
      selectableCandidates: true,
      candidates: [],
      invitedCandidates: [],
      interviews: [],
      dialog: false,
      ripple_boolean: false,
      activeApplicant: { title: "[failed to get name]" },

      selected: [],
      status_width: 120,
      search: "",
    };
  },
  created() {
    this.applicants =
      this.vx_applicants.length > 0
        ? JSON.parse(JSON.stringify(this.vx_applicants))
        : [];
  },
  computed: {
    showStarFilter: function () {
      for (var i = 0; i < this.applicants.length; i++) {
        if (this.applicants[i].starred) {
          return true;
        }
      }
      return false;
    },
    ...mapState("process", {
      vx_applicants: "applicants",
      headers: "headers",
      table_mobile_limit: "table_mobile_limit",
    }),
    filteredApplicants: function () {
      if (this.showOnlyFavorites) {
        return this.applicants.filter(function (item) {
          return item.starred == true;
        });
      } else {
        return this.applicants;
      }
    },
  },
  watch: {
    applicants: {
      deep: true,
      handler() {
        this.$store.commit("process/applicants", this.applicants);
      },
    },
    filteredApplicants: function () {
      if (this.filteredApplicants.length < 1) {
        this.showOnlyFavorites = false;
      }
    },
  },
  methods: {
    ...mapActions("process", ["updateApplicant"]),
    chooseCandidates: function () {
      this.$emit("updateView", null);
    },
    updateStar: async function ({ item, status }) {
      try {
        item.starred = status;
        await this.updateApplicant(item);
        this.applicants = JSON.parse(JSON.stringify(this.vx_applicants));
      } catch (e) {
        console.log(e);
        this._snackbarError("Kunde inte spara ändring");
      }
    },
  },
};
</script>
