const prepareQuestions = ({ jobQuestions, candidateQuestions }) => {
  var jobMotivation = jobQuestions.filter((elem) => elem.motivation == 1);
  var candidateMotivation = candidateQuestions.filter(
    (elem) => elem.motivation == 1
  );
  var candidateBasic = candidateQuestions.filter(
    (elem) => elem.motivation == null || elem.motivation == 0
  );

  var mergedMotivation = mergeMotivation({
    jobMotivation: jobMotivation,
    candidateMotivation: candidateMotivation,
  });

  return {
    motivationQuestions: mergedMotivation,
    candidateBasicQuestions: candidateBasic,
  };
};

const mergeMotivation = ({ jobMotivation, candidateMotivation }) => {
  var match;
  jobMotivation.forEach((element) => {
    match = candidateMotivation.find((obj) => obj.id == element.id);
    if (match != null) {
      element.candidateAnswer = match.answer;
      element.jobBiggerThanCandidate =
        element.answer <= match.answer ? true : false;
      element.values = [element.answer, match.answer];
    }
    /*         else {
            element.candidateAnswer = null;
            element.jobBiggerThanCandidate = false;
            element.values = [element.answer, null];
        } */
  });
  return jobMotivation;
};

module.exports = {
  prepareQuestions: prepareQuestions,
};
