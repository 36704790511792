<template>
  <v-content class="fullscreen-bg">
    <!--  <h1 class="title-text main-title">Brite</h1> -->
    <v-container class="fill-height login-container" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <h1
            class="d-block my-0 title-text main-title text-center"
            @click="$router.push('/')"
          >
            <img
              class="brite-logo-white"
              src="https://storage.googleapis.com/brite-resources/logo/Brite_logo_white%404x.png"
            />
          </h1>
          <router-link class="text-decoration-underline white--text" to="/"
            >Tillbaka till Brite</router-link
          >
        </v-col>
        <v-col cols="12" sm="8" md="6" lg="4">
          <Card v-if="key == 'reset'" :title="'Återställ lösenord'">
            <v-card-text>
              <Spinner
                v-if="loading"
                :absolute-spinner="false"
                :message="'Laddar'"
              />
              <v-form v-if="!loading" v-model="validEmail">
                <v-text-field
                  v-model="email"
                  color="primary"
                  :rules="rules.emailRules"
                  label="Ange email"
                  required
                ></v-text-field>
              </v-form>
              <v-alert v-if="errorMessage != null" class="mt-2" type="error">{{
                errorMessage
              }}</v-alert>
              <v-alert
                v-if="passwordResetMessage != null"
                class="mt-2"
                type="success"
                >{{ passwordResetMessage }}</v-alert
              >
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
              <v-btn to="/" rounded class="text-none" text>Tillbaka</v-btn>
              <v-btn
                v-if="!loading"
                :class="{ 'visibility-hide': !validEmail }"
                class="text-none px-6"
                rounded
                color="primary"
                @click="reset"
                >Återställ lösenord</v-btn
              >
            </v-card-actions>
          </Card>
          <Card v-if="key == 'newPassword'" :title="'Ange nytt lösenord'">
            <v-card-text>
              <Spinner
                v-if="loading"
                :absolute-spinner="false"
                :message="'Laddar'"
              />
              <v-form v-if="!loading" v-model="validPassword">
                <v-text-field
                  v-model="newPassword"
                  color="primary"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  :rules="[rules.required, rules.min]"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  label="Nytt lösenord"
                  hint="Minst 8 tecken"
                  counter
                  required
                  loading
                  @click:append="showPassword = !showPassword"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      :value="progress"
                      :color="color"
                      absolute
                      height="7"
                    ></v-progress-linear>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="repeatedPassword"
                  color="primary"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  :rules="[passwordMatch()]"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  label="Upprepa lösenord"
                  required
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-form>
              <v-alert v-if="errorMessage != null" class="mt-2" type="error">{{
                errorMessage
              }}</v-alert>
              <v-alert
                v-if="passwordUpdatedMessage != null"
                class="mt-2"
                type="success"
                >{{ passwordUpdatedMessage }}</v-alert
              >
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
              <v-btn to="/" rounded class="text-none" text>Tillbaka</v-btn>
              <v-btn
                v-if="!loading"
                :class="{
                  'visibility-hide':
                    !validPassword || newPassword != repeatedPassword,
                }"
                class="text-none px-6"
                rounded
                color="primary"
                @click="change"
                >Ändra lösenord</v-btn
              >
            </v-card-actions>
          </Card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import store from "../store";
import router from "../router";
import basic from "@/mixins/basic";

import { mapActions } from "vuex";

export default {
  mixins: [basic],
  props: {
    source: String,
  },
  data: () => ({
    errorMessage: null,
    passwordResetMessage: null,
    passwordUpdatedMessage: null,
    key: "reset",
    email: "",
    validEmail: false,
    validPassword: false,
    newPassword: "",
    repeatedPassword: "",
    showPassword: false,
    loading: false,
    rules: {
      required: (value) => !!value || "",
      min: (v) => v.length >= 8 || "Minst 8 tecken",
      emailRules: [
        (v) => !!v || "",
        (v) => /.+@.+\../.test(v) || "Ej godkänd e-post",
      ],
    },
  }),
  created: function () {
    console.log("Created");
    if (this.$route.query.token != null) {
      this.key = "newPassword";
    }
  },
  methods: {
    ...mapActions("user", ["passwordReset", "updatePassword"]),
    reset: async function () {
      try {
        this.loading = true;
        this.errorMessage = null;
        await this.passwordReset(this.email);
        this.loading = false;
        this.passwordResetMessage =
          "Ett email för återställning av lösenord har skickats";
      } catch (e) {
        this.loading = false;
        console.log(e);
        if (e.response.status == 400) {
          this.errorMessage =
            "Kunde inte återställa lösenordet. Kontrollera email.";
        }
      }
    },
    change: async function () {
      try {
        this.loading = true;
        this.errorMessage = null;
        await this.updatePassword({
          newPassword: this.newPassword,
          token: this.$route.query.token,
        });
        this.loading = false;
        this.passwordUpdatedMessage = "Lösenord uppdaterat";
      } catch (e) {
        this.loading = false;
        console.log(e);
        if (e.response.status == 400) {
          this.errorMessage = "Kunde inte uppdatera lösenordet. Försök igen.";
        }
      }
    },
  },
  computed: {
    passwordMatch: function () {
      return () =>
        this.newPassword == this.repeatedPassword || "Lösenorden matchar inte";
    },
    progress() {
      return Math.min(100, this.newPassword.length * 10);
    },
    color() {
      return ["#D94032", "#F2AF5C", "#038C8C"][Math.floor(this.progress / 40)];
    },
  },
};
</script>
