//import { axios } from 'axios';

import Vue from "vue";
import utils from "./utils/misc";
import store from "./store.js";

export default {
  methods: {
    setHTTPCookie: function () {},

    uploadUrl: function (url) {
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(
            "http://localhost:3000/upload/ag/url",
            //"https://us-central1-higher.cloudfunctions.net/service/ag/upload/url",
            //process.env.JOBS_BASE_URL + "/upload/ag/url",
            {
              encryptedAccessToken: encryptedAccessToken,
              encryptedSessionToken: encryptedSessionToken,
              url: url,
            },
            {
              headers: {
                authorization: "Bearer " + store.state.user.token
              }
            }
          )
          .then((response) => {
            resolve(response.data.url);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
        //self.originalUrl = response.data.data.url;
      });
    },
    uploadFile: function (formData) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(
            //"http://localhost:3000/upload/ag/file",
            //"https://us-central1-higher.cloudfunctions.net/service/ag/upload/file",
            process.env.VUE_APP_JOBS_BASE_URL + "/upload/ag/file",
            formData,
            {
              headers: {
                authorization: "Bearer " + store.state.user.token
              }
            }
          )
          .then((response) => {
            resolve(response.data.url);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
        //self.originalUrl = response.data.data.url;
      });
    },

    getCreate: function () {
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";
      //console.log("in get create");
      store.commit("create_status", "pending");
      return new Promise((resolve, reject) => {
        //Vue.axios.get("http://localhost:3000/ag/jobs/create", {
        Vue.axios
          .get(
            "https://us-central1-higher.cloudfunctions.net/service/ag/jobs/create",
            {
              //Vue.axios.get("http://localhost:3000/ag/jobs", {
              params: {
                encryptedAccessToken: encryptedAccessToken,
                encryptedSessionToken: encryptedSessionToken,
              },
            }
          )
          //Vue.axios.get("http://localhost:8010/higher/us-central1/autocompleteCity")
          .then(
            (response) => {
              store.commit("create", JSON.parse(JSON.stringify(response.data)));
              store.commit("create_status", "success");
              //console.log("Commited create");
              //console.log(store.getters.create);
              // console.log(response.data[0]);
              // console.log(response.data.length);
              /* for(var i=0;i<response.data.length;i++){
                            response.data[i].created_date=response.data[i].created.substring(0,10);
                        } */
              // console.log(response.data);

              //response.data.unshift({id:1,name:"Hela Sverige"});
              resolve(response.data);
            },
            (error) => {
              console.log(error);
              //console.log("ff in get create");
              store.commit("create_status", "failed");
            }
          );
      });
    },
    getJobs: function () {
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";
      //console.log("in get jobs");
      store.commit("job_status", "pending");
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("https://us-central1-higher.cloudfunctions.net/rec/ag/jobs", {
            //Vue.axios.get("http://localhost:3000/ag/jobs", {
            headers: {
              authorization:
                "Bearer afa6bdb02325ece42d702327e2fbc98a4913810a2bd40ec606e5594b36f2a3096f85de6f4e07a67771062afc156cfe2621e176bb22ccdfa2011a7726bb42f28edc098177d713a79f4aecb7ad01282b2bf185a7f19c7cf42006ab9862e4d1f6a7a6d0aecd45c840f7ab411410f180d0574549bfaedb4d47199563fc4e63b3743e.d283d3b589cfd7196d5501fc9d29bad4",
            },
            params: {
              encryptedAccessToken: encryptedAccessToken,
              encryptedSessionToken: encryptedSessionToken,
            },
          })
          //Vue.axios.get("http://localhost:8010/higher/us-central1/autocompleteCity")
          .then(
            (response) => {
              store.dispatch("jobs", JSON.parse(JSON.stringify(response.data)));
              //store.commit("jobs", JSON.parse(JSON.stringify(response.data)));
              store.commit("job_status", "success");
              // console.log(response.data[0]);
              // console.log(response.data.length);
              /* for(var i=0;i<response.data.length;i++){
                            response.data[i].created_date=response.data[i].created.substring(0,10);
                        } */
              // console.log(response.data);

              //response.data.unshift({id:1,name:"Hela Sverige"});
              resolve(response.data);
            },
            (error) => {
              console.log(error);
              //console.log("ff in get jobs");
              store.commit("job_status", "failed");
            }
          );
      });
    },
    inviteToInterviews: function (jobGuid, candidates, interviews) {
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";

      return new Promise((resolve, reject) => {
        Vue.axios
          .post(
            "https://us-central1-higher.cloudfunctions.net/service/ag/jobs/" +
              jobGuid +
              "/invite",
            //Vue.axios.post("http://localhost:3000/ag/jobs/" + jobGuid + "/invite",
            {
              encryptedAccessToken: encryptedAccessToken,
              encryptedSessionToken: encryptedSessionToken,
              candidates: candidates,
              interviews: interviews,
            }
          )
          .then(
            (response) => {
              // console.log("What is the token?");
              // console.log(response.data);
              resolve(response.data);
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      });
    },
    createJob: function (questions) {
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";

      return new Promise((resolve, reject) => {
        Vue.axios
          .post(
            "https://us-central1-higher.cloudfunctions.net/service/ag/createjob",
            //Vue.axios.post("http://localhost:3000/ag/createjob",
            {
              encryptedAccessToken: encryptedAccessToken,
              encryptedSessionToken: encryptedSessionToken,
              questions: questions,
            }
          )
          .then(
            (response) => {
              // console.log("What is the token?");
              // console.log(response.data);
              resolve(response.data);
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      });
    },
    saveCompany: function (company) {
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";

      return new Promise((resolve, reject) => {
        Vue.axios
          .post(
            "https://us-central1-higher.cloudfunctions.net/service/ag/company",
            //Vue.axios.post("http://localhost:3000/ag/company",
            {
              encryptedAccessToken: encryptedAccessToken,
              encryptedSessionToken: encryptedSessionToken,
              company: company,
            }
          )
          .then(
            (response) => {
              //console.log("What is the token?");
              // console.log(response.data);
              resolve(response.data);
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      });
    },
    getCompany: function () {
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";
      store.commit("company_status", "pending");
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(
            "https://us-central1-higher.cloudfunctions.net/service/ag/company",
            {
              //Vue.axios.get("http://localhost:3000/ag/company", {
              params: {
                encryptedAccessToken: encryptedAccessToken,
                encryptedSessionToken: encryptedSessionToken,
              },
            }
          )
          //Vue.axios.get("http://localhost:8010/higher/us-central1/autocompleteCity")
          .then(
            (response) => {
              //console.log(response.data);
              //response.data.unshift({id:1,name:"Hela Sverige"});

              store.commit(
                "company",
                JSON.parse(JSON.stringify(response.data))
              );
              store.commit("company_status", "success");
              store.commit(
                "profile",
                utils.filteredList(
                  JSON.parse(JSON.stringify(store.state.company)),
                  "tab",
                  "company"
                )
              );
              store.commit(
                "account",
                utils.filteredList(
                  JSON.parse(JSON.stringify(store.state.company)),
                  "tab",
                  "account"
                )
              );

              resolve(response.data);
            },
            (error) => {
              store.commit("company_status", "failed");
              console.log(error);
            }
          );
      });
    },
    getPlaces(query) {
      //console.log("query is " + query)
      return new Promise((resolve, reject) => {
        //Vue.axios.get("http://localhost:3000/external/cities", {
        Vue.axios
          .get(
            "https://us-central1-higher.cloudfunctions.net/service/external/cities",
            {
              params: {
                input: query,
              },
            }
          )
          //Vue.axios.get("http://localhost:8010/higher/us-central1/autocompleteCity")
          .then(
            (response) => {
              // console.log(response.data);
              //response.data.unshift({id:1,name:"Hela Sverige"});
              resolve(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
      });
    },
    getQuestions: function () {
      return new Promise((resolve) => {
        Vue.axios
          .get("https://us-central1-higher.cloudfunctions.net/getQuestions")
          //Vue.axios.get("http://localhost:8010/higher/us-central1/getQuestions")
          .then(
            (response) => {
              response.data.forEach((el) => {
                (el.visible = false),
                  (el.dimmed = false),
                  (el.answer = null),
                  (el.revealed = false),
                  (el.validAnswer = false);
              }); // ES6
              //console.log(response.data.value[0].joke);
              if (response.data.statue == "OK") {
                resolve(response.data);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      });
    },
    checkIfLoggedIn: function () {
      return new Promise((resolve, reject) => {
        if (store.state.isLoggedIn == true) {
          resolve(true);
        }
        this.getUserInfo()
          .then((result) => {
            //console.log(result);
            //console.log(result.validToken);
            if (result.validToken == true) {
              //console.log("true!!!");
              store.commit("isLoggedIn", true);
              //console.log(store.state.isLoggedIn);
              resolve(true);
            } else {
              store.commit("isLoggedIn", false);
              resolve(false);
            }
          })
          .catch((err) => {
            //console.log("Could not get user data" + err);
            store.commit("isLoggedIn", false);
            reject(err);
          });
      });
    },
    checkCredentials: function (username, password) {
      return new Promise((resolve, reject) => {
        //Vue.axios.post("http://localhost:3000/auth/AgLogin",
        Vue.axios
          .post(
            "https://us-central1-higher.cloudfunctions.net/service/auth/AgLogin",
            {
              username: username,
              password: password,
            }
          )
          .then(
            (response) => {
              //console.log("What is the token?");
              //console.log(response.data);
              if (response.data.isLoggedIn == true) {
                //console.log("Token resolved?");

                resolve(response.data);
              } else {
                resolve(response.data);
              }
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      });
    },
    getUserInfo: function () {
      //console.log("getting access_token_cookie")
      //console.log($cookies.get("encrypted_access_token"))
      //console.log($cookies.get("encrypted_session_token"))
      var encryptedAccessToken = $cookies.get("encrypted_access_token");
      var encryptedSessionToken =
        $cookies.get("encrypted_session_token") != null
          ? $cookies.get("encrypted_session_token")
          : "";
      return new Promise((resolve, reject) => {
        if (encryptedAccessToken == null) {
          reject({ validToken: false });
        }
        //Vue.axios.post("http://localhost:3000/auth/getAgInfo",
        Vue.axios
          .post(
            "https://us-central1-higher.cloudfunctions.net/service/auth/getAgInfo",
            {
              encryptedAccessToken: encryptedAccessToken,
              encryptedSessionToken: encryptedSessionToken,
            }
          )
          .then(
            (response) => {
              //console.log("What is the token?");
              //console.log(response.data.validToken);
              if (response.data.validToken == true) {
                //console.log("Token resolved?");

                resolve(response.data);
              } else {
                reject({ validToken: false });
              }
            },
            (error) => {
              console.log(error);
              reject({ validToken: false });
            }
          );
      });
    },
  },
};
