<template>
  <v-container fluid>
    <QBase v-for="question in questions" :key="question.id" v-bind="question">
      <template v-slot:question>
        <component :is="question.kind" v-bind="question"></component>
      </template>
    </QBase>
  </v-container>
</template>

<script>
import basicMixin from "../../mixins/basic";
import { questions } from "./Questions";
import questionsMixin from "../../mixins/companyQuestions";

export default {
  mixins: [basicMixin, questionsMixin],
  data() {
    return {
      questions: questions,
    };
  },
};
</script>
