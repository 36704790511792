<template>
  <div>
    <vc-date-picker
      v-model="dAnswer"
      class="datepicker"
      :min-date="minDate"
      :value="null"
      color="indigo"
      is-inline
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: String,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
  },
  data() {
    return {
      dAnswer: null,
      dValidAnswer: false,
    };
  },
  computed: {
    minDate: function () {
      var date = new Date();
      date.setDate(date.getDate() + 5);
      return date;
    },
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer_text: this.dAnswer.toString(),
          validAnswer: this.dValidAnswer,
        });
      },
      deep: true,
    },
  },
  mounted: function () {
    if (typeof this.answer_text == "string" && this.answer_text != undefined) {
      this.dAnswer = new Date(this.answer_text);
    }
  },
  methods: {},
};
</script>
