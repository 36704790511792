<template>
  <div class="pa-3">
    <p class="text-center"><b>Löneanspråk</b></p>
    <bar-chart
      :height="height"
      :chart-data="datacollection"
      :options="options"
    ></bar-chart>
  </div>
</template>

<script>
import BarChart from "./BarChart.js";
import LineChart from "./LineChart.js";
import { theme } from "../../../json/colors.js";

export default {
  components: {
    LineChart,
    BarChart,
  },
  props: {
    height: Number,
    dataset: Object,
  },
  data() {
    return {
      datacollection: {
        labels: null,
        datasets: [],
      },
      datasetSettings: {
        label: "Löneanspråk",
        barThickness: 5,
        backgroundColor: theme.themes.light.secondary,
      },
      datacollectionExample: {
        labels: ["Trafikledare", "Säljare", "Säljare", "Säljare"],
        datasets: [
          {
            // If switch to 3.0 beta with support for rounded corners
            //borderSkipped: false,
            //borderRadius: 5,
            //borderColor: "black",
            label: "Löneanspråk",
            barThickness: 5,
            backgroundColor: theme.themes.light.secondary,
            data: [42000, 39000, 35000, 36000],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 5,
                suggestedMin: 0,
                suggestedMax: 60000,
              },
            },
          ],
        },
      },
    };
  },
  created: function () {
    this.datacollection.labels = this.dataset.labels;
    this.datacollection.datasets = [
      {
        ...this.datasetSettings,
        data: this.dataset.data,
      },
    ];
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
