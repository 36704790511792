<template>
  <v-card class="mx-auto" tile>
    <v-card-title>
      <h2>{{ title }}</h2>
      <v-spacer></v-spacer>
      <slot name="cardOptions"> </slot>
    </v-card-title>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {};
  },
};
</script>
