const jobStatistics = (state, data) => {
  state.jobStatistics = data;
};

const status = (state, data) => {
  state.status = data;
};

const jobStatisticsForDashboard = (state, data) => {
  state.jobStatisticsForDashboard = data;
};

export default {
  status: status,
  jobStatistics: jobStatistics,
  jobStatisticsForDashboard: jobStatisticsForDashboard,
};
