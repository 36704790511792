<template>
  <div class="pa-3">
    <p class="text-center"><b>Ansökningar</b></p>
    <line-chart
      :height="height"
      :chart-data="datacollection"
      :options="options"
    ></line-chart>
  </div>
</template>

<script>
import BarChart from "./BarChart.js";
import LineChart from "./LineChart.js";
import { theme } from "../../../json/colors.js";

export default {
  components: {
    LineChart,
    BarChart,
  },
  props: {
    height: Number,
    dataset: Object,
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 3,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 6,
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          ],
        },
      },
      datasetSettings: {
        borderColor: theme.themes.light.secondary,
        pointBackgroundColor: "white",
        borderWidth: 1,
        pointBorderColor: "#038c8c3b",

        backgroundColor: "#038c8c3b",
      },
      datacollection: {
        labels: null,
        datasets: [],
      },
      datacollectionExample: {
        labels: ["Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "Maj", "Jun"],

        datasets: [
          {
            label: "Ansökningar",
            //backgroundColor: theme.themes.light.secondary,
            borderColor: theme.themes.light.secondary,
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#038c8c3b",

            backgroundColor: "#038c8c3b", //theme.themes.light.secondary,
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
          },
        ],
      },
    };
  },
  created: function () {
    this.datacollection.labels = this.dataset.labels;
    this.datacollection.datasets = [
      {
        ...this.datasetSettings,
        data: this.dataset.data,
      },
    ];
  },
  mounted() {},
  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (5 + 1)) + 5;
    },
  },
};
</script>

<style></style>
