import { groupQuestions, unGroupQuestions } from "../../utils/questions";

var { getCompany, saveCompany } = require("../../requests/global");

const groupedQuestions = (context, data) => {
  var q = context.state.groupedQuestions;
  if (q != null) {
    data.forEach(function (item, index) {
      q[
        q.findIndex((obj) => {
          return obj.id === item.id;
        })
      ] = item;
    });
  }
  context.commit("groupedQuestions", JSON.parse(JSON.stringify(q)));
};

const get = async (context, data) => {
  context.commit("status", "pending");
  let response = await getCompany({});
  let questions = response.questions;
  //questions = groupQuestions(questions,context.state.tabSortOrder)
  var groupedQuestions = [{ tab: "company", sortKey: 1, questions: questions }];


  context.dispatch("companyProfile/setFromCompanyQuestions", questions, { root: true });

  context.commit("status", "success");
  context.commit("groupedQuestions", groupedQuestions);
  return;
};

const save = async (context, data) => {
  var unGroupedQuestions = unGroupQuestions(data);
  await saveCompany({ questions: unGroupedQuestions });
  if (!context.rootState.user.companyInformation.account.status.setupComplete) {
    context.dispatch("user/getCompanyInformation", null, { root: true });
  }
  context.dispatch("groupedQuestions", data);
};

export default {
  groupedQuestions: groupedQuestions,
  get: get,
  save: save,
};
