<template>
  <v-row>
    <v-col><FlatToggleButtonList :items="items" /></v-col>
  </v-row>
</template>

<script>
import basicMixin from "../../../mixins/basic";

export default {
  mixins: [basicMixin],
  data: function () {
    return {
      items: ["Redigera profil", "Notifikationer", "Välj plan", "Annonsering"],
    };
  },
};
</script>

<style></style>
