const applications = (state, applications) => {
  state.applications = JSON.parse(JSON.stringify(applications));
};
const latestApplications = (state, latestApplications) => {
  state.latestApplications = JSON.parse(JSON.stringify(latestApplications));
};
const status = (state, status) => {
  state.status = JSON.parse(JSON.stringify(status));
};

export default {
  status,
  applications,
  latestApplications,
};
