<template>
  <div>
    <v-row v-if="this.$vuetify.breakpoint.smAndDown">
      <v-col>
        <v-card class="d-flex flex-column">
          <v-toolbar flat>
            <v-toolbar-title>{{ title }} ({{ items.length }})</v-toolbar-title>

            <v-spacer></v-spacer>

            <div>
              <v-btn v-if="showOnlyMatches" icon>
                <v-tooltip v-model="showMatchesTooltip" color="black" top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      large
                      color="primary"
                      v-on="on"
                      @click="
                        showOnlyMatches = !showOnlyMatches;
                        showMatchesTooltip = false;
                      "
                      >check_circle</v-icon
                    >
                  </template>
                  <span>Visar endast matchningar</span>
                </v-tooltip>
              </v-btn>

              <v-btn v-else icon>
                <v-tooltip v-model="showMatchesTooltip" color="black" top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      large
                      color="primary"
                      v-on="on"
                      @click="
                        showOnlyMatches = !showOnlyMatches;
                        showMatchesTooltip = false;
                      "
                      >check_circle_outline</v-icon
                    >
                  </template>
                  <span>
                    Visar alla kandidater
                    <br />Klicka för att endast visa matchningar
                  </span>
                </v-tooltip>
              </v-btn>
            </div>
            <div v-if="showFavoritesFilter">
              <v-btn v-if="showOnlyFavorites" icon>
                <v-tooltip
                  v-model="showStarTooltip"
                  color="black"
                  top
                  nudge-bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      large
                      color="primary"
                      v-on="on"
                      @click="
                        showOnlyFavorites = !showOnlyFavorites;
                        showStarTooltip = false;
                      "
                      >star</v-icon
                    >
                  </template>
                  <span>Visar endast favoriter</span>
                </v-tooltip>
              </v-btn>

              <v-btn v-else icon>
                <v-tooltip
                  v-model="showStarTooltip"
                  color="black"
                  top
                  nudge-bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      large
                      color="primary"
                      v-on="on"
                      @click="
                        showOnlyFavorites = !showOnlyFavorites;
                        showStarTooltip = false;
                      "
                      >star_outline</v-icon
                    >
                  </template>
                  <span>
                    Visar alla kandidater
                    <br />Klicka för att endast visa favoriter
                  </span>
                </v-tooltip>
              </v-btn>
            </div>
          </v-toolbar>
          <v-list two-line>
            <v-list-item-group>
              <template v-for="(item, index) in filteredApplicants">
                <v-list-item :key="item.job_guid" @click="openCandidate(item)">
                  <div class="mr-2">
                    <v-icon
                      v-if="item.starred"
                      color="primary"
                      @click.stop="
                        $emit('toggle-star', { item: item, status: false })
                      "
                      >star</v-icon
                    >
                    <v-icon
                      v-else
                      color="grey"
                      @click.stop="
                        $emit('toggle-star', { item: item, status: true })
                      "
                      >star_outline</v-icon
                    >
                    <v-btn
                      v-if="item.score > 0"
                      :max-width="20"
                      :min-width="20"
                      height="30px"
                      depressed
                      class="button-not-clickable"
                      :color="item.matchColor"
                      >{{ item.score }}</v-btn
                    >
                    <v-btn
                      v-else
                      :max-width="20"
                      :min-width="20"
                      height="30px"
                      depressed
                      class="button-not-clickable blurry-text"
                      :color="'grey lighten-2'"
                      >DD</v-btn
                    >
                    <!--  <v-tooltip
                      color="black"
                      v-model="item.tooltip"
                      top
                      nudge-bottom
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            :max-width="20"
                            :min-width="20"
                            height="30px"
                            depressed
                            class="button-not-clickable blurry-text"
                            :color="'grey lighten-2'"
                            >DD</v-btn
                          >
                        </div>
                      </template>
                      <span>Kandidaten har inte gjort tester</span>
                    </v-tooltip> -->
                  </div>
                  <v-list-item-content width="100%">
                    <v-list-item-title
                      class="text-wrap"
                      v-text="item.name"
                    ></v-list-item-title>
                    <!-- <v-list-item-subtitle class="text--primary" v-text="item.employee_comment"></v-list-item-subtitle> -->
                    <v-list-item-subtitle
                      class="text-wrap"
                      v-text="item.employee_comment"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="item.statusText"
                    ></v-list-item-action-text>
                    <v-avatar :color="item.statusColor" size="24"></v-avatar>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-card v-else max-width="2000" style="margin: auto">
      <v-card-title>
        <h5>{{ title }} ({{ items.length }})</h5>
        <div class="flex-grow-1"></div>
        <v-text-field
          :value="search"
          append-icon="search"
          label="Sök"
          single-line
          hide-details
          @input="searchUpdate($event)"
        ></v-text-field>

        <div>
          <v-menu :close-on-content-click="false" bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Filter</v-list-item-title>
                    <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch
                      v-model="showOnlyMatches"
                      color="primary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-title
                    >Visa endast 100% matchningar</v-list-item-title
                  >
                </v-list-item>
                <v-list-item v-if="showFavoritesFilter">
                  <v-list-item-action>
                    <v-switch
                      v-model="showOnlyFavorites"
                      color="primary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Visa endast favoriter</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="showIdentifyCandidatesFilter">
                  <v-list-item-action>
                    <v-switch
                      v-model="identifyCandidates"
                      color="primary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Visa identitet</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-card-title>
      <v-data-table
        class="clickable-rows-table"
        :mobile-breakpoint="table_mobile_limit"
        :headers="headers"
        :items="filteredApplicants"
        :search="search_table"
        item-key="guid"
        sort-by="match"
        :sort-desc="true"
        @click:row="openCandidate"
      >
        <template v-slot:item.statusText="{ item }">
          <v-tooltip
            v-model="item.status.tooltip"
            color="black"
            right
            nudge-bottom
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-avatar :color="item.statusColor" size="24"></v-avatar>
              </div>
            </template>
            <span>{{ item.statusText }}</span>
          </v-tooltip>

          <!-- <v-btn
          depressed
          block
          class="button-not-clickable"
          :color="item.statusColor"
          ></v-btn>--> </template
        ><template v-slot:item.name="{ item }">
          <span v-if="identifyCandidates"
            >{{ item.name }} <br />
            {{ item.email }}</span
          >
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:item.starred="{ item }">
          <v-icon
            v-if="item.starred"
            large
            color="primary"
            @click.stop="$emit('toggle-star', { item: item, status: false })"
            >star</v-icon
          >
          <v-icon
            v-else
            large
            color="grey"
            @click.stop="$emit('toggle-star', { item: item, status: true })"
            >star_outline</v-icon
          >
        </template>
        <template v-slot:item.score="{ item }">
          <v-btn
            v-if="item.score > 0"
            depressed
            class="button-not-clickable"
            :color="item.matchColor"
            >{{ item.score }}</v-btn
          >
          <v-tooltip
            v-else
            v-model="item.tooltip"
            color="black"
            top
            nudge-bottom
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  depressed
                  class="button-not-clickable blurry-text"
                  :color="'grey lighten-2'"
                  >DD</v-btn
                >
              </div>
            </template>
            <span>Kandidaten har inte gjort tester</span>
          </v-tooltip>
        </template>
        <template v-slot:item.matchJob="{ item }">
          <v-tooltip
            v-if="!item.matchQuestionsValid"
            v-model="item.validTooltip"
            color="primary"
            top
            nudge-bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon large color="grey lighten 3" v-on="on">search_off</v-icon>
            </template>
            <span>Kandidaten har inte angett denna information</span>
          </v-tooltip>
          <div v-else>
            <v-icon v-if="item.matchJob" large color="success"
              >check_circle</v-icon
            >
            <v-icon v-else large color="error">cancel</v-icon>
          </div>
        </template>
        <template v-slot:item.matchJobPercentage="{ item }">
          <v-progress-circular
            :rotate="-90"
            :size="30"
            :width="2"
            :value="item.matchJobPercentage"
            color="secondary"
          >
            {{ item.matchJobPercentage }}
          </v-progress-circular>
        </template>
        <!--       <template v-slot:item.status="{ item }">
        <v-btn @click="seeCandidate(item)" class="text-none" rounded dark color="primary" icon>
          <v-icon large>info</v-icon>
        </v-btn>
        </template>-->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    title: String,
    items: Array,
    headers: Array,
    table_mobile_limit: Number,
  },
  data: () => {
    return {
      dItems: null,
      search: "",
      search_table: "",
      showOnlyFavorites: false,
      identifyCandidates: false,
      showOnlyMatches: false,
      showStarTooltip: false,
      showMatchesTooltip: false,
      debounceDelay: 320,
      filter: {
        active: true,
      },
    };
  },
  methods: {
    ...mapActions("candidateCard", ["setCandidate"]),
    openCandidate: function (application) {
      this.setCandidate(application);
      this.$router.push({
        name: "Candidate",
        params: {
          application_guid: application.guid,
          job_guid: this.job_guid,
          currentView: this.currentView,
        },
      });
    },
    searchUpdate(val) {
      //console.log(val);
      var self = this;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(function () {
        self.search_table = val;
      }, this.debounceDelay);
    },
  },
  computed: {
    ...mapState("process", ["job_guid"]),
    showFavoritesFilter: function () {
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].starred) {
          return true;
        }
      }
      return false;
    },
    showIdentifyCandidatesFilter: function () {
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].email != null) {
          return true;
        }
      }
    },
    filteredApplicants: function () {
      var filtArr = JSON.parse(JSON.stringify(this.items));
      if (this.showOnlyFavorites) {
        filtArr = filtArr.filter(function (item) {
          return item.starred == true;
        });
      }
      if (this.showOnlyMatches) {
        filtArr = filtArr.filter(function (item) {
          return item.matchJob == true && item.matchQuestionsValid == true;
        });
      }
      return filtArr;
    },
  },
};
</script>
