<template>
  <div>
    <!--         <v-range-slider
          v-model="dAnswer"
          :step="sliderStepSize"
          :max="range[1]"
          :min="range[0]"
          thumb-label="always"
          class="align-center"
        >
  <template v-slot:prepend>
            <v-text-field
              v-model="salary.values[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              v-model="salary.values[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
    </v-range-slider>-->
    <v-slider
      v-if="showThumbLabel"
      v-model="dAnswer"
      :disabled="disabled"
      :max="range[1]"
      :min="range[0]"
      :step="sliderStepSize"
      :ticks="false"
      class="custom-thumb-label"
      color="secondary"
      thumb-label="always"
      track-color="grey lighten-3"
      always-dirty
      :tick-labels="fixedLabels"
      @start="touching = true"
      @end="endOfSlide"
      @mouseup="endOfSlide"
      @mousedown="dragging = true"
    ></v-slider>
    <v-slider
      v-if="!showThumbLabel"
      v-model="dAnswer"
      :disabled="disabled"
      :max="range[1]"
      :min="range[0]"
      :step="sliderStepSize"
      :ticks="false"
      thumb-size="50"
      color="secondary"
      track-color="secondary"
      always-dirty
      :tick-labels="fixedLabels"
      @start="touching = true"
      @end="endOfSlide"
      @mouseup="endOfSlide"
      @mousedown="dragging = true"
    ></v-slider>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    ticks: Number,
    max: Number,
    min: Number,
    default: Number,
    sliderStepSize: Number,
    answer: {
      type: Number,
      default: () => {
        return null;
      },
    },
    showThumbLabel: Boolean,
    range: Array,
    labels: Array,
    introText: String,
    instructionText: String,
  },
  data() {
    return {
      isNull: null,
      dAnswer: null,
      dValidAnswer: false,
      dLabels: [],
      touching: false,
      dragging: false,
      slideEnded: false,
      confirmedOnce: false,
      // myColor: "#038C8C",
    };
  },
  computed: {
    fixedLabels: function () {
      //this.labels = this.labels[0];
      this.dLabels[0] = this.labels[0];
      var labellength = Math.floor(
        (this.range[1] - this.range[0]) / this.sliderStepSize
      );
      //console.log(labellength);
      //console.log(this.range);
      for (var i = 1; i < labellength; i++) {
        this.dLabels[i] = "";
      }
      this.dLabels[labellength] = this.labels[1];
      //console.log(this.dLabels);

      return this.dLabels;
    },
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.slideEnded = false;
        if (this.confirmedOnce) {
          //console.log(this.slideEnded)
          //console.log(this.touching)
          //console.log(this.dragging)
          if (
            this.dAnswer != null &&
            this.dAnswer != false &&
            this.dAnswer != ""
          ) {
            this.dValidAnswer = true;
            if (
              this.slideEnded == true ||
              (this.touching == false && this.dragging == false)
            ) {
              this.$emit("changeAnswer", {
                id: this.id,
                tab: this.tab,
                answer: this.dAnswer,
                validAnswer: this.dValidAnswer,
              });
            }
          }
        }
        this.confirmedOnce = true;
        /*         this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer
        }); */
      },
      deep: true,
    },
  },
  mounted: function () {
    //console.log("Answer is: " + this.answer)
    //this.dAnswer = JSON.parse(this.answer);

    if (this.answer != null) {
      this.dAnswer = this.answer;
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer: this.dAnswer,
        validAnswer: true,
      });
    } else if (!this.showThumbLabel) {
      this.dAnswer = (this.range[1] + this.range[0]) / 2;
    }
  },
  methods: {
    endOfSlide: function () {
      //console.log("end of slide")
      this.touching = false;
      this.dragging = false;
      this.slideEnded = true;
      this.dValidAnswer = true;
      //console.log("slide ended");
      //console.log(this.dAnswer);
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
    },
  },
};
</script>
<style>
.v-input__slider {
  margin-top: 24px;
}
.v-slider__tick {
  background-color: rgba(255, 255, 255, 0) !important;
}
.custom-thumb-label .v-slider--horizontal .v-slider__thumb-label > * {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  background-color: white !important;
  caret-color: white !important;
  color: rgb(121, 121, 121);
  border: 1px solid #cecece;
  border-radius: 3px;
  padding: 0px 10px 0px 10px;
  font-size: 10px;
}

.custom-thumb-label .v-slider--horizontal .v-slider__thumb-label {
  -webkit-transform: translateY(0%) translateY(-25px) translateX(0%)
    rotate(0deg) !important;
  transform: translateY(0%) translateY(-25px) translateX(0%) rotate(0deg) !important;
  background-color: white !important;
  caret-color: white !important;
  height: 0px !important;
  width: 0px !important;
}
</style>
