
<template>
  <Dialog v-model="value" title="Vill du radera utkast?">
    Denna handling kan inte ångras.
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn text class="text-none" @click="value = false">Avbryt</v-btn>
      <Button :type="'error'" @clicked="deleteProcess">Ja, radera</Button>
    </template>
  </Dialog>
</template>

<script>
import Button from "../basic/Button.vue";
import Dialog from "../basic/Dialog.vue";
import snackbar from "../../mixins/snackbar";
import { mapActions } from "vuex";

export default {
  mixins: [snackbar],
  components: { Dialog, Button },
  data: function () {
    return {
      deleteDraftDialog: false,
    };
  },
  props: {
    value: Boolean,
    job: Object
  },
  watch: {
    value: function (newValue, oldValue) {
      if (oldValue != newValue) this.$emit("input", newValue);
    },
  },
  methods: {
    deleteProcess: async function () {
      try {
        console.log(this.job.job_guid);
        this.$emit("input", false);
        await this.updateJobStatus({
          job_guid: this.job.job_guid,
          status: "DELETED",
        });
      } catch (error) {
        console.log(error);
        this._snackbarError("Kunde inte ta bort jobb");
      }
    },
    ...mapActions("process", {
      updateJobStatus: "updateJobStatus",
    }),
  },
};
</script>