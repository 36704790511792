<template>
  <v-dialog
    v-model="value"
    :max-width="maxWidth"
    :fullscreen="
      (allowFullscreen && $vuetify.breakpoint.smAndDown) || fullscreen
    "
  >
    <v-card>
      <v-card-title>
        <h2 class="mt-0">{{ title }}</h2>
      </v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: Number,
      default: 600,
    },
    value: Boolean,
    title: String,
    fullscreen: {
      type: Boolean,
      default: false,
    },
    allowFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function (newValue, oldValue) {
      if (oldValue != newValue) this.$emit("input", newValue);
    },
  },
};
</script>
