import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import './registerServiceWorker'
//import vuetify from '@/plugins/vuetify'
import "./assets/main.css";
import VueCookies from "vue-cookies";

import Default from "./layouts/Default";
import NoSidebar from "./layouts/NoSidebar";
import Toolbar from "./layouts/Toolbar";

import axios from "axios";
import VueAxios from "vue-axios";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import vuetify from "./plugins/vuetify";

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
VueCookies.config("30d");

Vue.component("default-layout", Default);
Vue.component("default-toolbar-layout", Toolbar);
Vue.component("no-sidebar-layout", NoSidebar);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,

  // vuetify,
  render: (h) => h(App),
}).$mount("#app");
