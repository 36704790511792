<template>
  <v-sheet class="pa-6 rounded-lg" color="secondary" elevation="1" rounded>
    <slot></slot>
  </v-sheet>
</template>
<script>
export default {
  props: {},
};
</script>
