import { getCompanyJobPostingSettingsRequest, putCompanyJobPostingSettingsRequest } from '../../requests/global'

const getCompanyJobPostingSettings = async (context, data) => {
  let settings = await getCompanyJobPostingSettingsRequest();
  let jobPostingSettings = {
    blocketjobs: {
      component: "BlocketIntegrationSettings",
      title: "Blocket Jobs",
      subTitle: "Annonsera på Blocket Jobs",
      status: settings.blocketjobs.status,
      settings: settings.blocketjobs.settings,
      settingsValid: false,
    },
    ams: {
      component: "AmsIntegrationSettings",
      title: "Arbetsförmedlingen",
      subTitle: "Annonsera på Arbetsförmeedlingen",
      status: settings.ams.status,
      settings: settings.ams.settings,
      settingsValid: false,
    },
  }
  setCompanyJobPostingExtraFields(jobPostingSettings);
  context.commit("jobPostingSettings", jobPostingSettings);
  return jobPostingSettings;
}

const saveCompanyJobPostingSettings = async (context, jobPostingSettings) => {
  let settings = {
    ams: {
      status: jobPostingSettings.ams.status,
      settings: jobPostingSettings.ams.settings
    },
    blocketjobs: {
      status: jobPostingSettings.blocketjobs.status,
      settings: jobPostingSettings.blocketjobs.settings
    }
  }
  await putCompanyJobPostingSettingsRequest({ body: settings });
  setCompanyJobPostingExtraFields(jobPostingSettings);

  context.commit("jobPostingSettings", jobPostingSettings);

  //await context.dispatch("getCompanyJobPostingSettings", null)
}

const loadCompanyProfile = async (context, data) => {
  context.commit("status", "pending");

  context.commit("companyProfile", data);
  context.commit("status", "success");
};

const setFromCompanyQuestions = async (context, questions) => {
  let companyProfile = {}
  for (var i = 0; i < questions.length; i++) {
    if (questions[i].id == 7) {
      companyProfile.logoUrl = questions[i].answer_text;
    }
    if (questions[i].id == 1) {
      companyProfile.companyName = questions[i].answer_text;
    }
  }
  context.commit("companyProfile", companyProfile);
  context.commit("status", "success");
}


export default {
  getCompanyJobPostingSettings: getCompanyJobPostingSettings,
  saveCompanyJobPostingSettings: saveCompanyJobPostingSettings,
  loadCompanyProfile: loadCompanyProfile,
  setFromCompanyQuestions: setFromCompanyQuestions
};

function setCompanyJobPostingExtraFields(settings) {
  for (const [key, value] of Object.entries(settings)) {
    value.statusText = getCompanyJobPostingStatusText(value.status);
    value.statusColor = getCompanyJobPostingStatusColor(value.status);
    value.active = (value.status == "ACTIVE") ? true : false;
  }
}

function getCompanyJobPostingStatusColor(status) {
  let statusColorMap = {
    NOT_SETUP: "grey",
    INACTIVE: "error",
    ACTIVE: "open",
    PENDING: "ongoing",
  }
  return statusColorMap[status];
}
function getCompanyJobPostingStatusText(status) {
  let statusTextMap = {
    NOT_SETUP: "EJ KONFIGURERAD",
    INACTIVE: "INAKTIV",
    ACTIVE: "AKTIV",
    PENDING: "VERIFIERAR",
  }
  return statusTextMap[status];
}
