export let questions = [
  {
    answerAlternatives: [
      {
        val: null,
        text: null,
      },
    ],
    range: [],
    labels: [],
    id: 29,
    rank: null,
    tab: "create",
    kind: "QTextShort",
    questionText: "Exempel på kort textfråga / QTextShort?",
    questionText_extraInfo: "Popup info",
    motivation: null,
    answer_list_id: null,
    groupAnswers: false,
    autoContinue: false,
    optional: false,
    default: null,
    title: "Jobbtitel",
  },
  {
    answerAlternatives: [
      {
        val: null,
        text: null,
      },
    ],
    range: [],
    labels: [],
    id: 31,
    rank: null,
    tab: "create",
    kind: "QText",
    questionText: "Exempel på lång textfråga / QText?",
    questionText_extraInfo: "",
    motivation: null,
    answer_list_id: null,
    groupAnswers: false,
    autoContinue: false,
    optional: false,
    default: null,
    title: "Beskriv tjänsten kortfattat",
  },
  {
    answerAlternatives: [
      {
        val: null,
        text: null,
      },
    ],
    range: [],
    labels: [],
    id: 32,
    rank: null,
    tab: "create",
    kind: "QDatepicker",
    questionText: "QDatepicker fråga för att välja datum?",
    questionText_extraInfo:
      "Ange när tjänsten senast behöver vara tillsatt (troligen kommer den tillsättas tidigare)",
    motivation: null,
    answer_list_id: null,
    groupAnswers: false,
    autoContinue: false,
    optional: false,
    default: null,
    title: "",
  },
  {
    answerAlternatives: [
      {
        val: 1,
        text: "Stämmer inte alls - Stämmer precis",
      },
      {
        val: 2,
        text: "Stämmer inte alls - Stämmer precis",
      },
    ],
    range: [0, 100],
    labels: ["Stämmer inte alls", "Stämmer precis"],
    id: 33,
    rank: null,
    tab: "questions",
    kind: "QSlide",
    questionText: "Tjänsten är komplex och kräver analytisk förmåga, QSlider?",
    questionText_extraInfo:
      "Den här rollen ställer höga krav på analytiskt och abstrakt tänkande (rollens komplexitet).",
    motivation: null,
    answer_list_id: 6,
    showThumbLabel: false,
    thumbLabelSize: null,
    sliderStepSize: 1,
    groupAnswers: false,
    autoContinue: true,
    optional: false,
    default: 50,
    title: "Analytisk förmåga",
  },
  {
    answerAlternatives: [
      {
        val: 1,
        text: "Heltid",
      },
      {
        val: 2,
        text: "Deltid",
      },
      {
        val: 3,
        text: "Tidsbegränsad anställning",
      },
      {
        val: 4,
        text: "Projektanställning",
      },
      {
        val: 5,
        text: "Säsongsarbete",
      },
    ],
    range: [],
    labels: [],
    id: 2,
    rank: 20,
    tab: "profile",
    kind: "QMulti",
    questionText: "Multivalsfråga för många alternativ och autocomplete?",
    questionText_extraInfo:
      "Är denna tjänst en heltidstjänst, deltidstjänst, projekttjänst eller en säsongsbaserad tjänst?",
    motivation: null,
    answer_list_id: 2,
    groupAnswers: true,
    autoContinue: false,
    optional: false,
    default: null,
    title: "Arbetsform",
    autocomplete: true,
  },
  {
    answerAlternatives: [
      {
        val: 1,
        text: "Heltid",
      },
      {
        val: 2,
        text: "Deltid",
      },
      {
        val: 3,
        text: "Tidsbegränsad anställning",
      },
      {
        val: 4,
        text: "Projektanställning",
      },
      {
        val: 5,
        text: "Säsongsarbete",
      },
    ],
    range: [],
    labels: [],
    id: 2,
    rank: 20,
    tab: "profile",
    kind: "QMulti",
    questionText: "Multivalsfråga för många alternativ?",
    questionText_extraInfo:
      "Är denna tjänst en heltidstjänst, deltidstjänst, projekttjänst eller en säsongsbaserad tjänst?",
    motivation: null,
    answer_list_id: 2,
    groupAnswers: true,
    autoContinue: false,
    optional: false,
    default: null,
    title: "Arbetsform",
  },
  {
    autocomplete: true,
    answerAlternatives: [
      {
        val: 114,
        text: "Upplands Väsby",
      },
      {
        val: 115,
        text: "Vallentuna",
      },
      {
        val: 117,
        text: "Österåker",
      },
      {
        val: 120,
        text: "Värmdö",
      },
      {
        val: 123,
        text: "Järfälla",
      },
      {
        val: 125,
        text: "Ekerö",
      },
      {
        val: 126,
        text: "Huddinge",
      },
      {
        val: 127,
        text: "Botkyrka",
      },
      {
        val: 128,
        text: "Salem",
      },
      {
        val: 136,
        text: "Haninge",
      },
      {
        val: 138,
        text: "Tyresö",
      },
      {
        val: 139,
        text: "Upplands-Bro",
      },
      {
        val: 140,
        text: "Nykvarn",
      },
      {
        val: 160,
        text: "Täby",
      },
      {
        val: 162,
        text: "Danderyd",
      },
      {
        val: 163,
        text: "Sollentuna",
      },
      {
        val: 180,
        text: "Stockholm",
      },
      {
        val: 181,
        text: "Södertälje",
      },
      {
        val: 182,
        text: "Nacka",
      },
      {
        val: 183,
        text: "Sundbyberg",
      },
      {
        val: 184,
        text: "Solna",
      },
      {
        val: 186,
        text: "Lidingö",
      },
      {
        val: 187,
        text: "Vaxholm",
      },
      {
        val: 188,
        text: "Norrtälje",
      },
      {
        val: 191,
        text: "Sigtuna",
      },
      {
        val: 192,
        text: "Nynäshamn",
      },
      {
        val: 305,
        text: "Håbo",
      },
      {
        val: 319,
        text: "Älvkarleby",
      },
      {
        val: 330,
        text: "Knivsta",
      },
      {
        val: 331,
        text: "Heby",
      },
      {
        val: 360,
        text: "Tierp",
      },
      {
        val: 380,
        text: "Uppsala",
      },
      {
        val: 381,
        text: "Enköping",
      },
      {
        val: 382,
        text: "Östhammar",
      },
      {
        val: 428,
        text: "Vingåker",
      },
      {
        val: 461,
        text: "Gnesta",
      },
      {
        val: 480,
        text: "Nyköping",
      },
      {
        val: 481,
        text: "Oxelösund",
      },
      {
        val: 482,
        text: "Flen",
      },
      {
        val: 483,
        text: "Katrineholm",
      },
      {
        val: 484,
        text: "Eskilstuna",
      },
      {
        val: 486,
        text: "Strängnäs",
      },
      {
        val: 488,
        text: "Trosa",
      },
      {
        val: 509,
        text: "Ödeshög",
      },
      {
        val: 512,
        text: "Ydre",
      },
      {
        val: 513,
        text: "Kinda",
      },
      {
        val: 560,
        text: "Boxholm",
      },
      {
        val: 561,
        text: "Åtvidaberg",
      },
      {
        val: 562,
        text: "Finspång",
      },
      {
        val: 563,
        text: "Valdemarsvik",
      },
      {
        val: 580,
        text: "Linköping",
      },
      {
        val: 581,
        text: "Norrköping",
      },
      {
        val: 582,
        text: "Söderköping",
      },
      {
        val: 583,
        text: "Motala",
      },
      {
        val: 584,
        text: "Vadstena",
      },
      {
        val: 586,
        text: "Mjölby",
      },
      {
        val: 604,
        text: "Aneby",
      },
      {
        val: 617,
        text: "Gnosjö",
      },
      {
        val: 642,
        text: "Mullsjö",
      },
      {
        val: 643,
        text: "Habo",
      },
      {
        val: 662,
        text: "Gislaved",
      },
      {
        val: 665,
        text: "Vaggeryd",
      },
      {
        val: 680,
        text: "Jönköping",
      },
      {
        val: 682,
        text: "Nässjö",
      },
      {
        val: 683,
        text: "Värnamo",
      },
      {
        val: 684,
        text: "Sävsjö",
      },
      {
        val: 685,
        text: "Vetlanda",
      },
      {
        val: 686,
        text: "Eksjö",
      },
      {
        val: 687,
        text: "Tranås",
      },
      {
        val: 760,
        text: "Uppvidinge",
      },
      {
        val: 761,
        text: "Lessebo",
      },
      {
        val: 763,
        text: "Tingsryd",
      },
      {
        val: 764,
        text: "Alvesta",
      },
      {
        val: 765,
        text: "Älmhult",
      },
      {
        val: 767,
        text: "Markaryd",
      },
      {
        val: 780,
        text: "Växjö",
      },
      {
        val: 781,
        text: "Ljungby",
      },
      {
        val: 821,
        text: "Högsby",
      },
      {
        val: 834,
        text: "Torsås",
      },
      {
        val: 840,
        text: "Mörbylånga",
      },
      {
        val: 860,
        text: "Hultsfred",
      },
      {
        val: 861,
        text: "Mönsterås",
      },
      {
        val: 862,
        text: "Emmaboda",
      },
      {
        val: 880,
        text: "Kalmar",
      },
      {
        val: 881,
        text: "Nybro",
      },
      {
        val: 882,
        text: "Oskarshamn",
      },
      {
        val: 883,
        text: "Västervik",
      },
      {
        val: 884,
        text: "Vimmerby",
      },
      {
        val: 885,
        text: "Borgholm",
      },
      {
        val: 980,
        text: "Gotland",
      },
      {
        val: 1060,
        text: "Olofström",
      },
      {
        val: 1080,
        text: "Karlskrona",
      },
      {
        val: 1081,
        text: "Ronneby",
      },
      {
        val: 1082,
        text: "Karlshamn",
      },
      {
        val: 1083,
        text: "Sölvesborg",
      },
      {
        val: 1214,
        text: "Svalöv",
      },
      {
        val: 1230,
        text: "Staffanstorp",
      },
      {
        val: 1231,
        text: "Burlöv",
      },
      {
        val: 1233,
        text: "Vellinge",
      },
      {
        val: 1256,
        text: "Östra Göinge",
      },
      {
        val: 1257,
        text: "Örkelljunga",
      },
      {
        val: 1260,
        text: "Bjuv",
      },
      {
        val: 1261,
        text: "Kävlinge",
      },
      {
        val: 1262,
        text: "Lomma",
      },
      {
        val: 1263,
        text: "Svedala",
      },
      {
        val: 1264,
        text: "Skurup",
      },
      {
        val: 1265,
        text: "Sjöbo",
      },
      {
        val: 1266,
        text: "Hörby",
      },
      {
        val: 1267,
        text: "Höör",
      },
      {
        val: 1270,
        text: "Tomelilla",
      },
      {
        val: 1272,
        text: "Bromölla",
      },
      {
        val: 1273,
        text: "Osby",
      },
      {
        val: 1275,
        text: "Perstorp",
      },
      {
        val: 1276,
        text: "Klippan",
      },
      {
        val: 1277,
        text: "Åstorp",
      },
      {
        val: 1278,
        text: "Båstad",
      },
      {
        val: 1280,
        text: "Malmö",
      },
      {
        val: 1281,
        text: "Lund",
      },
      {
        val: 1282,
        text: "Landskrona",
      },
      {
        val: 1283,
        text: "Helsingborg",
      },
      {
        val: 1284,
        text: "Höganäs",
      },
      {
        val: 1285,
        text: "Eslöv",
      },
      {
        val: 1286,
        text: "Ystad",
      },
      {
        val: 1287,
        text: "Trelleborg",
      },
      {
        val: 1290,
        text: "Kristianstad",
      },
      {
        val: 1291,
        text: "Simrishamn",
      },
      {
        val: 1292,
        text: "Ängelholm",
      },
      {
        val: 1293,
        text: "Hässleholm",
      },
      {
        val: 1315,
        text: "Hylte",
      },
      {
        val: 1380,
        text: "Halmstad",
      },
      {
        val: 1381,
        text: "Laholm",
      },
      {
        val: 1382,
        text: "Falkenberg",
      },
      {
        val: 1383,
        text: "Varberg",
      },
      {
        val: 1384,
        text: "Kungsbacka",
      },
      {
        val: 1401,
        text: "Härryda",
      },
      {
        val: 1402,
        text: "Partille",
      },
      {
        val: 1407,
        text: "Öckerö",
      },
      {
        val: 1415,
        text: "Stenungsund",
      },
      {
        val: 1419,
        text: "Tjörn",
      },
      {
        val: 1421,
        text: "Orust",
      },
      {
        val: 1427,
        text: "Sotenäs",
      },
      {
        val: 1430,
        text: "Munkedal",
      },
      {
        val: 1435,
        text: "Tanum",
      },
      {
        val: 1438,
        text: "Dals-Ed",
      },
      {
        val: 1439,
        text: "Färgelanda",
      },
      {
        val: 1440,
        text: "Ale",
      },
      {
        val: 1441,
        text: "Lerum",
      },
      {
        val: 1442,
        text: "Vårgårda",
      },
      {
        val: 1443,
        text: "Bollebygd",
      },
      {
        val: 1444,
        text: "Grästorp",
      },
      {
        val: 1445,
        text: "Essunga",
      },
      {
        val: 1446,
        text: "Karlsborg",
      },
      {
        val: 1447,
        text: "Gullspång",
      },
      {
        val: 1452,
        text: "Tranemo",
      },
      {
        val: 1460,
        text: "Bengtsfors",
      },
      {
        val: 1461,
        text: "Mellerud",
      },
      {
        val: 1462,
        text: "Lilla Edet",
      },
      {
        val: 1463,
        text: "Mark",
      },
      {
        val: 1465,
        text: "Svenljunga",
      },
      {
        val: 1466,
        text: "Herrljunga",
      },
      {
        val: 1470,
        text: "Vara",
      },
      {
        val: 1471,
        text: "Götene",
      },
      {
        val: 1472,
        text: "Tibro",
      },
      {
        val: 1473,
        text: "Töreboda",
      },
      {
        val: 1480,
        text: "Göteborg",
      },
      {
        val: 1481,
        text: "Mölndal",
      },
      {
        val: 1482,
        text: "Kungälv",
      },
      {
        val: 1484,
        text: "Lysekil",
      },
      {
        val: 1485,
        text: "Uddevalla",
      },
      {
        val: 1486,
        text: "Strömstad",
      },
      {
        val: 1487,
        text: "Vänersborg",
      },
      {
        val: 1488,
        text: "Trollhättan",
      },
      {
        val: 1489,
        text: "Alingsås",
      },
      {
        val: 1490,
        text: "Borås",
      },
      {
        val: 1491,
        text: "Ulricehamn",
      },
      {
        val: 1492,
        text: "Åmål",
      },
      {
        val: 1493,
        text: "Mariestad",
      },
      {
        val: 1494,
        text: "Lidköping",
      },
      {
        val: 1495,
        text: "Skara",
      },
      {
        val: 1496,
        text: "Skövde",
      },
      {
        val: 1497,
        text: "Hjo",
      },
      {
        val: 1498,
        text: "Tidaholm",
      },
      {
        val: 1499,
        text: "Falköping",
      },
      {
        val: 1715,
        text: "Kil",
      },
      {
        val: 1730,
        text: "Eda",
      },
      {
        val: 1737,
        text: "Torsby",
      },
      {
        val: 1760,
        text: "Storfors",
      },
      {
        val: 1761,
        text: "Hammarö",
      },
      {
        val: 1762,
        text: "Munkfors",
      },
      {
        val: 1763,
        text: "Forshaga",
      },
      {
        val: 1764,
        text: "Grums",
      },
      {
        val: 1765,
        text: "Årjäng",
      },
      {
        val: 1766,
        text: "Sunne",
      },
      {
        val: 1780,
        text: "Karlstad",
      },
      {
        val: 1781,
        text: "Kristinehamn",
      },
      {
        val: 1782,
        text: "Filipstad",
      },
      {
        val: 1783,
        text: "Hagfors",
      },
      {
        val: 1784,
        text: "Arvika",
      },
      {
        val: 1785,
        text: "Säffle",
      },
      {
        val: 1814,
        text: "Lekeberg",
      },
      {
        val: 1860,
        text: "Laxå",
      },
      {
        val: 1861,
        text: "Hallsberg",
      },
      {
        val: 1862,
        text: "Degerfors",
      },
      {
        val: 1863,
        text: "Hällefors",
      },
      {
        val: 1864,
        text: "Ljusnarsberg",
      },
      {
        val: 1880,
        text: "Örebro",
      },
      {
        val: 1881,
        text: "Kumla",
      },
      {
        val: 1882,
        text: "Askersund",
      },
      {
        val: 1883,
        text: "Karlskoga",
      },
      {
        val: 1884,
        text: "Nora",
      },
      {
        val: 1885,
        text: "Lindesberg",
      },
      {
        val: 1904,
        text: "Skinnskatteberg",
      },
      {
        val: 1907,
        text: "Surahammar",
      },
      {
        val: 1960,
        text: "Kungsör",
      },
      {
        val: 1961,
        text: "Hallstahammar",
      },
      {
        val: 1962,
        text: "Norberg",
      },
      {
        val: 1980,
        text: "Västerås",
      },
      {
        val: 1981,
        text: "Sala",
      },
      {
        val: 1982,
        text: "Fagersta",
      },
      {
        val: 1983,
        text: "Köping",
      },
      {
        val: 1984,
        text: "Arboga",
      },
      {
        val: 2021,
        text: "Vansbro",
      },
      {
        val: 2023,
        text: "Malung-Sälen",
      },
      {
        val: 2026,
        text: "Gagnef",
      },
      {
        val: 2029,
        text: "Leksand",
      },
      {
        val: 2031,
        text: "Rättvik",
      },
      {
        val: 2034,
        text: "Orsa",
      },
      {
        val: 2039,
        text: "Älvdalen",
      },
      {
        val: 2061,
        text: "Smedjebacken",
      },
      {
        val: 2062,
        text: "Mora",
      },
      {
        val: 2080,
        text: "Falun",
      },
      {
        val: 2081,
        text: "Borlänge",
      },
      {
        val: 2082,
        text: "Säter",
      },
      {
        val: 2083,
        text: "Hedemora",
      },
      {
        val: 2084,
        text: "Avesta",
      },
      {
        val: 2085,
        text: "Ludvika",
      },
      {
        val: 2101,
        text: "Ockelbo",
      },
      {
        val: 2104,
        text: "Hofors",
      },
      {
        val: 2121,
        text: "Ovanåker",
      },
      {
        val: 2132,
        text: "Nordanstig",
      },
      {
        val: 2161,
        text: "Ljusdal",
      },
      {
        val: 2180,
        text: "Gävle",
      },
      {
        val: 2181,
        text: "Sandviken",
      },
      {
        val: 2182,
        text: "Söderhamn",
      },
      {
        val: 2183,
        text: "Bollnäs",
      },
      {
        val: 2184,
        text: "Hudiksvall",
      },
      {
        val: 2260,
        text: "Ånge",
      },
      {
        val: 2262,
        text: "Timrå",
      },
      {
        val: 2280,
        text: "Härnösand",
      },
      {
        val: 2281,
        text: "Sundsvall",
      },
      {
        val: 2282,
        text: "Kramfors",
      },
      {
        val: 2283,
        text: "Sollefteå",
      },
      {
        val: 2284,
        text: "Örnsköldsvik",
      },
      {
        val: 2303,
        text: "Ragunda",
      },
      {
        val: 2305,
        text: "Bräcke",
      },
      {
        val: 2309,
        text: "Krokom",
      },
      {
        val: 2313,
        text: "Strömsund",
      },
      {
        val: 2321,
        text: "Åre",
      },
      {
        val: 2326,
        text: "Berg",
      },
      {
        val: 2361,
        text: "Härjedalen",
      },
      {
        val: 2380,
        text: "Östersund",
      },
      {
        val: 2401,
        text: "Nordmaling",
      },
      {
        val: 2403,
        text: "Bjurholm",
      },
      {
        val: 2404,
        text: "Vindeln",
      },
      {
        val: 2409,
        text: "Robertsfors",
      },
      {
        val: 2417,
        text: "Norsjö",
      },
      {
        val: 2418,
        text: "Malå",
      },
      {
        val: 2421,
        text: "Storuman",
      },
      {
        val: 2422,
        text: "Sorsele",
      },
      {
        val: 2425,
        text: "Dorotea",
      },
      {
        val: 2460,
        text: "Vännäs",
      },
      {
        val: 2462,
        text: "Vilhelmina",
      },
      {
        val: 2463,
        text: "Åsele",
      },
      {
        val: 2480,
        text: "Umeå",
      },
      {
        val: 2481,
        text: "Lycksele",
      },
      {
        val: 2482,
        text: "Skellefteå",
      },
      {
        val: 2505,
        text: "Arvidsjaur",
      },
      {
        val: 2506,
        text: "Arjeplog",
      },
      {
        val: 2510,
        text: "Jokkmokk",
      },
      {
        val: 2513,
        text: "Överkalix",
      },
      {
        val: 2514,
        text: "Kalix",
      },
      {
        val: 2518,
        text: "Övertorneå",
      },
      {
        val: 2521,
        text: "Pajala",
      },
      {
        val: 2523,
        text: "Gällivare",
      },
      {
        val: 2560,
        text: "Älvsbyn",
      },
      {
        val: 2580,
        text: "Luleå",
      },
      {
        val: 2581,
        text: "Piteå",
      },
      {
        val: 2582,
        text: "Boden",
      },
      {
        val: 2583,
        text: "Haparanda",
      },
      {
        val: 2584,
        text: "Kiruna",
      },
    ],
    range: [],
    labels: [],
    id: 79,
    rank: 52,
    tab: "create",
    kind: "QSingle",
    questionText: "Kommun, autocomplete ett alternativ",
    questionText_extraInfo: "Välj kommun med omsorg",
    motivation: null,
    answer_list_id: 8,
    groupAnswers: false,
    autoContinue: false,
    optional: false,
    default: null,
    title: "KommunKod",
  },
  {
    answerAlternatives: [
      {
        val: null,
        text: null,
      },
    ],
    range: [],
    labels: [],
    id: 6,
    rank: 60,
    tab: "profile",
    kind: "QCities",
    questionText: "I vilka städer tillsätts denna tjänst?",
    questionText_extraInfo:
      "Specificera den eller de städer som denna tjänst är aktuell för",
    motivation: null,
    answer_list_id: null,
    groupAnswers: false,
    autoContinue: false,
    optional: false,
    default: null,
    title: "Intressanta städer",
  },
  {
    answerAlternatives: [
      {
        val: 1,
        text: "Ja",
      },
      {
        val: 2,
        text: "Nej",
      },
    ],
    range: [],
    labels: [],
    id: 8,
    rank: 80,
    tab: "profile",
    kind: "QSingle",
    questionText: "Singlefråga QSingle för att välja ett av två alternativ?",
    questionText_extraInfo:
      "Kräver denna tjänst att arbetstagaren kan arbeta kvällar och helger?",
    motivation: null,
    answer_list_id: 7,
    groupAnswers: true,
    autoContinue: false,
    optional: false,
    default: null,
    title: "Jobba kvällar & helger",
  },
  {
    answerAlternatives: [
      {
        val: 1,
        text: "Ja",
      },
      {
        val: 2,
        text: "Nej",
      },
      {
        val: 3,
        text: "Kanske",
      },
    ],
    range: [],
    labels: [],
    id: 8,
    rank: 80,
    tab: "profile",
    kind: "QSingle",
    questionText: "Singlefråga QSingle för att välja ett av flera alternativ?",
    questionText_extraInfo:
      "Kräver denna tjänst att arbetstagaren kan arbeta kvällar och helger?",
    motivation: null,
    answer_list_id: 7,
    groupAnswers: true,
    autoContinue: false,
    optional: false,
    default: null,
    title: "Jobba kvällar & helger",
  },
];
