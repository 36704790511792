import Vue from "vue";
import Router from "vue-router";
import store from "./store";

import Company from "./views/Company.vue";
import Dashboard from "./views/Dashboard.vue";
import Processes from "./views/Processes.vue";
import Menu from "./views/Menu.vue";
import Settings from "./views/Settings.vue";
import Support from "./views/Support.vue";
import CreateJob from "./views/Create.vue";
import EditJobQuestions from "./views/Create.vue";
import Process from "./views/Process.vue";
import Candidate from "./views/Candidate.vue";
import LoginPage from "./views/LoginPage.vue";
import Signup from "./views/Signup.vue";
import MissingPath from "./views/MissingPath.vue";
import LibraryTestpage from "./views/Development/Testpage.vue";
import PasswordReset from "./views/PasswordReset.vue";
import ComponentLibrary from "./views/Development/ComponentLibrary.vue";
import ComponentLibraryQuestions from "./views/Development/ComponentLibraryQuestions.vue";

import pageTemplate from "./components/layout/Page.vue";

Vue.use(Router);

var routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    /*  redirect: {
       path: '/c'
     }, */
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/c",
    name: "Company",
    component: Company,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/p",
    name: "Processes",
    component: Processes,
    meta: {
      requiresAuth: true,
    },
  },
/*   {
    path: "/s/:section",
    name: "SettingsSection",
    component: Settings,
    props: true,
    meta: {
      requiresAuth: true,
    },
  }, */
  {
    path: "/s",
    name: "Settings",
    component: Settings,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/su",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cr",
    name: "Create",
    component: CreateJob,
    meta: {
      requiresAuth: true,
      requiresCompanyStatusSetupDone: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    meta: {
      skipIfLoggedIn: true,
      layout: "no-sidebar",
    },
    component: Signup,
  },
  {
    path: "/l",
    name: "LoginPage",
    meta: {
      skipIfLoggedIn: true,
      layout: "no-sidebar",
    },
    component: LoginPage,
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    meta: {
      skipChecks: true,
      layout: "no-sidebar",
    },
    component: PasswordReset,
  },
  {
    path: "/p/:job_guid/candidate/:application_guid",
    name: "Candidate",
    component: Candidate,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/p/:job_guid/edit",
    name: "EditJob",
    component: EditJobQuestions,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/p/:id",
    name: "Job",
    component: Process,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "Missing Path",
    meta: {
      skipChecks: true,
      layout: "no-sidebar",
    },
    component: MissingPath,
  },
];

if (process.env.NODE_ENV == "development") {
  routes.push({
    path: "/pageTemplate",
    name: "pageTemplate",
    component: pageTemplate,
  });
  routes.push({
    path: "/component-library",
    name: "ComponentLibrary",
    component: ComponentLibrary,
  });
  routes.push({
    path: "/component-library/questions",
    name: "ComponentLibraryQuestions",
    component: ComponentLibraryQuestions,
  });
  routes.push({
    path: "/component-library/testpage",
    name: "LibraryTestpage",
    component: LibraryTestpage,
  });

}

const router = new Router({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: routes,
});

export default router;

var loginPath = "/l";
var startPagePath = "/";

router.beforeEach(async (to, from, next) => {
  
  if (!store.state.user.isLoggedIn) {
    store.dispatch("user/load", null);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.user.isLoggedIn) {
      //console.log("user is logged in");
      store.dispatch("dataservice/initialLoad");
      if (
        to.matched.some(
          (record) => record.meta.requiresCompanyStatusSetupDone
        ) && store.state.user.companyInformation != null &&
        !store.state.user.companyInformation.account.status.setupComplete
      ) {
        next({
          name: "Settings",
          params: { section: 'profile' }
        });
        return;
      }
      next();
      return;
    }
    next({
      path: loginPath,
      query: { nextUrl: to.fullPath },
      params: { nextUrl: to.fullPath },
    });
    return;
  } else if (
    to.matched.some(
      (record) =>
        record.meta.skipIfLoggedIn && store.state.user.isLoggedIn == true
    )
  ) {
    next({
      path: startPagePath,
    });
    return;
  } else {
    next();
    return;
  }
});

router.afterEach((to) => {
  //if (process.env.NODE_ENV == "production") {
  gtag("config", window.GA_TRACKING_ID, {
    page_path: to.fullPath,
    app_name: "Brite AG",
    send_page_view: true,
  });
  //}
});
