<template>
  <div>
    <!-- v-if="answerAlternatives.length>2" -->
    <v-autocomplete
      v-if="
        autocomplete == true ||
        (autocomplete == null && answerAlternatives.length > 5)
      "
      v-model="dAnswer"
      :items="answerAlternatives"
      :search-input.sync="search"
      auto-select-first
      item-value="val"
      item-text="text"
      chips
      clearable
      deletable-chips
      class="secondary-color-chips"
      multiple
      @change="search = ''"
      :disabled="disabled"
    ></v-autocomplete>
    <v-select
      v-else
      v-model="dAnswer"
      multiple
      :items="answerAlternatives"
      item-value="val"
      item-text="text"
      class="secondary-color-chips"
      chips
      color="secondary"
      deletable-chips
      :disabled="disabled"
    ></v-select>

    <!--   <v-flex v-else class="d-flex">
      <v-checkbox
        xs6
        v-for="alt in answerAlternatives"
        :key="alt.id"
        color="primary"
        v-model="dAnswer"
        :label="alt.text"
        :value="alt.val"
      :disabled="disabled"
      ></v-checkbox>
    </v-flex> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Array,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    disabled: Boolean,
    autocomplete: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      selected: [],
      dAnswer: this.answer ? this.answer : [],
      dValidAnswer: false,
      typing: false,
      typingDone: false,
      search: "",
    };
  },
  watch: {
    dAnswer: {
      handler: function () {
       this.emitValues();
      },
      deep: true,
    },
  },
  methods: {
    emitValues() {
       this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer,
        });
    }
  },
  mounted: function() {
    this.emitValues();
  }
};
</script>
