<template>
  <v-container
    class="d-flex flex-column"
    :class="{ 'flex-grow-1': !$vuetify.breakpoint.mdAndDown }"
  >
    <v-row class="">
      <v-col class="d-flex">
        <!-- <Spinner  v-bind:message="loadingMessage" />  -->
        <Spinner v-if="loading" :message="loadingMessage" />

        <slot v-else>
         
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Button from "../basic/Button";
import Spinner from "../misc/Spinner";

export default {
  components: {
    Button,
    Spinner,
  },
  props: {
    loading: Boolean,
    loadingMessage: {
      type: String,
      default: "Laddar...",
    },
    fluid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {};
  },
  methods: {
    nextStep: function () {
      this.$emit("confirm", {});
    },
  },
};
</script>
