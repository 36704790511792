<template>
  <div v-if="!loading && userInformation.userAccount.isAdmin != null">
    <AdminDashboard v-if="userInformation.userAccount.isAdmin == true" />
    <UserDashboard v-else />
  </div>
</template>

<script>
import UserDashboard from "./UserDashboard";
import AdminDashboard from "./AdminDashboard";

import { mapState, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    UserDashboard,
    AdminDashboard,
  },
  data: () => {
    return {
      loading: true,
    };
  },
  watch: {
    userStatus: function () {
      if (this.userStatus == "initialize") {
        this.getUserInformation();
      } else if (this.userStatus == "success") {
        this.loading = false;
      }
    },
  },
  mounted: function () {
    if (this.userStatus == "success") {
      this.loading = false;
    }
  },
  computed: {
    ...mapState("user", {
      userStatus: "status",
      userInformation: "userInformation",
    }),
  },
  methods: {
    ...mapActions("user", ["getUserInformation"]),
  },
};
</script>
