<template>
   <div>
    <v-container class="pb-0">
      <v-row>
        <v-col>
        <sticky class="dense-banner mt-0 pt-1" color="#FAFAFA" sticky two-line>
          <v-progress-linear
            v-if="progress != null"
            color="secondary"
            :value="progress"
            class="mb-2"
          ></v-progress-linear>

          <thick-divider
            v-else-if="showDivider"
            class="mt-0 pt-0"
          ></thick-divider>
          <slot name="breadcrumbs">
            <v-breadcrumbs
              v-if="breadcrumbs != null"
              class="pa-0 mb-2"
              :items="breadcrumbs"
            >
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :disabled="item.disabled"
                  :class="{
                    'text-decoration-underline': item.tab == currentTab,
                    clickable: !item.disabled,
                  }"
                  @click="$emit('switch-tab', item.tab)"
                  >{{ item.text }}</v-breadcrumbs-item
                >
              </template>
              <template v-if="breadcrumbsStepper" v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
          </slot>

          <slot name="alertAction">
            <AlertBar
              :visible="alertBar.visible"
              :message="alertBar.message"
              :btn-message="alertBar.btnMessage"
            ></AlertBar>
          </slot>
          <slot>
            <ContinueBar></ContinueBar>
            <v-avatar
              v-if="icon"
              slot="icon"
              color="deep-purple accent-4"
              size="40"
            >
              <v-icon icon="mdi-lock" color="white">mdi-lock</v-icon>
            </v-avatar>
            <v-alert
              v-if="canContinue"
              class="mx-auto py-1 my-1"
              max-width="450"
              border="left"
              text
              color="secondary"
              transition="scale-transition"
            >
              <v-row align="center">
                <v-col class="grow">Alla frågor ifyllda</v-col>
                <v-col class="shrink py-0">
                  <Button :type="'continue'">Fortsätt</Button>
                </v-col>
              </v-row>
            </v-alert>
          </slot>
          <!--  <v-row :justify="justifyActions">
        <v-btn class="text-none" text color="primary">Avbryt</v-btn>
        <v-btn class="text-none" rounded color="primary">Spara</v-btn>
    </v-row>-->
        </sticky>
       </v-col>
      </v-row>
    </v-container>
  </div>

</template>
<script>
import ContinueBar from "../basic/ContinueBar";
import AlertBar from "../basic/AlertBar";
import Button from "../basic/Button";
import ThickDivider from "../atom/line/ThickDivider.vue";
import Sticky from "../basic/Sticky.vue";
export default {
  components: {
    Button,
    ContinueBar,
    AlertBar,
    ThickDivider,
    Sticky,
  },
  props: {
    alertBar: Object,
    progress: Number,
    canContinue: Boolean,
    currentTab: String,
    breadcrumbsStepper: Boolean,
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    icon: String,
    temptext: String,
    showDivider: {
      type: Boolean,
      required: false,
      default: () => {
        return true;
      },
    },
  },
  data: () => {
    return {};
  },
  computed: {
    justifyActions: function () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "center";
      } else {
        return "flex-start";
      }
    },
  },
};
</script>
