<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="12">
          <h1>Hitta den perfekta kandidaten för tjänsten</h1>
          <p>
            För att din nästa stjärna ska hitta till just din tjänst så
            rekommenderar vi att du publicerar tjänsten på någon av tjänsterna
            nedan. Du även kan dela direktlänken direkt till din tjänst på din
            hemsida eller i sociala medier.
          </p>
        </v-col>
      </v-row>
      <v-row
        ><v-col cols="12" md="7">
          <job-publishing-list
            v-if="jobPosting != null"
            :jobGuid="jobGuid"
            :jobPosting.sync="jobPosting"
          ></job-publishing-list>
          <v-col v-if="jobPostingChanged" class="mt-8 d-flex justify-center">
            <Button @clicked="saveJobPostingSettings" class="align-self-center"
              >Spara</Button
            >
          </v-col>
          
        </v-col>
        <v-col cols="12" md="5">
          <find-your-dream-candidate-sheet></find-your-dream-candidate-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import FindYourDreamCandidateSheet from "../../organism/FindYourDreamCandidateSheet.vue";
import JobPublishingList from "../../organism/JobPublishingList.vue";
import Button from "../../../components/basic/Button";
import { mapState, mapActions } from "vuex";
import snackbarMixin from "../../../mixins/snackbar";

export default {
  mixins: [snackbarMixin],
  components: { JobPublishingList, FindYourDreamCandidateSheet, Button },
  props: {
    jobGuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      jobPosting: null,
      jobPostingOriginal: null,
      jobPostingChanged: false,
    };
  },
  created: function () {
    this.handleSettings();
  },
  computed: {
    ...mapState("process", ["jobPostingSettingsStatus", "jobPostingSettings"]),
  },
  methods: {
    ...mapActions("process", [
      "getJobPostingSettings",
      "updateJobPostingSettings",
    ]),
    saveJobPostingSettings: async function () {
      try {
        await this.updateJobPostingSettings({
          settings: this.jobPosting,
          jobGuid: this.jobGuid,
        });
        this.jobPostingChanged = false;
        this.jobPostingOriginal = JSON.parse(JSON.stringify(this.jobPosting));
      } catch (e) {
        this._snackbarError("Kunde inte spara ändring");
      }
    },
    handleSettings: function () {
      if (this.jobPostingSettingsStatus == "success") {
        this.jobPosting = JSON.parse(JSON.stringify(this.jobPostingSettings));
        this.jobPostingChanged = false;
        this.jobPostingOriginal = JSON.parse(
          JSON.stringify(this.jobPostingSettings)
        );
      }
      if (this.jobPostingSettingsStatus == "initialize") {
        this.getJobPostingSettings(this.jobGuid);
      }
      if (this.jobPostingSettingsStatus == "failed") {
        //this.loadingError = true;
      }
    },
  },
  watch: {
    jobPostingSettingsStatus: function () {
      this.handleSettings();
    },
    jobPosting: {
      handler: function () {
        this.jobPostingChanged = !_.isEqual(
          this.jobPosting,
          this.jobPostingOriginal
        );
      },
      deep: true,
    },
  },
};
</script>
