<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-row
              ><v-col><Heading1>Layout</Heading1></v-col></v-row
            >
          </v-col>
          <v-row
            ><v-col cols="12" sm="3"> <Card /> </v-col
            ><v-col cols="12" sm="3"> <Card /> </v-col
            ><v-col cols="12" sm="3"> <Card /> </v-col
            ><v-col cols="12" sm="3"> <Card /> </v-col
          ></v-row>
        </v-row>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <v-row>
          <v-col><Heading1>Atoms</Heading1></v-col>
        </v-row>
        <v-row class="d-inline-flex">
          <v-col v-for="(item, key) in atomLibraryComponents" :key="item.name">
            <v-chip
              :outlined="item.show"
              :color="item.show ? 'secondary' : null"
              @click="
                atomLibraryComponents[key].show =
                  !atomLibraryComponents[key].show
              "
              >{{ item.name }}</v-chip
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col
            cols="12"
            v-for="item in atomLibraryComponents"
            :key="item.kind"
          >
            <div v-if="item.show">
              <v-row
                ><v-col>
                  <h2>{{ item.name }}</h2>
                </v-col></v-row
              >
              <component :is="item.kind"></component>
            </div>
          </v-col>
        </v-row>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <v-row>
          <v-col><Heading1>Organisms</Heading1></v-col>
        </v-row>
        <v-row class="d-inline-flex">
          <v-col v-for="(item, key) in libraryComponents" :key="item.name"
            ><v-chip
              :outlined="item.show"
              :color="item.show ? 'secondary' : null"
              @click="
                libraryComponents[key].show = !libraryComponents[key].show
              "
              >{{ item.name }}</v-chip
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" v-for="item in libraryComponents" :key="item.kind">
            <div v-if="item.show">
              <v-row
                ><v-col>
                  <h2>{{ item.name }}</h2>
                </v-col></v-row
              >
              <component :is="item.kind"></component>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <v-row>
      <v-col cols="12">
        <Heading1>The Brite Colors</Heading1>
        <p>Lite text undeer en rubriken</p>
        <Heading2>The Brite Colors</Heading2>
        <p>Lite text undeer en rubriken</p>
      </v-col>
      <v-col cols="12"><h1>The Brite Colors</h1></v-col>
    </v-row>
    <v-row
      ><v-col>
        <v-avatar color="primary"></v-avatar><span>Primary</span>
        <v-avatar color="secondary"></v-avatar><span>Secondary</span>
        <v-avatar color="accent"></v-avatar><span>Accent</span>
        <v-avatar color="success"></v-avatar><span>Success</span>
        <v-avatar color="info"></v-avatar><span>Info</span>
        <v-avatar color="error"></v-avatar><span>Error</span>
      </v-col></v-row
    >
    <v-row
      ><v-col> <h1>Typography</h1></v-col></v-row
    >
    <v-row>
      <v-col cols="12"> <h1>Heading 1</h1></v-col>
      <v-col cols="12"> <h2>Heading 2</h2></v-col>
      <v-col cols="12"> <h3>Heading 3</h3></v-col>
      <v-col cols="12"> <h4>Heading 4</h4></v-col>
      <v-col cols="12"> <h5>Heading 5</h5></v-col>
      <v-col cols="12"> <h6>Heading 6</h6></v-col>

      <v-col cols="12"> <p class="text-subtitle-1">Subtitle 1</p></v-col>
      <v-col cols="12"> <p class="text-subtitle-2">Subtitle 2</p></v-col>
      <v-col cols="12"> <p class="text-body-1">Body 1</p></v-col>
      <v-col cols="12"> <p class="text-body-2">Body 2</p></v-col>
      <v-col cols="12"> <p class="text-button">Button</p></v-col>
      <v-col cols="12"> <p class="text-caption">Caption</p></v-col>
      <v-col cols="12"> <p class="text-overline">OVERLINE</p></v-col>
      <v-col cols="12"> <p>regular paragraph</p></v-col>
      <v-col cols="12"> <span>regular span</span></v-col>
    </v-row>
    <v-row
      ><v-col> <h1></h1></v-col
    ></v-row>
    <v-row
      ><v-col> <h1></h1></v-col
    ></v-row>
    <v-row
      ><v-col> <h1></h1></v-col
    ></v-row>
    <v-row
      ><v-col> <h1></h1></v-col
    ></v-row>

    <v-sparkline
      :fill="fill"
      :gradient="gradients[2]"
      :line-width="width"
      :padding="padding"
      :smooth="radius || false"
      :value="value"
      auto-draw
    ></v-sparkline>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="12" md="6">
        <v-row class="fill-height" align="center">
          <v-item-group v-model="selectedGradient" mandatory>
            <v-row>
              <v-item
                v-for="(gradient, i) in gradients"
                :key="i"
                v-slot="{ active, toggle }"
                :value="gradient"
              >
                <v-card
                  :style="{
                    background:
                      gradient.length > 1
                        ? `linear-gradient(0deg, ${gradient})`
                        : gradient[0],
                    border: '2px solid',
                    borderColor: active ? '#222' : 'white',
                  }"
                  width="30"
                  height="30"
                  class="mr-2"
                  @click.native="toggle"
                ></v-card>
              </v-item>
            </v-row>
          </v-item-group>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-slider
          v-model="width"
          label="Width"
          min="0.1"
          max="10"
          step="0.1"
          thumb-label
        ></v-slider>
      </v-col>

      <v-col cols="6">
        <v-row class="fill-height" align="center">
          <v-switch v-model="fill" label="Filled"></v-switch>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-slider
          v-model="radius"
          label="Radius"
          min="0"
          max="25"
          thumb-label
        ></v-slider>
      </v-col>

      <v-col cols="12" md="6" offset-md="6">
        <v-slider
          v-model="padding"
          label="Padding"
          min="0"
          max="25"
          thumb-label
        ></v-slider>
      </v-col>
    </v-row>

    Button <Button>Button</Button> <br />
    Spinner
    <Spinner :absolute-spinner="false"></Spinner> <br />
    PageHeader<PageHeader :title="'Header'"></PageHeader> <br />
    InfoBar
    <InfoBar
      :visible="true"
      :message="'Message from beyond the grave'"
      :btn-message="'ActionText'"
    ></InfoBar
    ><br />
    PageActions - basic breadcrumbs
    <PageActions
      :progress="60"
      :alert-bar="{ visible: false }"
      :can-continue="false"
      :breadcrumbs="breadcrumbs"
      :breadcrumbs-stepper="true"
    ></PageActions>
    <br />
    ContinueBar
    <ContinueBar
      :visible="true"
      :message="'Message from beyond the grave'"
      :btn-message="'ActionText'"
    ></ContinueBar
    ><br />
    AlertBar
    <AlertBar
      :visible="true"
      :message="'Message from beyond the grave'"
      :btn-message="'ActionText'"
    ></AlertBar
    ><br />
    Card
    <Card :title="'Card Title'">
      <template v-slot:cardOptions>
        Cardoptions
        <v-icon large color="grey lighten 3">gps_off</v-icon>
      </template>
      Card content
    </Card>
    <br />

    Dialog <Button @clicked="showDialog = true">Show dialog</Button>

    <Dialog v-model="showDialog" absolute title="Vill du radera jobb?">
      Denna handling kan inte ångras.
      <template v-slot:actions>
        Example actions slot
        <Button :type="'error'">Ja, radera</Button>
      </template> </Dialog
    ><br />
    SimpleList
    <SimpleList
      :items="breadcrumbs"
      prepend-type="calendar"
      :key-name="'job_guid'"
      :item-title="'key'"
      :item-subtitle="'text'"
      :item-subtitle2="'text'"
      :item-action-text="'type'"
      :item-action-avatar-color="'primary'"
      :title="'Simple List'"
    />
    <br />
    ActionCard DEFAULT
    <ActionCard
      :type="'DEFAULT'"
      :title="'Title'"
      :action-text="'ActionText'"
    />
    ActionCard IMPORTANT
    <ActionCard
      :type="'IMPORTANT'"
      :title="'Important title'"
      :subtitle="'Subtitle'"
      :action-text="'ActionText'"
    />
  </v-container>
</template>
<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

import basicMixin from "../../mixins/basic";
import SideBarNavExample from "./Lists/SidebarNavExample";
import ProminentListExample from "./Lists/ProminentListExample";
import StandardButtonExample from "./atom/StandardButtonExample";
import Heading1 from "../../components/atom/heading/Heading1";
import Heading2 from "../../components/atom/heading/Heading2";
import StandardHeadingsExample from "./atom/StandardHeadingsExample";

export default {
  components: {
    StandardHeadingsExample,
    StandardButtonExample,
    SideBarNavExample,
    ProminentListExample,
    Heading1,
    Heading2,
  },
  mixins: [basicMixin],
  data: function () {
    return {
      atomLibraryComponents: {
        StandardButtonExample: {
          kind: "StandardButtonExample",
          name: "Standard Button",
        },
        StandardHeadingsExample: {
          kind: "StandardHeadingsExample",
          name: "Standard headings",
        },
      },
      libraryComponents: {
        ProminentListExample: {
          kind: "ProminentListExample",
          name: "Prominent List",
        },
        SideBarNavExample: {
          kind: "SideBarNavExample",
          name: "Sidebar List",
        },
      },
      fill: true,
      selectedGradient: gradients[4],
      gradients,
      padding: 8,
      radius: 10,
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      width: 2,
      canContinue: true,
      showDialog: false,
      breadcrumbs: [
        {
          key: "basic",
          text: "Ny tjänst",
          type: "create",
          valid: true,
          disabled: false,
        },
        {
          key: "req",
          text: "Info",
          type: "profile",
          valid: true,
          disabled: false,
        },
        {
          key: "motivation",
          text: "Motivation",
          type: "questions",
          valid: true,
          disabled: false,
        },
      ],
    };
  },
  mounted: function () {
    let tempLibrary = {};
    for (const [key, value] of Object.entries(this.libraryComponents)) {
      tempLibrary[key] = { ...value, show: false };
    }
    this.libraryComponents = tempLibrary;
    tempLibrary = {};
    for (const [key, value] of Object.entries(this.atomLibraryComponents)) {
      tempLibrary[key] = { ...value, show: false };
    }
    this.atomLibraryComponents = tempLibrary;
  },
  methods: {},
};
</script>
<style scoped></style>
