<template>
  <Page v-bind="pageInfo">
    <template v-slot:banner>
      <div v-if="pageInfo.loading">Not loading</div>
    </template>
    <v-row>
      <v-col class="column-border-right thick-border" cols="12" md="3">
        <FlatToggleButtonList v-model="navigation" :items="settingsList" />
      </v-col>
      <v-col cols="12" md="9" class="pa-md-12">
        <div v-for="setting in settingsComponents" :key="setting">
          <component
            :is="setting"
            v-if="settingsComponents[navigation] == setting"
          ></component>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>
<script>
import JobPostingSettings from "@/components/templates/Settings/JobPostingSettings.vue";
import GeneralSettings from "@/components/templates/Settings/GeneralSettings.vue";
import CompanyProfileSettings from "@/components/templates/Settings/CompanyProfileSettings.vue";
import FlatToggleButtonList from "../components/basic/lists/FlatToggleButtonList";
import Page from "../components/layout/Page";

export default {
  components: {
    FlatToggleButtonList,
    Page,
    GeneralSettings,
    JobPostingSettings,
    CompanyProfileSettings,
  },
  data() {
    return {
      pageInfo: {
        title: "Inställningar",
        loading: false,
        pageSize: "normal",
      },
      settings: {
        CompanyProfileSettings: { section: "profile", name: "Redigera profil" },
        GeneralSettings: { section: "general", name: "Generellt" },
        //JobPostingSettings: { name: "Annonsering" },
      },
      sectionMap: {},
      settingsList: [],
      settingsComponents: [],
      navigation: 0,
    };
  },
  props: {
    section: String,
  },
  created: function () {
    let settingsList = [];
    let settingsComponents = [];
    let sectionMap = {};
    for (var key in this.settings) {
      settingsList.push(this.settings[key].name);
      settingsComponents.push(key);
      this.sectionMap[this.settings[key].section] = settingsList.length-1;
    }
    this.settingsList = settingsList;
    this.settingsComponents = settingsComponents;
    if(this.section != null) {
      this.navigation = this.sectionMap[this.section];
    }
  },
};
</script>