module.exports = {
  jobs: null,
  jobDrafts: null,
  allJobs: null,
  upcomingInterviews: null,
  status: "initialize",
  headers: [
    { text: "Titel", align: "left", value: "title", width: 200 },
    /*{ text: "Avdelning", value: "department", width: 130 },*/
    { text: "Ansvarig", value: "responsible", width: 150 },
    { text: "Ort", value: "cityText", width: 140 },
    { text: "Skapad", value: "created_date", width: 95 },
    { text: "Ansökningar", value: "applicants.length", width: 10 },
    { text: "Status", value: "statusText", width: 50 },
  ],
  draftHeaders: [
    { text: "Titel", align: "left", value: "title", width: 200 },
    /*{ text: "Avdelning", value: "department", width: 130 },*/
    { text: "Ansvarig", value: "responsible", width: 150 },
    { text: "Ort", value: "cityText", width: 140 },
    { text: "Skapad", value: "created_date", width: 95 },
    { text: "", value: "action", width: 100 }
  ],
  activeJobStatus: ["CREATED", "PUBLISHED", "INTERVIEWS"],
  jobStatusDefinition: {
    DRAFT: { statusText: "Utkast", statusColor: "grey" },
    CREATED: { statusText: "Skapad", statusColor: "open" },
    PUBLISHED: { statusText: "Publicerad", statusColor: "open" },
    INTERVIEWS: { statusText: "Intervjuer pågår", statusColor: "ongoing" },
    CANCELLED: { statusText: "Stängd", statusColor: "closed" },
    FINISHED: { statusText: "Tjänsten tillsatt", statusColor: "finished" },
  }, 
};
