<template>
  <div>
    <v-switch hide-details color="open" :input-value="value" @change="update" inset
    :label="label"
      ><slot></slot
    ></v-switch>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    label: String
  },
  methods: {
    update: function (value) {
      this.$emit("input", value);
      this.$emit("update:status", value);
    },
  },
};
</script>