const applicationsByJobGuid = (state) => (job_guid) => {
  return state.applications[job_guid] != null &&
    state.applications[job_guid] != undefined
    ? state.applications[job_guid]
    : [];
};

const applicationByApplicationGuid = (state) => (data) => {
  for (var i = 0; i < state.applications[data.job_guid].length; i++) {
    if (state.applications[data.job_guid][i].guid == data.application_guid) {
      return state.applications[data.job_guid][i];
    }
  }
  return null;
};

export default {
  applicationsByJobGuid: applicationsByJobGuid,
  applicationByApplicationGuid: applicationByApplicationGuid,
};
