<template>
  <v-divider class="thick-divider" :vertical="vertical"></v-divider>
</template>
<script>
export default {
    props: {
        vertical: {
            type: Boolean,
            default: false
        }
    }
}
</script>