<template>
  <v-app app>
    <Sidebar />
    <v-main>
      <Snackbar />
      <transition appear mode="out-in" name="fade">
        <keep-alive include="Processes">
          <router-view :key="$route.path" />
        </keep-alive>
      </transition>
    </v-main>
    <BottomBar />
  </v-app>
</template>

<script>
import Sidebar from "../components/nav/Sidebar.vue";
import BottomBar from "../components/nav/BottomBar.vue";

import Snackbar from "../components/misc/Snackbar.vue";

export default {
  components: {
    Sidebar,
    BottomBar,
    Snackbar,
  },
};
</script>
