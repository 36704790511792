<template>
  <div>
    <sheet-img-text
      :text="text"
      :title="title"
      :color="color"
      :icon="icon"
      :iconColor="iconColor"
      :url="url"
      :imgUrl="'https://storage.googleapis.com/brite-resources/img/Hands%201.svg'"
    >
  <h3 class="mt-0" :class="{'white--text' : color != 'white'}">{{title}}</h3>
    <p>Vi på Brite älskar integrationer, delvis för att de gör vårt jobb lättare men också för att det gör våra kunders vardag lättare. Saknar du din favvo-integration eller vill läsa mer om våra framtida planer?<p>
      <p><external-link :url="url">Läs mer på vår blogg</external-link></p>
    </sheet-img-text>
  </div>
</template>
<script>
import ExternalLink from '../atom/text/ExternalLink.vue';
import SheetImgText from '../molecule/sheet/SheetImgText.vue';
import InfoBanner from "./InfoBanner";
export default {
  components: { SheetImgText, ExternalLink },
  data() {
    return {
      url: "https://www.brite.jobs/integrationer",
      icon: "favorite",
      iconColor: "error",
      color: "white",
      title: "Brite <3 Integrationer",
      text: `Vi på Brite älskar integrationer, delvis för att de gör vårt jobb lättare men också för att det gör våra kunders vardag lättare. Saknar du din favvo-integration eller vill läsa mer om våra framtida planer? Läs mer på vår blogg`,
    };
  },
};
</script>