<template>
  <div>
    <div
      v-if="
        autocomplete == true ||
        (autocomplete == null && answerAlternatives.length > 5)
      "
    >
      <v-autocomplete
        v-model="dAnswer"
        :items="answerAlternatives"
        item-value="val"
        item-text="text"
        auto-select-first
        chips
        class="secondary-color-chips"
      :disabled="disabled"
      ></v-autocomplete>
    </div>
    <div v-else>
      <v-select
        v-if="answerAlternatives.length > 2"
        v-model="dAnswer"
        :items="answerAlternatives"
        item-value="val"
        item-text="text"
        color="white"
        :disabled="disabled"
      ></v-select>
      <v-radio-group v-else v-model="dAnswer" row>
        <v-radio
          v-for="alternative in answerAlternatives"
          :key="alternative.val"
          color="primary"
          :label="alternative.text"
          :value="alternative.val"
          :disabled="disabled"
        ></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    disabled: Boolean,
    autocomplete: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      dAnswer: this.answer,
      dValidAnswer: false,
      typing: false,
      typingDone: false,
    };
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.emitAnswer();
        },
      deep: true,
    },
  },
  methods: {
    emitAnswer() {
      this.dValidAnswer = false;
      if (this.dAnswer != null && this.dAnswer != false && this.dAnswer != "") {
        this.dValidAnswer = true;
      }
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
    },
  },
  mounted: function () {
    this.emitAnswer();
  },
};
</script>
