module.exports = {
  theme: {
    themes: {
      light: {
        primary: "#182540", // #E53935
        secondary: "#038C8C", // #FFCDD2
        success: "#038C8C", // #FFCDD2
        accent: "#F2AF5C", // #3F51B5
        error: "#D94032",
        open: "#07CE63",
        closed: "#E64435",
        ongoing: "#F4BB7A",
        finished: "#8B919F",
      },
      dark: {
        primary: "#182540", // #E53935
        secondary: "#038C8C", // #FFCDD2
        success: "#038C8C", // #FFCDD2
        accent: "#F2AF5C", // #3F51B5
        error: "#D94032",
        open: "#07CE63",
        closed: "#E64435",
        ongoing: "#F4BB7A",
        finished: "#8B919F",
      },
    },
  },
};
