<template>
  <v-content :class="{ scalePage: scalePage }">
    <transition name="fade" @after-enter="$router.push({ path: nextPath })">
      <svg v-if="scalePage" id="fader" />
    </transition>
    <v-container v-if="!scalePage" class="fill-height login-container" fluid>
      <h1 class="title-text main-title">
        <img
          class="brite-logo-white"
          src="https://storage.googleapis.com/brite-resources/logo/Brite_logo_white%404x.png"
        />
      </h1>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-form>
              <v-card-text>
                <h1>Logga in</h1>
                <v-alert v-if="errorMessage" dense outlined text type="error">{{
                  errorMessage
                }}</v-alert>
                <v-text-field
                  v-model="username"
                  outlined
                  placeholder="Användarnamn"
                  name="username"
                  type="text"
                ></v-text-field>

                <v-text-field
                  id="password"
                  v-model="password"
                  outlined
                  placeholder="Lösenord"
                  type="password"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-row class="ma-0 pa-0" justify="center">
                  <v-col cols="12" class="text-center ma-0 pa-0">
                    <v-btn
                      v-if="!loading"
                      color="primary"
                      type="submit"
                      class="confirm-action login-button text-none"
                      @click="login"
                      >Logga in</v-btn
                    >
                    <v-btn
                      v-else
                      color="primary"
                      type="submit"
                      class="login-button text-none"
                      @click="login"
                    >
                      <v-progress-circular
                        indeterminate="indeterminate"
                        size="26"
                        width="3"
                        color="white"
                      ></v-progress-circular>
                    </v-btn>
                  </v-col>
                  <v-col class="ma-0 pa-0" cols="12">
                    <p class="text-center mb-0 text-decoration-underline">
                      <router-link to="password-reset">
                        <small>Glömt lösen?</small>
                      </router-link>
                    </p>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <h3
      v-if="this.$vuetify.breakpoint.smAndUp"
      class="title-text white--text sub-title"
    >
      The future is Brite!
    </h3>
  </v-content>
</template>

<script>
import store from "../store";
import router from "../router";
import requests from "../request";

export default {
  props: {
    source: String,
  },
  data: () => ({
    username: null,
    password: null,
    scalePage: false,
    loading: false,
    imgFix: false,
    drawer: null,
    errorMessage: null,
    loading: false,
    nextPath: "c",
  }),
  created() {
    if (this.$route.query.nextUrl != null) {
      this.nextPath = this.$route.query.nextUrl;
    }
  },
  methods: {
    login: async function (e) {
      try {
        this.errorMessage = null;
        this.loading = true;
        e.preventDefault();
        await store.dispatch("user/login", {
          username: this.username,
          password: this.password,
        });
        this.loading = false;
        this.scalePage = true;
      } catch (e) {
        this.errorMessage = e;
        if (e.response) {
          if (e.response.status == 401) {
            this.errorMessage = "Felaktigt användarnamn eller lösenord!";
          }
        }
        console.log(e);
        this.loading = false;
      }
      /* requests.methods
        .checkCredentials(this.username, this.password)
        .then(result => {
          //console.log("results are");
          //console.log(result);
          if (result.isLoggedIn == true) {
            store.commit("isLoggedIn", true);
            store.commit("encryptedAccessToken", result.encryptedAccessToken);
            store.commit("encryptedSessionToken", result.encryptedSessionToken);
            this.scalePage = true;
          } else {
            this.errorMessage = result.message;
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.errorMessage = err;
        }); */
      // this.scalePage = true;
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
  background: #fafafa; /*
    animation-duration: 5000ms;
    animation-timing-function: ease-in-out; */
}

.login-button {
  width: 100px;
}

.scalePage {
  transform: scale(2);
  transition: 0.6s;
}
.v-application--wrap .v-content {
  margin-top: 0px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    url("https://storage.googleapis.com/brite-resources/img/bg/login_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fafafa;
  max-width: unset;
  height: 100%;
}

.v-card__actions {
  justify-content: center;
  padding: 16px;
}

.title-text {
  position: absolute;
  left: 0px;
  right: 0px;
  display: block;
  text-align: center;
}

.main-title {
  top: 10vh;
}

.sub-title {
  bottom: 15vh;
}

.login-container {
  justify-content: center;
}

/*
.container.login-container {
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  margin: 0px 0px 0px 0px;
} */

.v-form .v-input__slot {
  box-shadow: none;
}

/* .v-card {
  opacity:0.8;
} */

.v-card h1 {
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
  width: 100%;
  text-align: center;
  color: black;
}
</style>
