<template>
  <v-row>
    <v-col cols="12" md="7"
      ><prominent-list :items="items" :titleKey="'name'"> </prominent-list>
    </v-col>
  </v-row>
</template>

<script>
import basicMixin from "../../../mixins/basic";

export default {
  mixins: [basicMixin],
  data: function () {
    return {
      items: [
        {
          name: "Publicera på Arbetsförmedlingen",
          imgUrl:
            "https://storage.googleapis.com/brite-resources/logo/Brite_logo_teal%404x.png",
        },
          {
            name: "Publicera på Blocket Jobs",
            imgUrl:
              "https://storage.googleapis.com/brite-resources/logo/Brite_logo_teal%404x.png",
          },
        {
          name: "Välj plan",
          imgUrl:
            "https://storage.googleapis.com/brite-resources/logo/Brite_logo_teal%404x.png",
        },
        {
          name: "Annonsering",
          imgUrl:
            "https://storage.googleapis.com/brite-resources/logo/Brite_logo_teal%404x.png",
        },
      ],
    };
  },
};
</script>

<style></style>
