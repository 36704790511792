const jobs = (state, jobs) => {
  state.jobs = JSON.parse(JSON.stringify(jobs));
};
const allJobs = (state, allJobs) => {
  state.allJobs = JSON.parse(JSON.stringify(allJobs));
};
const jobDrafts = (state, jobDrafts) => {
  state.jobDrafts = JSON.parse(JSON.stringify(jobDrafts));
};
const upcomingInterviews = (state, upcomingInterviews) => {
  state.upcomingInterviews = JSON.parse(JSON.stringify(upcomingInterviews));
};
const status = (state, status) => {
  state.status = JSON.parse(JSON.stringify(status));
};

export default {
  upcomingInterviews,
  status,
  jobs,
  jobDrafts,
  allJobs
};
