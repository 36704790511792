function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function filteredList(list, attr, answer) {
  return list.filter(function (itm) {
    // console.log(itm[attr]);
    // console.log(answer.includes(itm[attr]));
    // console.log(list);
    return answer.includes(itm[attr]) == true;
  });
}

let dateInPast = function (firstDate, secondDate = new Date()) {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};

let dateHasNotPassed = function(dateToCheck) {
  return !dateInPast(dateToCheck)
}

function getMatchColor(score) {
  if (score == null || isNaN(score)) {
    return "rgb(160,160,160)";
  }
  var percentColors = [
    { pct: 0, color: { r: 248, g: 201, b: 168 } },
    { pct: 0.2, color: { r: 248, g: 201, b: 168 } },
    { pct: 0.4, color: { r: 245, g: 248, b: 167 } },
    { pct: 0.8, color: { r: 206, g: 242, b: 201 } },
  ];

  var getColorForPercentage = function (score) {
    var pct = score / 100;

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
    // or output as hex if preferred
  };

  return getColorForPercentage(score);
}

function getMotivationColor(score) {
  var percentColors = [
    { pct: 0, color: { r: 217, g: 64, b: 50 } },
    { pct: 0.2, color: { r: 217, g: 64, b: 50 } },
    { pct: 0.5, color: { r: 242, g: 175, b: 92 } },
    { pct: 0.8, color: { r: 3, g: 140, b: 140 } },
    { pct: 1, color: { r: 3, g: 140, b: 140 } },
  ];
  var getColorForPercentage = function (score) {
    var pct = score / 100;

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
    // or output as hex if preferred
  };

  return getColorForPercentage(score);
}

module.exports = {
  sleep: sleep,
  dateInPast: dateInPast,
  dateHasNotPassed: dateHasNotPassed,
  filteredList: filteredList,
  getMatchColor: getMatchColor,
  getMotivationColor: getMotivationColor,
};
