import store from "../store";

export default {
  components: {},
  data: () => {
    return {
      storeData: null,
    };
  },
  computed: {},
  beforeMount: function () {
    this.handleLoad(this.status, this.page.key, this.page.data, this.page.get);
  },
  watch: {
    status: function (status) {
      this.handleLoad(
        this.status,
        this.page.key,
        this.page.data,
        this.page.get
      );
    },
  },
  methods: {
    set: function (data) {
      this.storeData = data;
    },
    getFromStore: async function (storeKey, get) {
      try {
        this.loading = true;
        this.showAlertBox = false;
        await store.dispatch(storeKey + "/" + get, null);
        this.loading = false;
      } catch (e) {
        console.log("we got an error");
        this.alertBar.visible = true;
        this.alertBar.message = "Kunde inte ladda info";
        this.alertBar.btnMessage = "Ladda om sidan";
        this.loading = false;
        this.showAlertBox = true;
        console.log(e);
      }
    },
    handleLoad(status, storeKey, data, get) {
      switch (status) {
        case "initialize":
          this.getFromStore(storeKey, get);
          break;
        case "success":
          // console.log("trying to set questions")
          // console.log(store.state[storeQuestionsKey])
          this.storeData = JSON.parse(
            JSON.stringify(store.state[storeKey][data])
          );
          // console.log("my group qus")
          // console.log(this.groupedQuestions);
          break;
      }
    },
  },
};
