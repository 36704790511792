<template>
  <page v-bind="pageInfo">
    <v-row>
      <v-col cols="12" md="8">
        <div v-for="route in routes" :key="route.id" class="support-link">
          <router-link :to="route.path">{{ route.name }}</router-link>
        </div>
      </v-col>
    </v-row>
  </page>
</template>
<script>
import Heading1 from "../components/atom/heading/Heading1.vue";
import page from "../components/layout/Page.vue";
export default {
  components: { page, Heading1 },
  data() {
    return {
      pageInfo: {
        title: "Meny",
      },
      routes: [
        { id: 1, name: "Översikt", icon: "speed", path: "/" },
        { id: 4, name: "Processer", icon: "mdi-sitemap", path: "/p" },

        { id: 2, name: "Profil", icon: "mdi-home", path: "/c" },
        { id: 3, name: "Skapa", icon: "add", path: "/cr" },
        { id: 5, name: "Inställningar", icon: "mdi-tune", path: "/s" },
        { id: 6, name: "Support", icon: "mdi-chat-processing", path: "/su" },
      ],
    };
  },
  methods: {},
  mounted: function() {
    if (process.env.NODE_ENV == "development") {
      this.routes.push({
        id: 7, name: "Components", icon: "extension", path: "/component-library"
      })
    }
  }
};
</script>
<style scoped></style>
