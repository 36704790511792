<template>
  <v-text-field
    v-model="dAnswer"
    :readonly="disabled"
    placeholder="Skriv..."
  ></v-text-field>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    disabled: Boolean,
  },
  data() {
    return {
      dAnswer: this.answer_text,
      dValidAnswer: false,
      typing: false,
      typingDone: false,
    };
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.setValidAnswer();
      },
      deep: true,
    },
  },
  mounted: function () {
    //console.log("Inside this componenet");
    //this.dAnswer = JSON.parse(this.answer);
    this.setValidAnswer();
  },
  methods: {
    setValidAnswer: function () {
      this.dValidAnswer = false;
      if (this.dAnswer != null && this.dAnswer != false && this.dAnswer != "") {
        this.dValidAnswer = true;
      }
      if (this.dAnswer != null) {
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer_text: this.dAnswer,
          answer: null,
          validAnswer: this.dValidAnswer,
        });
      }
    },
  },
};
</script>
