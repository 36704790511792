<template>
  <div>
    <NotificationList
      :title="'Senaste ansökningarna'"
      :text-field="'notificationText'"
      icon="notification_important"
      :items="items"
      @clicked="openCandidate"
    >
    </NotificationList>
  </div>
</template>
<script>
import NotificationList from "./NotificationList";

export default {
  components: {
    NotificationList,
  },
  props: {
    title: String,
    textField: String,
    applications: Array,
    icon: String,
  },
  data: () => {
    return {
      items: [],
    };
  },
  created: function () {
    for (var i = 0; i < this.applications.length; i++) {
      let item = this.applications[i];
      item.notificationText = `${item.name} som ${item.jobTitle}`;
      this.items.push(item);
    }
  },
  methods: {
    openCandidate: function (application) {
      // this.setCandidate(application);
      this.$router.push({
        name: "Candidate",
        params: {
          application_guid: application.guid,
          job_guid: application.job_guid,
          currentView: "selectCandidates",
        },
      });
    },
  },
};
</script>
