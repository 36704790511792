
<template>
  <div>
    <v-item-group mandatory v-model="selected">
      <v-row
        class="d-flex"
        :class="{'flex-column': !$vuetify.breakpoint.mdAndDown}"
      >
        <v-col
          :class="{ 'd-inline': $vuetify.breakpoint.mdAndDown, 'flex-grow-0': $vuetify.breakpoint.mdAndDown }"
          v-for="item in items"
          :key="item"
        >
          <v-item v-slot="{ active, toggle }">
            <flat-toggle-button
              :outlined="$vuetify.breakpoint.mdAndDown"
              :name="item"
              :active="active"
              @clicked="toggle"
            />
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </div>
</template>

<script>
import FlatToggleButton from "../buttons/FlatToggleButton.vue";

export default {
  components: { FlatToggleButton },
  props: {
    value: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      selected: this.value,
    };
  },
  watch: {
    selected: function () {
      this.handleInput();
    },
    value: function() {
      this.selected = value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style>
</style>