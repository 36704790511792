<template>
  <v-row>
    <v-col>
      <Card :title="'Motivationsfrågor'">
        <template v-slot:cardOptions>
          <v-icon
            v-if="!candidate.motivationQuestionsValid"
            large
            color="grey lighten 3"
            >gps_off</v-icon
          >
          <v-rating
            v-else
            class="align-self-end"
            large
            :value="candidate.motivationScore + 1"
            background-color="grey lighten-4"
            color="success"
            readonly
            length="10"
          ></v-rating>
        </template>
        <v-container class="mx-2">
          <p>
            <v-avatar
              class="mr-1 motivation-comparison"
              color="secondary"
              size="24"
            ></v-avatar
            >Arbetssökande
            <v-avatar
              class="mr-1 motivation-comparison ag-less"
              color="secondary"
              size="24"
            ></v-avatar
            >Arbetsgivare
          </p>
          <v-row class="questions">
            <v-col
              v-for="(item, index) in items"
              :key="index"
              class="mb-4 pr-6"
              cols="12"
              sm="6"
            >
              <div class="question-text pb-4" v-html="item.questionText"></div>
              <v-range-slider
                v-if="item.values != null"
                v-model="item.values"
                :class="{
                  'ag-less': item.jobBiggerThanCandidate,
                  'close-range': Math.abs(item.values[0] - item.values[1]) < 10,
                }"
                class="
                  manual-disabled
                  motivation-comparison
                  align-center
                  center-align-slider
                  ma-0
                  pt-6
                "
                :thumb-color="differenceColor(item.values)"
                thumb-label="always"
              >
                <template v-slot:prepend>
                  <v-avatar
                    depressed
                    size="50"
                    class="button-not-clickable"
                    :color="differenceColor(item.values)"
                  >
                    <v-icon small color="white">{{
                      getMotivationIcon(item.values)
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-range-slider>
              <p v-else class="text-center text-bold">
                <b>Inget svar från sökande</b>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </Card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import snackbarMixin from "../../mixins/snackbar";
import basicMixin from "../../mixins/basic";
import { getMotivationColor } from "../../utils/misc";

export default {
  mixins: [basicMixin, snackbarMixin],
  props: {
    items: Array,
    candidate: Object,
  },
  data: function () {
    return {
      /*       values: [
        [20, 20],
        [20, 21],
        [20, 50],
        [20, 60],
        [20, 70],
        [20, 80],
        [10, 95],
        [10, 95],
        [30, 75],
      ], */
    };
  },
  methods: {
    differenceColor: function (value) {
      //var color = getMotivationColor(100 - Math.abs(value[0] - value[1]));
      var color = "secondary";
      return color;
    },
    getMotivationIcon: function (value) {
      if (Math.abs(value[0] - value[1]) > 75) {
        return "thumb_down";
      } else if (Math.abs(value[0] - value[1]) < 25) {
        return "thumb_up";
      }
      return "thumbs_up_down";
    },
  },
};
</script>
